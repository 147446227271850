import { CurrentExtract, PortabilityExtract } from '../models/account/data-portability.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';

import { OSSAppConfig } from '../../core/config/app-config';
import { CustomerService } from './customer.service';
@Injectable()
export class DataPortabilityService {

    private apiGroupURL = OSSAppConfig.API_GROUP[OSSAppConfig.API_SRC].extract;
    constructor(
        private http: HttpClient,
        private customerService: CustomerService
    ) { }

    /**
     * Service to retrive extract history
     *
     * @param {any} customer
     * @param {any} isCSR
     * @returns {Observable<PortabilityExtract>}
     * @memberof DataPortabilityService
     */
    public getExtractHistory(customer, isCSR, isOSAorCAG): Observable<PortabilityExtract> {
        let url = `${OSSAppConfig.DATA_PORTABILITY_BASE}/${this.apiGroupURL}/portability`;
        // let url = `${OSSAppConfig.jsonBasePath}/mocks/extracts.json`;
        let headers = new HttpHeaders();
        if (isCSR) {
            headers = new HttpHeaders({ 'csr-customer': customer });
            url = `${OSSAppConfig.DATA_PORTABILITY_BASE}/${this.apiGroupURL}/portability/${customer}`;
        } else if (isOSAorCAG) {
            const debtorNumber = this.customerService.getAccNo();
            url = `${OSSAppConfig.DATA_PORTABILITY_BASE}/${this.apiGroupURL}/portability/debtorNumber/${debtorNumber}`;
            headers = new HttpHeaders();
        }
        return this.http.get<PortabilityExtract>(url, { headers })
            .pipe(map((extractHistory: PortabilityExtract) => {
                return extractHistory;
            }), catchError((exception: Response | any) => throwError(exception))
                , finalize(() => {

                }));
    }
    /**
     * Service to generate data report extract
     *
     * @param {any} customer
     * @param {any} isCSR
     * @returns {Observable<CurrentExtract>}
     * @memberof DataPortabilityService
     */
    public generateExtract(customer, isCSR, isOSAorCAG): Observable<CurrentExtract> {
        let url = `${OSSAppConfig.DATA_PORTABILITY_BASE}/${this.apiGroupURL}/portability`;
        let headers = new HttpHeaders();
        if (isCSR) {
            headers = new HttpHeaders({ 'csr-customer': customer });
            url = `${OSSAppConfig.DATA_PORTABILITY_BASE}/${this.apiGroupURL}/portability/${customer}`;
        } else if (isOSAorCAG) {
            const debtorNumber = this.customerService.getAccNo();
            url = `${OSSAppConfig.DATA_PORTABILITY_BASE}/${this.apiGroupURL}/portability/debtorNumber/${debtorNumber}`;
            headers = new HttpHeaders();
        }
        return this.http.post(url, null, { headers })
            .pipe(map((extract: CurrentExtract) => extract
            ), catchError((exception: Response | any) => throwError(exception))
                , finalize(() => {

                }));
    }
    /**
     * Download data report as CSV
     *
     * @param {any} extractId
     * @param {any} customer
     * @returns {Observable<any>}
     * @memberof DataPortabilityService
     */
    public getExtractAsCSV(extractId, customer, isCSR, isAdmin?): Observable<any> {
        let url = `${OSSAppConfig.DATA_PORTABILITY_BASE}/${this.apiGroupURL}/portabilityextract.csv?extractId=${extractId}`;
        if (isAdmin) {
            url = `${OSSAppConfig.DATA_PORTABILITY_BASE}/${this.apiGroupURL}/portabilityextract.csv?extractId=${extractId}&customerId=${customer}`;
        }

        const params = new HttpParams();
        let headers = new HttpHeaders();
        if (isCSR) {
            params.set('customerId', customer);
            headers = new HttpHeaders({
                'csr-customer': customer
            });
        }
        const options = {
            observe: 'response' as 'body',
            responseType: 'blob' as 'json',
            params,
            headers
        };
        return this.http.get<any>(url, options)
            .pipe(map((data: any) => {
                if (data) {
                    return {
                        filename: extractId + '.csv',
                        data: data.body
                    };
                }
            })
                , catchError((exception) => throwError(exception))
                , finalize(() => {

                }));
    }
}
