import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { ProxyLoginService } from '../../../core/services/proxy-login.service';
import { OssContentService } from '../../../core/services/oss-content/oss-content.service';
import { Component, OnInit, ViewChild, ElementRef, Renderer2, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LocalizationService } from '../../../core/services/localization/localization.service';
import { Subscription } from 'rxjs';
import { NpsModalComponent } from '../../components/nps-modal/nps-modal.component';
import { CustomUiService } from '../../../shared/services/custom-ui.service';
import { BehaviorSubject } from 'rxjs';
import { NPSFeedbackDomain, NpsModalService, NPSStatus, NPSWidgetState } from '../../../core/services/nps-modal.service';
@Component({
  selector: 'oss-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit, OnChanges {

  year: number;
  localizationSubscription: Subscription;
  npsModalInvocation: Subscription;
  ROI = false;
  NI = false;
  region: any;
  npsModalOpen = false;
  npsModalDomain = NPSFeedbackDomain.General;
  npsWidgetState: NPSWidgetState;
  customerAgent = false;
  @ViewChild('footerBack') MyProp: ElementRef;

  footerLinks_gasAndElectricity_NI = [
    { text: 'Energy Supply - SME', link: this.contentService.getRedirectUrl('footerEnergySupplySme_NI') },
    { text: 'Energy Supply - Large Business', link: this.contentService.getRedirectUrl('footerEnergySupplyLargeBussiness_NI') },
    { text: 'Energy Solutions', link: this.contentService.getRedirectUrl('footerEnergySolutions_NI') },
    { text: 'Distributed Energy', link: this.contentService.getRedirectUrl('footerDistributedElements_NI') },
  ];

  footerLinks_gasAndElectricity_ROI = [
    { text: 'Energy Supply - SME', link: this.contentService.getRedirectUrl('footerEnergySupplySme_ROI') },
    { text: 'Energy Supply - Large Business', link: this.contentService.getRedirectUrl('footerEnergySupplyLargeBussiness_ROI') },
    { text: 'Energy Solutions', link: this.contentService.getRedirectUrl('footerEnergySolutions_ROI') },
    { text: 'Distributed Energy', link: this.contentService.getRedirectUrl('footerDistributedElements_ROI') },


  ];

  footerLinks_aboutSSEA_NI = [
    { text: 'Our story', link: this.contentService.getRedirectUrl('footerOurStory_NI') },
    { text: 'Energy Tips', link: this.contentService.getRedirectUrl('footerEnergyTips_NI') },
    { text: 'Energy Sources ', link: this.contentService.getRedirectUrl('footerEnergySources_NI') },
    { text: 'Careers', link: this.contentService.getRedirectUrl('footerCareers_NI') },
    { text: 'View a list of our registered companies', link: this.contentService.getRedirectUrl('footerViewRegisteredCompanies_NI') },
  ];

  footerLinks_aboutSSEA_ROI = [
    { text: 'Energy sources†', link: this.contentService.getRedirectUrl('hundredGreenEnergy_ROI') },
    { text: 'Our story', link: this.contentService.getRedirectUrl('footerOurStory_ROI') },
    { text: 'Energy Tips', link: this.contentService.getRedirectUrl('footerEnergyTips_ROI') },
    { text: 'Energy Sources ', link: this.contentService.getRedirectUrl('footerEnergySources_ROI') },
    // { text: 'View a list of our registered companies', link: this.contentService.getRedirectUrl('footerViewRegisteredCompanies_ROI') },
    { text: 'Careers', link: this.contentService.getRedirectUrl('footerAboutSSECareers') },
    { text: 'Our registered companies', link: this.contentService.getRedirectUrl('footerAboutSSEOurRegCompanies') },
  ];

  footerLinks_NI = [
    { text: 'Contact us', link: this.contentService.getRedirectUrl('footerContactUs_NI') },
    { text: 'Privacy notice', link: this.contentService.getRedirectUrl('footerPrivacyPolicy_NI') },
    { text: 'Cookies', link: this.contentService.getRedirectUrl('footerCookies_NI') },
    { text: 'Terms of Use', link: this.contentService.getRedirectUrl('footerTermsOfUse_NI') },
    { text: 'Codes of Practice', link: this.contentService.getRedirectUrl('footerCustomerCharter_NI') },
    // { text: 'View our tariffs', link: this.contentService.getRedirectUrl('footerCurrentAndOlderPlans_NI') },
    { text: 'Help', link: this.contentService.getRedirectUrl('footerCurrentAndOlderPlans_NI') },
    { text: 'General Terms & Conditions', link: this.contentService.getRedirectUrl('footerGeneraltermsAndConditions_NI') },
    { text: 'Have a complaint?', link: this.contentService.getRedirectUrl('footerHaveComplaints_NI') },
    { text: 'What to do in an emergency?', link: this.contentService.getRedirectUrl('footerWhatToDoInEmergency_NI') }
  ];

  footerLinks_ROI = [
    { text: 'Contact us', link: this.contentService.getRedirectUrl('footerContactUs_ROI') },
    { text: 'Codes of Practice', link: this.contentService.getRedirectUrl('footerCustomerCharter_ROI') },
    { text: 'Privacy notice', link: this.contentService.getRedirectUrl('footerPrivacyPolicy_ROI') },
    { text: 'Cookies', link: this.contentService.getRedirectUrl('footerCookies_ROI') },
    { text: 'Terms of Use', link: this.contentService.getRedirectUrl('footerTermsOfUse_ROI') },
    // { text: 'View our tariffs', link: this.contentService.getRedirectUrl('footerCurrentAndOlderPlans_ROI') },
    { text: 'General Terms & Conditions', link: this.contentService.getRedirectUrl('footerGeneraltermsAndConditions_ROI') },
    { text: 'Help', link: this.contentService.getRedirectUrl('footerCurrentAndOlderPlans_ROI') },
    // { text: 'Energy Efficiency', link: this.contentService.getRedirectUrl('footerEnergyEfficiency_ROI') },
    { text: 'Smart Meters', link: this.contentService.getRedirectUrl('footerSmartMeters_ROI') }


  ];
  isServiceError = false;
  @Input() userRegion;


  constructor(
    private contentService: OssContentService,
    private localizationService: LocalizationService,
    private customUIService: CustomUiService,
    private npsModalService: NpsModalService,
    private proxyLoginService: ProxyLoginService,
    private router: Router
  ) { }
  ngOnChanges(changes: SimpleChanges) {
    if(changes.userRegion && changes.userRegion?.currentValue) {
      if (changes.userRegion?.currentValue?.trim()?.toLowerCase() === 'ni') {
        this.NI = true;
        this.ROI = false;
      } else {
        this.ROI = true;
        this.NI = false;
        // console.log('roi value ...' , this.ROI)

      }
    }
  }

  ngOnInit() {
    this.proxyLoginService.isCustomerAgent$.subscribe((isCustomerAgent) => {
      this.customerAgent = isCustomerAgent;
    });
    this.year = new Date().getFullYear();
    this.localizationSubscription = this.localizationService.region$.subscribe((region) => {
      setTimeout(() => {
        if (JSON.parse(localStorage.getItem('userData')) !== null) {
          if (JSON.parse(localStorage.getItem('userData'))?.jur?.trim()?.toLowerCase() === 'ni') {
            this.NI = true;
            this.ROI = false;
          } else {
            this.ROI = true;
            this.NI = false;
          }
        } else if(region?.jurisdiction === 'NI') {
          this.NI = true;
          this.ROI = false;
        } else {
          this.ROI = true;
          this.NI = false;
        }
      }, 100);
    });
    this.npsModalInvocation = this.npsModalService.npsWidgetState$.subscribe((widgetState: NPSWidgetState) => {
      this.npsWidgetState = widgetState;
    });
    // Hide NPS Widget on router navigation, if visible
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationStart) {
        if (event.url.includes('/service-error')) {
          this.isServiceError = true;
        } else {
          this.isServiceError = false;
        }
        if (this.npsWidgetState && this.npsWidgetState.isVisible) {
          this.npsModalService.hideNPSWidget();
        }
      }
    });

  }
  /**
   * Open help modal
   *
   * @memberof SubmitReadingComponent
   */
  openNPSModal(modalDomain?: NPSFeedbackDomain) {
    this.customUIService.setModalOpenClass();
    this.npsModalDomain = modalDomain ? modalDomain : this.npsModalService.mapUrlToDomain(window.location.href);
    this.npsModalOpen = true;
    this.npsModalService.hideNPSWidget();
  }

  closeNPSModal() {
    this.npsModalOpen = false;
    this.MyProp.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  closeNPSWidget() {
    this.npsModalService.setNPSStatus(this.npsWidgetState.currentDomain, NPSStatus.Ignored);
    this.npsModalService.hideNPSWidget();
  }

}
