import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
// import {APP_BASE_HREF } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { OssContentService } from './core/services/oss-content/oss-content.service';
import { FormsModule } from '@angular/forms';

import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppHttpInterceptor } from './core/interceptor/http.interceptor';

import {
  AuthModule, OidcConfigService
} from 'angular-auth-oidc-client';
import { OSSOpenIDConfiguration, OSSAuthWellKnownEndpoints, DEFAULT_CONFIG } from './core/config/oss-oidc-config';
import { OSSAppConfig } from './core/config/app-config';
import { map, switchMap } from 'rxjs/operators';
// old one
// import { OidcConfigService } from './core/auth/oidc-config-security.service';
// export function loadConfig(oidcConfigService: OidcConfigService): Function {
// console.log('APP_INITIALIZER STARTING');
// return () => oidcConfigService.load_using_custom_stsServer(`${OSSAppConfig.IDSVR_BASE_URL}/.well-known/openid-configuration`);
// }
// tslint:disable-next-line: ban-types
export function ossContentsFactory(ossContentService: OssContentService): Function {
  return () => ossContentService.bootstrapOssLocalData();
}
// export function configureAuth(oidcConfigService: OidcConfigService, httpClient: HttpClient, openConfig: OSSOpenIDConfiguration) {
//   const setupAction$ = httpClient.get<any>(`${OSSAppConfig.IDSVR_BASE_URL}/.well-known/openid-configuration`).pipe(
//     map((customConfig) => {
//       return {
//         'openIDConfig': {
//           stsServer: DEFAULT_CONFIG.stsServer,
//           redirectUrl: DEFAULT_CONFIG.redirect_url,
//           clientId: DEFAULT_CONFIG.client_id,
//           responseType: DEFAULT_CONFIG.response_type,
//           scope: DEFAULT_CONFIG.scope,
//           postLogoutRedirectUri: DEFAULT_CONFIG.post_logout_redirect_uri,
//           startCheckSession: DEFAULT_CONFIG.start_checksession,
//           silentRenew: DEFAULT_CONFIG.silent_renew,
//           silentRenewUrl: DEFAULT_CONFIG.silent_redirect_url,
//           postLoginRoute: DEFAULT_CONFIG.post_login_route,
//           forbiddenRoute: DEFAULT_CONFIG.forbidden_route,
//           unauthorizedRoute: DEFAULT_CONFIG.unauthorized_route,
//           logLevel: 0, // LogLevel.logLevel or DEFAULT_CONFIG.logLevel
//           maxIdTokenIatOffsetAllowedInSeconds: DEFAULT_CONFIG.max_id_token_iat_offset_allowed_in_seconds,
//           historyCleanupOff: true,
//           renewTimeBeforeTokenExpiresInSeconds: 3
//          // autoUserinfo: true,
//         },
//         'wellKnown': {
//         'issuer': customConfig.issuer,
//         'jwksUri': customConfig.jwks_uri,
//         'authorizationEndpoint': customConfig.authorization_endpoint,
//         'tokenEndpoint': customConfig.token_endpoint,
//         'userinfoEndpoint': customConfig.userinfo_endpoint,
//         'endSessionEndpoint': customConfig.end_session_endpoint,
//         'checkSessionIframe': customConfig.check_session_iframe,
//         'revocationEndpoint': customConfig.revocation_endpoint,
//         'introspectionEndpoint': customConfig.introspection_endpoint,

//       }
//       };
//     }),
//     switchMap((config) => oidcConfigService.withConfig(config.openIDConfig, config.wellKnown)
//       ));


//   return () => setupAction$.toPromise();

// }
export function configureAuth(oidcConfigService: OidcConfigService) {
  return () =>
      oidcConfigService.withConfig({
          stsServer: DEFAULT_CONFIG.stsServer,
          authWellknownEndpoint:`${OSSAppConfig.IDSVR_BASE_URL}/.well-known/openid-configuration`,
          redirectUrl: DEFAULT_CONFIG.redirect_url,
          postLogoutRedirectUri: DEFAULT_CONFIG.post_logout_redirect_uri,
          clientId: DEFAULT_CONFIG.client_id,
          scope:  DEFAULT_CONFIG.scope,
          responseType: DEFAULT_CONFIG.response_type,
          startCheckSession: DEFAULT_CONFIG.start_checksession,
          silentRenew: true, // Silent renew
          silentRenewUrl: DEFAULT_CONFIG.silent_renew_url,
          postLoginRoute: DEFAULT_CONFIG.post_login_route,
          forbiddenRoute: DEFAULT_CONFIG.forbidden_route,
          unauthorizedRoute: DEFAULT_CONFIG.unauthorized_route,
          maxIdTokenIatOffsetAllowedInSeconds: DEFAULT_CONFIG.max_id_token_iat_offset_allowed_in_seconds,
          historyCleanupOff: true,
          renewTimeBeforeTokenExpiresInSeconds: 3,
          silentRenewTimeoutInSeconds: 20,
          logLevel: 0,
      });

}
@NgModule({
  declarations: [
    AppComponent
    ],
  imports: [
    BrowserModule, BrowserAnimationsModule, AuthModule.forRoot(), FormsModule, 
    AppRoutingModule, SharedModule, CoreModule, HttpClientModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true },
    {
    // Provider for APP_INITIALIZER
    provide: APP_INITIALIZER,
    useFactory: ossContentsFactory,
    deps: [OssContentService, ],
    multi: true
  }, OidcConfigService,
  {
    provide: APP_INITIALIZER,
    useFactory: configureAuth,
    deps: [OidcConfigService, HttpClient],
    multi: true
  },
    OSSOpenIDConfiguration],
  bootstrap: [AppComponent]
})
export class AppModule { }
