<div class="header container-fluid upper-ribbon d-none d-md-block">
  <div class="row"> 
    <div class="col-auto mr-auto header-bar-item">
      <nav class="navbar navbar-expand-md pt-0 pb-0 navbar-light">
        <div class="collapse navbar-collapse">
          <ul class="navbar-nav sector-nav">
            <li class="nav-item" *ngFor="let sector of sectors">
              <a *ngIf="sector.text==='For business'" class="nav-link active" href="{{sector.link}}">
                {{sector.text}}
              </a>
              <a *ngIf="sector.text!='For business'" class="nav-link " href="{{sector.link}}">
                {{sector.text}}
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>

    <div class="col-auto ml-auto header-bar-item">
      <nav class="navbar navbar-expand-md pt-0 pb-0 navbar-light">
        <div class="collapse navbar-collapse">
          <ul class="navbar-nav contact-us">
            <li class="nav-item toplink" id="search">
              <a class="nav-link search" href="{{searchUrl}}">
                <!-- <span class="sr-only">(current)</span> -->
              </a>
            </li>
            <li class="nav-item toplink" id="help">
              <a class="nav-link" href="{{helpUrl}}">Help
                <!-- <span class="sr-only">(current)</span> -->
              </a>
            </li>
            <li class="nav-item toplink"  id="contact-us">
              <a class="nav-link" href="{{contactUsUrl}}">Contact us
                <!-- <span class="sr-only">(current)</span> -->
              </a>
            </li>
            <li class="nav-item dropdown" dropdown>
              <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false" aria-controls="regionPopup" dropdownToggle>
                {{currentRegion.name}}
              </a>
              <div id="regionPopup" class="dropdown-menu" aria-labelledby="navbarDropdown">
                <div *ngFor="let region of regionList">
                  <a class="dropdown-item" (click)="selectUserRegion(region)" (keyup)="selectUserRegion(region)">{{region.name}}</a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</div>

<nav class="navbar navbar-expand-md navbar-light site-menu">

  <button [ngClass]="{'collapsed':!navToggled}" (click)="toggleNav()"
    class="navbar-toggler navbar-toggler-right collapsed site-menu-toggler" type="button" aria-expanded="false"
    aria-label="Toggle navigation">
    <span> </span>
    <span> </span>
    <span> </span>
  </button>
  <a class="navbar-brand logo" aria-label="SSE Airtricity Online Self Service" href="{{parentSiteUrl}}"></a>

  <div [ngClass]="{'show':navToggled}" class="collapse navbar-collapse d-none d-md-flex">
    <!-- <oss-account-menu></oss-account-menu> -->
  
    <ul class="navbar-nav ml-auto site-menu-nav bottom-selection-indicator">
        
      <ng-container *ngFor="let navItem of navMenuItems_COM">
        <li class="nav-item menu-item" *ngIf="!navItem.submenu?.length > 0">
          <!-- <a *ngIf="navItem.id==='account'" class="menu-link" [routerLink]="navItem.link"
            routerLinkActive="active" [ngClass]="navItem.customClasses">
            <span class="menu-link-text">{{navItem.text}}</span>
          </a>
          <a *ngIf="navItem.id==='search'" class="menu-link" href="javascript:;" (click)="showSearchModal()"
            [ngClass]="navItem.customClasses">
            <span class="menu-link-text">{{navItem.text}}</span>
          </a> -->
          <a  [routerLink]="navItem.link"
          routerLinkActive="active" class="menu-link" href="{{navItem.link}}"
            [ngClass]="navItem.customClasses">
            <span class="menu-link-text">{{navItem.text}}</span>
          </a>
        </li>
        
        
      </ng-container> 
      <li class="nav-item menu-item">
        <a class="logout-btn menu-link"><span class="logout-btn-span" (click)="logout()">Logout</span></a>
    </li>
    </ul>
  </div>
  <div role="button" aria-label="Logout" class="my-account-icon" (click)="logout()" (keyup)="logout()">

  </div>

</nav>


<!-- hamburger menu  -->
<div class="m-menu-overlay is-visible" data-overlay-name="menu-overlay" data-js-module="menu-overlay"
  [ngClass]="{'is-visible':navToggled,'is-hiding':!navToggled}">
  <div class="header">
    <div class="inner">
      <!-- Close Link component -->
      <div class="c-close-link ">
        <a role="button" aria-label="close navigation menu" (click)="toggleNav()" (keyup)="toggleNav()" class="link"
          data-close-target="menu-overlay"></a>
      </div>
      <!-- End Close Link component -->
    </div>
  </div>
  <div class="content">
    <div class="inner">
      <!-- Search from the menu -->

      <!-- <form novalidate (ngSubmit)="search()" class="search-form">
        <input type="text" placeholder="Search" name="search" [(ngModel)]="searchQuery" autocomplete="off">
        <button class="btn-submit" type="submit"></button>
      </form> to be uncommented-->

      <!-- Ends Search from the menu -->
      <!-- Domestic customer navigation menu -->
      <div class="dom-menu is-visible" *ngIf="isDomMenu">
        <ul class="domestic-navigation">
          <li *ngFor="let navItem of navMenuItems_COM" id='{{navItem.id}}'
            (click)="toggleSubMenu(toggleSub,navItem.id)">
            <!-- <a *ngIf="navItem.id==='account'" class="nav-link menu-link" [ngStyle]="{'disabled':navItem.submenu}"
              [routerLink]="navItem.link" [routerLinkActive]="['active']"
              [ngClass]="{'sub-menu-trigger':navItem.submenu?.length > 0,'open':toggleSub&&openedSubMenu==navItem.id}"
              (click)="navToggled=false">{{navItem.text}}
              <span *ngIf="navItem.submenu?.length > 0">
              </span>
              <span *ngIf="navItem.submenu?.length > 0">
              </span></a> -->

            <a *ngIf="navItem.id!='account' && navItem.id!='search'" class="nav-link menu-link"
              [ngStyle]="{'disabled':navItem.submenu}" href="{{navItem.link}}"
              [ngClass]="{'sub-menu-trigger':navItem.submenu?.length > 0,'open':toggleSub&&openedSubMenu==navItem.id}">{{navItem.text}}
              <span *ngIf="navItem.submenu?.length > 0">
              </span>
              <span *ngIf="navItem.submenu?.length > 0">
              </span>
            </a>
            <!-- displays submenus only when the menu item contains submenu -->
            <div class="sub-menu" [ngClass]="{'open':toggleSub && openedSubMenu==navItem.id}">
              <ul>
                <li class="link" *ngFor="let submenuitem of navItem.submenu">
                  <a class="nav-link" href="{{submenuitem.link}}">{{submenuitem.text}}</a>

                </li>
              </ul>
            </div>
            <!-- ends submenu -->
          </li>
        </ul>
        <div class="change-user-region">
          <!-- Input Select Component -->
          <!-- <div class="styled-select square">
            <select>
              <option *ngFor="let region of userRegions">{{region.text}}</option>
            </select>
          </div> -->
          <div class="styled-select square dropdown" dropdown>
            <a class=" dropdown-toggle" id="overlayRegionDropdown" role="button" aria-haspopup="true"
              aria-expanded="false" aria-controls="overlayRegionPopup" dropdownToggle>
              {{currentRegion.name}}
              <span class="arrow"></span>
            </a>
            <div id="overlayRegionPopup" *dropdownMenu class="dropdown-menu" aria-labelledby="overlayRegionDropdown">
              <ng-container *ngFor="let region of regionList">
                <a class="dropdown-item" (click)="selectUserRegion(region)" (keyup)="selectUserRegion(region)">{{region.name}}</a>
              </ng-container>
            </div>
          </div>

          <!-- End Input Select Component -->
        </div>
      </div>
      <!-- Ends Domestic customer navigation menu -->

      <!-- Commercial customer navigation menu -->
      <div class="com-menu" *ngIf="!isDomMenu">
        <ul class="domestic-navigation">
          <li *ngFor="let navItem of navMenuItems_COM" 
            (click)="toggleNav()">
            <a class="nav-link menu-link"  [routerLink]="navItem.link"
            routerLinkActive="active" href="{{navItem.link}}" >{{navItem.text}}
              <!-- <span *ngIf="navItem.submenu?.length > 0">
              </span>
              <span *ngIf="navItem.submenu?.length > 0">
              </span> -->
            </a>
            <!-- displays submenus only when the menu item contains submenu -->
            <!-- <div class="sub-menu" [ngClass]="{'open':toggleSub && openedSubMenu==navItem.id}">
              <ul>
                <li class="link" *ngFor="let submenuitem of navItem.submenu">
                  <a class="nav-link" href="{{submenuitem.link}}">{{submenuitem.text}}</a>

                </li>
              </ul>
            </div> -->
            <!-- ends submenu -->
          </li>
          <li>
            <a class="nav-link menu-link" (click)="logout()">Logout</a>
          </li>
        </ul>
        <!-- <div class="change-user-region">
          <div class="styled-select square dropdown" dropdown>
            <a class=" dropdown-toggle" id="overlayRegionDropdown" role="button" aria-haspopup="true"
              aria-expanded="false" aria-controls="overlayRegionPopup" dropdownToggle>
              {{currentRegion.name}}
              <span class="arrow"></span>
            </a>
            <div id="overlayRegionPopup" *dropdownMenu class="dropdown-menu" aria-labelledby="overlayRegionDropdown">
              <ng-container *ngFor="let region of regionList">
                <a class="dropdown-item" (click)="selectCommercialUserRegion(region)" (keyup)="selectCommercialUserRegion(region)">{{region.name}}</a>
              </ng-container>
            </div>
          </div>
        </div> -->
      </div>
      <!-- Ends Commercial customer navigation menu -->
    </div>
  </div>
  <div class="footer">
    <div class="c-navigation-menu-toggle">
      <!-- <div class="tabs-under">
        <div class="tab">
          <a class="selected" [ngClass]="{'selected': isDomMenu}" (click)="isDomMenu = true" (keyup)="isDomMenu = true">At home</a>
        </div>
        <div class="tab">
          <a class="" [ngClass]="{'selected': !isDomMenu}" (click)="isDomMenu = false" (keyup)="isDomMenu = false">For business </a>
        </div>
      </div> -->
      <ul class="mobile-list-toplinks">
        <li>
          <a class="menu-link" href="{{homeUrl}}"> At home</a>
        </li>
        <li>
          <a class="menu-link active" href="{{businessUrl}}">For business</a>
        </li>
        <li>
          <a class="menu-link" href="{{rewardUrl}}">SSE Reward</a>
        </li>
        <li>
          <a class="menu-link" href="{{greenUrl}}">Generation Green</a>
        </li>
        <li>
          <a class="menu-link" href="{{helpUrl}}">Help</a>
        </li>
        <li>
          <a class="menu-link" href="{{contactUsUrl}}">Contact us</a>
        </li>
      </ul>
    </div>

  </div>
</div>

<oss-search-modal *ngIf="isSearchModalShown" (hide)="closeSearchModal($event)" [searchUrl]="searchUrl">
</oss-search-modal>