import { County } from '../../models/county.model';
import { Country } from '../../models/country.model';
import { OSSAppConfig } from '../../../core/config/app-config';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';

@Injectable()
export class LocalDataService {

  constructor(
    private http: HttpClient,
  ) { }

  getCountryList(): Observable<Country[]> {
    const path = `${OSSAppConfig.jsonBasePath}/country-list.json`;
    return this.http.get<Country[]>(path)
      .pipe(map(countries => countries)
      , catchError(error => throwError(error))
      , finalize(() => {

      }));
  }

  getCountyList(): Observable<{ ROI: County[], NI: County[] }> {
    const path = `${OSSAppConfig.jsonBasePath}/county-list.json`;
    return this.http.get<{ ROI: County[], NI: County[] }>(path)
      .pipe(map(counties => counties)
      , catchError(error => throwError(error))
      , finalize(() => {

      }));
  }
  getBillHoverText(): Observable<any> {
    const path = `${OSSAppConfig.jsonBasePath}/bill-hover-text.json`;
    return this.http.get<any>(path)
            .pipe(map((billHoverText: any) => {
                return billHoverText.items;
            }), catchError((exception: Response | any) => throwError(exception))
            , finalize(() => {
        }));
  }

}
