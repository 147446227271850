<div [config]="{ show: true, animated: true, backdrop: 'static',  keyboard: false}" [ngClass]="customClass" (onHidden)="hideModal()" bsModal
    #npsModal="bs-modal" class="modal oss-modal fade" tabindex="-1" role="dialog" aria-hidden="true"  id="feedback-modal">
    <div class="modal-dialog modal-{{modalSize}}" >
        <div class="modal-content">
            <div class="modal-header p-0">
                <h2 class="modal-title meter-read-title">How was your experience with SSE Airtricity?</h2>
                <div class="nps-close-icon" (click)="hideModal()" (keyup)="hideModal()" aria-label="Close"></div>
            </div>
            <div class="modal-body">
                <div class="content">
                    <div class="inner"> 
                        <oss-inline-loader *ngIf="sending" theme="dark"></oss-inline-loader>
                        <div class="feedback-form" *ngIf="!isFeedbackSubmitted">
                            <div class="nps-form-field">
                                <div class="input-role">
                                    <label for="roleFilter">Which option best describes your role?</label>
                                </div>
                                <div class="heading form-group col-md-5 p-0 col-xs-12">
                                    <ng-select class="oss-ngselect"
                                              [items]="roleType"
                                              bindLabel="name"
                                              placeholder="Choose an option"
                                              [clearable]=false                  
                                              [hideSelected]=true
                                              [markFirst]=false
                                              [(ngModel)]="selectedRoleType"
                                              [dropdownPosition]="'bottom'"
                                              id="roleFilter">
                                    </ng-select>
                                  </div>
                            </div>

                            <div class="nps-form-field">
                                <div class="c-input-scale" data-js-component="inputScale">
                                    <label id="question" for="recommend">How likely are you to recommend SSE Airtricity?</label>
                                    <input type="hidden" name="recommend" id="recommend">
                                    <div class="slider">
                                        <!--<div class="track"></div>-->
                                        <ul>
                                            <li *ngFor="let number of [0,1,2,3,4,5,6,7,8,9,10]">
                                                <span (click)="setRating('improvement', number)" (keyup)="setRating('improvement', number)" *ngIf="number==0">Very
                                                    unlikely</span>
                                                <span (click)="setRating('improvement', number)" (keyup)="setRating('improvement', number)"
                                                    *ngIf="number==10">Likely</span>
                                                <a (click)="setRating('improvement', number)" href="javascript:;"
                                                    [ngClass]="{'highlight': number===improveRating}">{{number}}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="nps-form-field">
                                <div class="c-input-scale" data-js-component="inputScale">
                                    <label for="satisfied" id="question">How satisfied are you with your online experience?</label>
                                    <input type="hidden" name="satisfied" id="satisfied">
                                    <div class="slider">
                                        <!--<div class="track"></div>-->
                                        <ul>
                                            <li *ngFor="let number of [0,1,2,3,4,5,6,7,8,9,10]">
                                                <span (click)="setRating('satisfaction', number)" (keyup)="setRating('satisfaction', number)" *ngIf="number==0">Very
                                                    unlikely</span>
                                                <span (click)="setRating('satisfaction', number)" (keyup)="setRating('satisfaction', number)"
                                                    *ngIf="number==10">Likely</span>
                                                <a (click)="setRating('satisfaction', number)" href="javascript:;"
                                                    [ngClass]="{'highlight': number===satisfactionRating}">{{number}}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="nps-form-field">
                                <div class="c-input-scale" data-js-component="inputScale">
                                    <label for="help" id="question">Overall, how easy was it to get the help you needed today with SSE Airtricity?</label>
                                    <input type="hidden" name="help" id="help">
                                    <div class="slider">
                                        <!--<div class="track"></div>-->
                                        <ul>
                                            <li *ngFor="let number of [0,1,2,3,4,5,6,7,8,9,10]">
                                                <span (click)="setRating('help', number)" (keyup)="setRating('help', number)" *ngIf="number==0">Very
                                                    unlikely</span>
                                                <span (click)="setRating('help', number)" (keyup)="setRating('help', number)"
                                                    *ngIf="number==10">Likely</span>
                                                <a (click)="setRating('help', number)" href="javascript:;"
                                                    [ngClass]="{'highlight': number===helpRating}">{{number}}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="nps-form-field" *ngIf = "feedbackFlag">
                                <div class="c-input-textarea">
                                    <div class="input-wrap" id="question">
                                        <textarea [(ngModel)]="comment2" name="comment" id="comment2" value=""
                                            placeholder="We’re sorry you didn’t find what you were looking for today. Would you like to leave feedback to help us make future improvements to our online service?" tabindex="0" autocomplete="false"
                                            class="form-control"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="nps-form-field">
                                <div class="c-input-textarea">
                                    <div >
                                        <label for="comment" id="question" >We value your feedback to make improvements. What can we do better?</label>
                                        <div class="input-wrap">
                                            <textarea [(ngModel)]="comment" name="comment" id="comment" value=""
                                                placeholder="" tabindex="0" autocomplete="false"
                                                class="form-control"></textarea>
                                        </div>
                                    </div>
                                    <div class="submit">
                                        <button (click)="onSubmit()" type="button"
                                        class="btn btn-large btn-primary btn-nps-submit" [ngClass]="{'active-button': ( searchPremiseFuelType)}"  [disabled]="!(selectedRoleType) || !satisfyFlag
                                        || !improveFlag || !helpFlag"> Send </button>
                                    <button *ngIf="isFeedbackSubmitted" (click)="hideModal()" type="button"
                                        class="btn btn-large btn-primary btn-feedback-submit"> Close </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="feedback-response" *ngIf="isFeedbackSubmitted">
                            <h2>Thank You</h2>
                            <p>We have received your feedback and will use it to improve our service.</p>
                            <p *ngIf="jurisdiction=='ROI'">If you would like to contact us, our <a
                                    href="https://www.sseairtricity.com/ie/home/help-centre-ie/contact-us/"
                                    target="_blank" rel="noopener">Customer Support Team</a> will be happy to help.
                            </p>
                            <p *ngIf="jurisdiction=='NI'">If you would like to contact us, our <a
                                    href="https://www.sseairtricity.com/uk/home/help-centre/contact-us" target="_blank"
                                    rel="noopener">Customer Support Team</a> will be happy to help.
                            </p>
                        </div>
                    </div>
                </div>

            </div>
         
        </div>
    </div>
</div>