<div class="spinner-container inline" [ngClass]="{'light':theme === 'light', 'dark': theme === 'dark', 'no-bg': nobackground === true}">
    <div class="spinkit inline-loader">
        <div class="sk-three-bounce">
            <div class="sk-child sk-bounce1"></div>
            <div class="sk-child sk-bounce2"></div>
            <div class="sk-child sk-bounce3"></div>
            <div class="sk-child sk-bounce4"></div>
        </div>
    </div>
    <div class="spinner-overlay inline"></div>
</div>