import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { CacheManager } from '../../core/services/cache-manager.service';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
@Injectable()
export class CustomUiService {
  private renderer: Renderer2;
  npsModalOpen: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private rendererFactory: RendererFactory2,
              private cacheManager: CacheManager) {

    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public setModalOpenClass() {
    this.cacheManager.quickSave('bodyScrollTop', document.body.scrollTop);
    if (navigator.userAgent.match(/iPhone/i)) {
      document.body.scrollTop = 0;
    }
    this.renderer.addClass(document.getElementsByTagName('html')[0], 'modal-open');
  }

  public unSetModalOpenClass() {
    document.body.scrollTop = this.cacheManager.quickRetrieve('bodyScrollTop');
    this.cacheManager.quickRemove('bodyScrollTop');
    this.renderer.removeClass(document.getElementsByTagName('html')[0], 'modal-open');
  }
}
