import { Injectable } from '@angular/core';

@Injectable()
export class LoaderService {
    body = document.getElementsByTagName('body')[0];
    constructor() { }
    start() {

        this.body.classList.add('loader-shown');
        // document.querySelector('body').classList.add("loader-shown");
    }

    stop() {
        const classList = Array.prototype.slice.call(this.body.classList);
        // tslint:disable-next-line: one-variable-per-declaration
        let className = '', noOfRepetition = 0;
        classList.forEach((val, key) => {
            if (val.indexOf('loader-from-') > -1) { className = val; noOfRepetition++; }
        });
        if (className) {
            this.body.classList.remove(className);
        }
        if (noOfRepetition <= 1) {
            this.body.classList.remove('loader-shown');
        }
    }

}
