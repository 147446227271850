import { Address } from '../models/account/address.model';
import { PremiseDetail } from '../models/premise/premise-detail.model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterMultipremise'
})
export class FilterMultipremisePipe implements PipeTransform {
  // tslint:disable-next-line: ban-types
  ALL: String = 'All';
  // tslint:disable-next-line: ban-types
  GAS: String = 'gas';
  // tslint:disable-next-line: ban-types
  ELECTRICITY: String = 'elec';



  // tslint:disable-next-line: ban-types
  removeWhiteSpace(text): String {
    return text.replace(/\s/g, '');
  }

  transform(items: PremiseDetail[], searchPremiseText: string, searchPremiseFuelType: any): any[] {
    searchPremiseText = searchPremiseText ? searchPremiseText : '';
    if (!items) { return []; }
    if (!searchPremiseText && searchPremiseFuelType.type === this.ALL) {
      return items;
    }

    return items.filter(premiseItem => {
      let premiseDetails = '';

      if (premiseItem.premise && premiseItem.premise.address) {
        Object.setPrototypeOf(premiseItem.premise.address, Address.prototype);
        premiseDetails = premiseDetails.concat(premiseItem.premise.address.completeAddress);
      }

      if (searchPremiseFuelType.type === this.ALL) {
        if (premiseItem.premise && premiseItem.premise.mprn) {
          premiseDetails = premiseDetails.concat(premiseItem.premise.mprn);
        }
        if (premiseItem.premise && premiseItem.premise.gprn) {
          premiseDetails = premiseDetails.concat(premiseItem.premise.gprn);
        }
      } else if (searchPremiseFuelType.type === this.ELECTRICITY) {
        if (premiseItem.premise && premiseItem.premise.mprn) {
          premiseDetails = premiseDetails.concat(premiseItem.premise.mprn);
        } else {
          return false;
        }
      } else if (searchPremiseFuelType.type === this.GAS) {
        if (premiseItem.premise && premiseItem.premise.gprn) {
          premiseDetails = premiseDetails.concat(premiseItem.premise.gprn);
        } else {
          return false;
        }
      }

      return !searchPremiseText || premiseDetails.toUpperCase().indexOf(searchPremiseText.toUpperCase()) > -1;
    });

  }

}
