import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FooterComponent, HeaderComponent, SearchModalComponent } from './layout';
import { NotFoundComponent, ServerErrorComponent, MaintenancePageComponent } from './components';
import { NpsModalComponent } from './components/nps-modal/nps-modal.component';
import { FaqComponent } from './components/faq/faq.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { InlineLoaderComponent } from './components/inline-loader/inline-loader.component';
import { AccountMenuComponent } from './layout/account-menu/account-menu.component';
import { ProxyBannerComponent } from './layout/proxy-banner/proxy-banner.component';
import { DataPortabilityComponent } from './components/data-portability/data-portability.component';
import { OidcRedirectComponent } from './components/oidc-redirect/oidc-redirect.component';
import { OidcCallbackComponent } from './components/oidc-callback/oidc-callback.component';
import { OidcSignoutComponent } from './components/oidc-signout/oidc-signout.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { DaterangePickerComponent } from './components/daterange-picker/daterange-picker.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverConfig, PopoverContainerComponent, PopoverDirective, PopoverModule } from 'ngx-bootstrap/popover';
import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CustomUiService } from './services/custom-ui.service';
import { CustomerService } from './services/customer.service';
import { AccountService } from './services/account.service';
import { PreferencesService } from './services/preferences.service';
import { PremiseService } from './services/premise.service';
import { TenancyService } from './services/tenancy.service';
import { BillingService } from './services/billing.service';
import { LocalDataService } from './services/local-data/local-data.service';
import { FaqService } from './services/faq.service';
import { AddressParserService } from './services/address-parser/address-parser.service';
import { DataPortabilityService } from './services/data-portability.service';
import { AccountMaintenanceService } from './services/account-maintenance.service';
import { TransactionsHandlerService } from './services/transactions-handler/transactions-handler.service';
import { CurrencyMaskDirective } from './directives/currency-mask/currency-mask.directive';
import { CurrencyMaskService } from './directives/currency-mask/currency-mask.service';
import { LoaderService } from './components/loader/loader.service';
import { PaymentService } from './services/payment.service';
import { UsageReportService } from './services/usage-report.service';
import { DateHandlerService } from './services/date-handler/date-handler.service';
import { OssContentPipe } from './pipes/oss-content.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { FilterMultipremisePipe } from './pipes/filter-multipremise.pipe';
import { FilterSupplyAddressPipe } from './pipes/filter-supplyAddress.pipe';
import { CountryListFilterPipe } from './pipes/country-list-filter.pipe';
import { PaymentHeaderComponent } from './layout/payment-header/payment-header.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgSelectModule } from '@ng-select/ng-select';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { RouterModule } from '@angular/router';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { MeterService } from './services/meter.service';
import { PaginationComponent } from './components/pagination/pagination.component';
import { LoaderComponent } from './components/loader/loader.component';
import { AdminSegmentPipePipe } from './pipes/admin-segment-pipe.pipe';
import { FilterBillsPipe } from './pipes/filterBills.pipe';
import { MultiaccountOverviewGroupsComponent } from './components/multiaccount-overview-groups/multiaccount-overview-groups.component';
import { FilterAccountgrpPipe } from './pipes/filter-accountgrp.pipe';
import { MultiAccBillsummaryComponent } from '../features/account-overview/multi-acc-billsummary/multi-acc-billsummary.component';
import { UsageDropdownComponent } from './components/dropdown/usage-dropdown/usage-account-dropdown.component';
import { FilterAccountNumberPipe } from './pipes/filter-accountNumber.pipe';
import { UsageBarGraphComponent } from './components/usage-bar-graph/usage-bar-graph.component';
import { DataexportPremiseFilterPipe } from './pipes/dataexport-premise-filter.pipe';
import { DataexportSegmentFilterPipe } from './pipes/dataexport-segment-filter.pipe';
import { MaxImportGraphComponent } from './components/max-import-graph/max-import-graph.component';
import { CostBreakdownGraphComponent } from './components/cost-breakdown-graph/cost-breakdown-graph.component';
import { SupplyAddressSearchPipe } from './pipes/supply-address-search.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { AccountFilterPipe } from './pipes/account-filter.pipe';
import { UsageReportDropdownComponent } from './components/dropdown/usage-report-dropdown/usage-report-dropdown.component';
import { HeBannerComponent } from './components/he-baaner/he-banner.component';
@NgModule({
  declarations: [OidcCallbackComponent,
    OidcRedirectComponent, CurrencyMaskDirective,
    OidcSignoutComponent, SearchModalComponent, InlineLoaderComponent, NotFoundComponent, ServerErrorComponent, MaintenancePageComponent,
    UnauthorizedComponent, FooterComponent, HeaderComponent, NpsModalComponent, FaqComponent,
    CountryListFilterPipe, OssContentPipe, SafeHtmlPipe, FilterMultipremisePipe, FilterSupplyAddressPipe,
    DataPortabilityComponent, ProxyBannerComponent, AccountMenuComponent, LoaderComponent, PaymentHeaderComponent,
    DaterangePickerComponent, AdminSegmentPipePipe, MultiaccountOverviewGroupsComponent, FilterAccountgrpPipe,
    DropdownComponent, FilterBillsPipe,
    MultiAccBillsummaryComponent, PaginationComponent,
    UsageDropdownComponent,
    DataexportPremiseFilterPipe,DataexportSegmentFilterPipe,
    FilterAccountNumberPipe, UsageBarGraphComponent, MaxImportGraphComponent, CostBreakdownGraphComponent,
    SupplyAddressSearchPipe, SearchFilterPipe, AccountFilterPipe, UsageReportDropdownComponent, HeBannerComponent],
  imports: [
    CommonModule, FormsModule, BsDatepickerModule.forRoot(), ModalModule.forRoot(), PopoverModule.forRoot(), TooltipModule.forRoot(),
    BsDropdownModule.forRoot(), NgSelectModule, NgxPageScrollCoreModule, AccordionModule.forRoot(),InfiniteScrollModule,
    BsDropdownModule.forRoot(), SelectDropDownModule, RouterModule, ReactiveFormsModule
  ],
  exports: [OidcCallbackComponent, DaterangePickerComponent,
    OidcRedirectComponent, InlineLoaderComponent, FaqComponent,
    OidcSignoutComponent, NotFoundComponent, ServerErrorComponent, MaintenancePageComponent, CurrencyMaskDirective,
    UnauthorizedComponent, FooterComponent, HeaderComponent, NpsModalComponent,
    ProxyBannerComponent, DataPortabilityComponent, AccountMenuComponent,
    CountryListFilterPipe, OssContentPipe, FilterMultipremisePipe, FilterSupplyAddressPipe, FilterBillsPipe,
    SafeHtmlPipe, AdminSegmentPipePipe, LoaderComponent, PaymentHeaderComponent, DropdownComponent, MultiaccountOverviewGroupsComponent,
    MultiAccBillsummaryComponent, UsageDropdownComponent, FilterAccountNumberPipe, PaginationComponent,
    UsageBarGraphComponent, MaxImportGraphComponent, CostBreakdownGraphComponent, SupplyAddressSearchPipe, UsageReportDropdownComponent, HeBannerComponent
  ],
  providers: [
    CurrencyPipe, DatePipe, SafeHtmlPipe, BillingService, FaqService, FilterBillsPipe,
    CurrencyMaskService, DateHandlerService, PaymentService, TransactionsHandlerService,
    LoaderService, AccountMaintenanceService, CustomUiService, CountryListFilterPipe,
    OssContentPipe, FilterMultipremisePipe, FilterSupplyAddressPipe, CustomerService,
    AccountService, PreferencesService, PremiseService, TenancyService, LocalDataService,
    AddressParserService, DataPortabilityService, MeterService, AdminSegmentPipePipe,
    BsModalRef, FilterAccountNumberPipe, UsageReportService, SupplyAddressSearchPipe
  ]
})
export class SharedModule { }
