import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { of, Observable } from 'rxjs';

export type HandleError =
  <T> (operation?: string, result?: T) => (error: HttpErrorResponse) => Observable<T>;

@Injectable()
export class HttpErrorHandler {
  constructor() { }
  createHandleError = (serviceName = '') => <T>
    (operation = 'operation', result = {} as T) => this.handleError(serviceName, operation, result)

  handleError<T>(serviceName = '', operation = 'operation', result = {} as T) {

    return (error: HttpErrorResponse | any): Observable<T> => {
      let errMessage = '';
      let errStatus = '';
      if (!navigator.onLine) {
        // Handle offline error
        // this.toastr.error('No Internet Connection');
      }
      switch (error.status) {
        case 404:
          errStatus = `${error.status}: Not found`;
          errMessage = 'The service not available';
          break;
        case 401:
          errStatus = `${error.status}: Unauthorized Access`;
          errMessage = 'You have attempted to access a page for which you are not authorized.';
          break;
        case 403:
          errStatus = `${error.status}: ${error.title}`;
          errMessage = error.detail;
          break;
        default:
          break;
      }
      // this.toastr.error(errMessage, errStatus);
      return of(result);
    };

  }
}
