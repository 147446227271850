import { OtacCodeValidity } from '../models/account/otac-code-validity.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { HttpOptions } from '../../core/services/http-options.service';
import { OSSAppConfig } from '../../core/config/app-config';
import { LoaderService } from '../components/loader/loader.service';
import { CacheManager } from '../../core/services/cache-manager.service';

@Injectable()
export class AccountMaintenanceService {
  private apiGroupURL = OSSAppConfig.API_GROUP[OSSAppConfig.API_SRC].accountmaintenance;
  constructor(
    private http: HttpClient,
    private httpOptions: HttpOptions,
    private loader: LoaderService,
    private cacheManager: CacheManager
  ) { }

  public sendAccnumber(accNumber: string, noEmail: boolean) {
    this.cacheManager.quickSave('registrationRequirements', { accountNumber: accNumber, isEmailRequired: noEmail });
  }

  public clearAccnumber() {
    this.cacheManager.quickRemove('registrationRequirements');
  }

  public getAccnumber(): any {
    const data = this.cacheManager.quickRetrieve('registrationRequirements');
    return data;
  }

  public setLinkExpiredStatus() {
    this.cacheManager.quickSave('isVerificationLinkExpired', true);
  }

  public getLinkExpiredStatus(): any {
    return this.cacheManager.quickRetrieve('isVerificationLinkExpired');
  }

  public clearLinkExpiredStatus() {
    this.cacheManager.quickRemove('isVerificationLinkExpired');
  }

  public regRequirements(data) {
    const headers = new HttpHeaders({ isLoggedOutJourney: 'true' });
    const debtorNum = data.accountNumber;
    return this.http.get(
      `${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL}/registration/customers/` + debtorNum + `/requirements`,
      { headers }
    ).pipe(map((response: Response) => {
      return response;
    }), catchError((exception: Response | any) => throwError(exception))
      , finalize(() => {
      }));
  }

  public verifyMobileNumber(accountNumber, data) {
    const params = {};
    const headers = new HttpHeaders({ isLoggedOutJourney: 'true' });
    const options = this.httpOptions.set(params as HttpParams, headers);
    const url = `${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL}/registration/customers/` + accountNumber;
    return this.http.post(url, data, options)
      .pipe(map((response: Response) => {
        return response;
      }), catchError((exception: Response | any) => throwError(exception))
        , finalize(() => {
        }));
  }

  public verifyCode(verificationCode) {
    const headers = new HttpHeaders({ isLoggedOutJourney: 'true' });
    return this.http.get(
      `${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL}/verification-code/` + verificationCode + `/registration`,
      { headers }
    ).pipe(map((response: Response) => {
      return response;
    }), catchError((exception: Response | any) => throwError(exception))
      , finalize(() => {
      }));
  }

  public completeRegistration(data) {
    const params = {};
    const headers = new HttpHeaders({ isLoggedOutJourney: 'true' });
    const options = this.httpOptions.set(params as HttpParams, headers);
    const url = `${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL}/registration`;
    return this.http.put(url, data, options)
      .pipe(map((response: Response) => {
        return response;
      }), catchError((exception: Response | any) => throwError(exception))
        , finalize(() => {
        }));
  }

  public verifyPassword(accNumber: string, pwd): Observable<any> {
    const url = `${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL}/${accNumber}/validpassword`;
    return this.http.post(`${url}`, pwd)
      .pipe(map((response: Response) => response
      ), catchError((exception: Response | any) => throwError(exception)));
  }

  public updatePassword(accNumber: string, formData) {
    const url = `${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL}/${accNumber}/password`;
    return this.http.put(url, formData)
      .pipe(map((response) => {
        return response;
      }), catchError((exception: Response | any) => throwError(exception))
        , finalize(() => {
        }));
  }

  public updateUsername(accNumber: string, formData) {
    this.cacheManager.quickRemove('accountDetails' + accNumber);
    const url = `${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL}/${accNumber}/oss-username`;
    return this.http.put(url, formData)
      .pipe(map((response) => {
        return response;
      }), catchError((exception: Response | any) => throwError(exception))
        , finalize(() => {
        }));
  }

  public resetPassword(userData) {
    const headers = new HttpHeaders({ isLoggedOutJourney: 'true' });
    return this.http.post(`${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL}/password`, userData, { headers })
      .pipe(map((response: Response) => response
      ), catchError((exception: Response | any) => throwError(exception))
        , finalize(() => {
        }));
  }

  public changePassword(userData) {
    const headers = new HttpHeaders({ isLoggedOutJourney: 'true' });
    return this.http.put(`${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL}/password`, userData, { headers })
      .pipe(map((response: Response) => response
      ), catchError((exception: Response | any) => throwError(exception))
        , finalize(() => {
        }));
  }

  public verifyCodeforPassword(verificationCode) {
    this.loader.start();
    const headers = new HttpHeaders({ isLoggedOutJourney: 'true' });
    return this.http.get(
      `${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL}/verification-code/` + verificationCode + `/password`,
      { headers }
    ).pipe(map((response: Response) => response
    ), catchError((exception: Response | any) => throwError(exception))
      , finalize(() => {
        this.loader.stop();

      }));
  }

  public resetUsername(userData) {
    const params = {};
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'isLoggedOutJourney': 'true'
      }),
      params: this.httpOptions.setQueryParams(params),
    };
    return this.http.post(`${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL}/username`, userData, httpOptions)
      .pipe(map((response: Response) => response
      ), catchError((exception: Response | any) => throwError(exception))
        , finalize(() => {
        }));
  }

  getOTAC(code): Observable<OtacCodeValidity> {
    const url = `${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL}/verification-code/${code}/otac`;
    return this.http.get<OtacCodeValidity>(url)
      .pipe(map(otacCodeValidity => otacCodeValidity)
        , catchError(exception => throwError(exception))
        , finalize(() => {
        }));
  }
}
