import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map , catchError, finalize } from 'rxjs/operators';

import { HttpOptions } from '../../core/services/http-options.service';
import { OSSAppConfig } from '../../core/config/app-config';
import { Premise } from '../models/premise/premise.model';
import { PremiseDetail } from '../models/premise/premise-detail.model';

@Injectable()
export class PremiseService {

  private apiGroupURL = OSSAppConfig.API_GROUP[OSSAppConfig.API_SRC].premisedetails;
  private apiGroupURL2 = OSSAppConfig.API_GROUP[OSSAppConfig.API_SRC].premisedetails_v2;

  premises: Premise[];
  _selectedPremise: PremiseDetail;
  _allPremises: PremiseDetail[];
  constructor(private http: HttpClient) { }

  public getPremises(debtornum: any): Observable<Premise[]> {
  //  const url = `${OSSAppConfig.jsonBasePath}/mocks/premises.json`;

   const url = `${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL2}/customer-id/${debtornum}/premises-v2`;
   return this.http.get<Premise[]>(url)
      .pipe(map((premises: Premise[]) => {
        return premises;
      }), catchError((exception: Response | any) => throwError(exception))
     , finalize(() => {

      }));
  }

  get selectedPremise(): PremiseDetail {
    return this._selectedPremise;
  }

  set selectedPremise(premiseDetail: PremiseDetail) {
    this._selectedPremise = premiseDetail;
  }

  get allPremise(): PremiseDetail[] {
    return this._allPremises;
  }

  set allPremise(premiseDetails: PremiseDetail[]) {
    this._allPremises = premiseDetails;
  }

}
