import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '../models/account/address.model';

@Pipe({
  name: 'adminSegmentPipe'
})
export class AdminSegmentPipePipe implements PipeTransform {
  ALL = 'All';
  GAS = 'gas';
  ELECTRICITY = 'elec';
  NONINTERVAL = 'non-interval';
  INTERVAL = 'interval';

  transform(items: any[], searchPremiseMeterType: any, searchPremiseFuelType: any, isOuter: boolean): any {
    if (searchPremiseFuelType.type === this.ALL && searchPremiseMeterType.type === this.ALL) {
      return items;
    // tslint:disable-next-line: align
    } if (isOuter) {
      return items.filter((ag) => {
        const agList = ag?.accountGroups?.filter((grp: { premises: any[]; }) => {
          const premises = grp?.premises?.filter((premise: { utilities: any[]; }) => {
            const utils = premise?.utilities?.filter((premiseItem: { type: string; interval: boolean; }) => {
              if (searchPremiseFuelType.type === this.ELECTRICITY && searchPremiseMeterType.type === this.ALL) {
                if (premiseItem.type === 'E') {
                  return premiseItem;
                } else {
                  return false;
                }
              } else if (searchPremiseFuelType.type === this.ELECTRICITY && searchPremiseMeterType.type === this.INTERVAL) {
                if (premiseItem.type === 'E' && premiseItem.interval === true) {
                  return premiseItem;
                } else {
                  return false;
                }
              } else if (searchPremiseFuelType.type === this.ELECTRICITY && searchPremiseMeterType.type === this.NONINTERVAL) {
                if (premiseItem.type === 'E' && premiseItem.interval === false) {
                  return premiseItem;
                } else {
                  return false;
                }
              } else if (searchPremiseFuelType.type === this.GAS && searchPremiseMeterType.type === this.ALL) {
                if (premiseItem.type === 'G') {
                  return premiseItem;
                } else {
                  return false;
                }
              } else if (searchPremiseFuelType.type === this.GAS && searchPremiseMeterType.type === this.INTERVAL) {
                if (premiseItem.type === 'G' && premiseItem.interval === true) {
                  return premiseItem;
                } else {
                  return false;
                }
              }
              else if (searchPremiseFuelType.type === this.GAS && searchPremiseMeterType.type === this.NONINTERVAL) {
                if (premiseItem.type === 'G' && premiseItem.interval === false) {
                  return premiseItem;
                } else {
                  return false;
                }
              }
              else if (searchPremiseMeterType.type === this.INTERVAL && searchPremiseFuelType.type === this.ALL) {
                if (premiseItem.interval === true) {
                  return premiseItem;
                } else {
                  return false;
                }
              }
              else if (searchPremiseMeterType.type === this.NONINTERVAL && searchPremiseFuelType.type === this.ALL) {
                if (premiseItem.interval === false) {
                  return premiseItem;
                }
                else {
                  return false;
                }
              }
            });
            return utils?.length === 0 ? false : true;
          });
          return premises?.length === 0 ? false : true;
        });
        return agList?.length === 0 ? false : true;
      });
    } else {
      return items.filter((grp) => {
        const premises = grp?.premises?.filter((premise: { utilities: any[]; }) => {
          const utils = premise?.utilities?.filter((premiseItem: { type: string; interval: boolean; }) => {

            if (searchPremiseFuelType.type === this.ELECTRICITY && searchPremiseMeterType.type === this.ALL) {
              if (premiseItem.type === 'E') {
                return premiseItem;
              } else {
                return false;
              }
            } else if (searchPremiseFuelType.type === this.ELECTRICITY && searchPremiseMeterType.type === this.INTERVAL) {
              if (premiseItem.type === 'E' && premiseItem.interval === true) {
                return premiseItem;
              } else {
                return false;
              }
            } else if (searchPremiseFuelType.type === this.ELECTRICITY && searchPremiseMeterType.type === this.NONINTERVAL) {
              if (premiseItem.type === 'E' && premiseItem.interval === false) {
                return premiseItem;
              } else {
                return false;
              }
            } else if (searchPremiseFuelType.type === this.GAS && searchPremiseMeterType.type === this.ALL) {
              if (premiseItem.type === 'G') {
                return premiseItem;
              } else {
                return false;
              }
            } else if (searchPremiseFuelType.type === this.GAS && searchPremiseMeterType.type === this.INTERVAL) {
              if (premiseItem.type === 'G' && premiseItem.interval === true) {
                return premiseItem;
              } else {
                return false;
              }
            }
            else if (searchPremiseFuelType.type === this.GAS && searchPremiseMeterType.type === this.NONINTERVAL) {
              if (premiseItem.type === 'G' && premiseItem.interval === false) {
                return premiseItem;
              } else {
                return false;
              }
            }
            else if (searchPremiseMeterType.type === this.INTERVAL && searchPremiseFuelType.type === this.ALL) {
              if (premiseItem.interval === true) {
                return premiseItem;
              } else {
                return false;
              }
            }
            else if (searchPremiseMeterType.type === this.NONINTERVAL && searchPremiseFuelType.type === this.ALL) {
              if (premiseItem.interval === false) {
                return premiseItem;
              }
              else {
                return false;
              }
            }

          });
          return utils?.length === 0 ? false : true;

        });
        return premises?.length === 0 ? false : true;

      });
    }

    
  }

}
