import { Country } from '../models/country.model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countryListFilter'
})
export class CountryListFilterPipe implements PipeTransform {

  transform(countryList: Country[]): Country[] {
    return countryList.filter(country => country.code !== 'ROI' && country.code !== 'N-I');
  }

}
