import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';

export enum DisplayMode {
  Mobile = 768,
  Tablet = 992,
  Desktop = 1200,
  LargeDesktop = 1440
}

@Injectable()
export class ViewportService {

  private _displayMode: BehaviorSubject<DisplayMode>;

  constructor() {
    this._displayMode = new BehaviorSubject<DisplayMode>(DisplayMode.Mobile);
  }

  getDisplayMode$(): Observable<DisplayMode> {
    return this._displayMode.asObservable();
  }

  getDisplayMode(): DisplayMode {
    return this._displayMode.value;
  }

  setDisplayMode(width: number) {
    if (width < DisplayMode.Mobile) {
      if (this._displayMode.value !== DisplayMode.Mobile) {
        this._displayMode.next(DisplayMode.Mobile);
      }
    } else if (width < DisplayMode.Tablet) {
      if (this._displayMode.value !== DisplayMode.Tablet) {
        this._displayMode.next(DisplayMode.Tablet);
      }
    } else if (width < DisplayMode.Desktop) {
      if (this._displayMode.value !== DisplayMode.Desktop) {
        this._displayMode.next(DisplayMode.Desktop);
      }
    } else {
      if (this._displayMode.value !== DisplayMode.LargeDesktop) {
        this._displayMode.next(DisplayMode.LargeDesktop);
      }
    }
  }

}
