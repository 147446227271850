import { RealexOrderDetail3DS2 } from '../../shared/models/payment/realex-order-detail.model';
import { environment } from '../../../environments/environment';
import * as Chart from 'chart.js';

export class OSSAppConfig {

    public static BASE_URL = environment.baseUrl;
    public static API_SRC = 'frontend';
    public static API_GATEWAY_URL: string = environment.APIM_BASE_URL;
    public static ID_SERVICE_URL: string = (OSSAppConfig.API_SRC === 'frontend') ? environment.apim_identity_service
        : environment.identityUrl;
    public static BACKEND_URL: string = environment.backendUrl;
    public static DATA_API_BASE = (OSSAppConfig.API_SRC === 'frontend') ? environment.APIM_BASE_URL
        : `${environment.baseUrl}${environment.backendApiBaseUrl}`;
    public static PAYMENT_API_BASE = (OSSAppConfig.API_SRC === 'frontend') ? environment.APIM_BASE_URL
        : environment.paymentBaseUrl;
    public static API_BASE = environment.baseUrl;
    public static DATA_PORTABILITY_BASE = (OSSAppConfig.API_SRC === 'frontend') ? environment.APIM_BASE_URL
        : `${environment.baseUrl}${environment.dataPortabilityBase}`;
    // TODO: Remove after moving preference center to the common API
    public static CDM_BASE = environment.oracleCdmUrl;
    // TODO: Remove after moving preference center to the common API
    public static CDM_USERNAME = environment.oracleCdmUsername;
    // TODO: Remove after moving preference center to the common API
    public static CDM_PASSWORD = environment.oracleCdmPassword;
    public static APP_TITLE = 'OSS Responsive';
    public static dateFormat = 'mm/dd/yy';
    public static validPatterns = {
        name: /^[a-z\d\-_\s.]+$/i
    };
    public static theme = '';
    public static lang = 'en';
    public static jsonBasePath = 'assets/data';
    public static FAQ_URL = environment.FAQUrl;
    public static VIEW_ALL_FAQ_URL = environment.ViewAllFAQUrl;
    public static VIEW_ALL_METER_READING_FAQ_URL = environment.ViewAllMeterReadingFAQUrl;
    public static VIEW_ALL_ACCOUNT_OVERVIEW_FAQ_URL = environment.ViewAllAccOverviewFAQUrl;
    public static NOTIFICATION_TIMEOUT = 5000;
    public static NPS_POPUP_DELAY = 4000;
    public static scrollbarOptions = { theme: 'minimal-dark', keyboard: { enable: true } };
    public static billHoverTextUrl = environment.billHoverTextUrl;
    public static OSS_COMM_BASE = environment.oss_comm_base;
    public static OSS_HE_BASE = environment.app_base_url_oss;
    public static OSS_COMM_TOKEN_URL = '/token.htm';
    public static OSS_COMM_DASHBOARD = '/main-dashboard.htm';
    public static OSS_COMM_MOBILE = environment.oss_comm_mobile;
    public static OCP_API_SUB_KEY = environment.ocp_apim_sub_key;
    public static chartOptions: Chart.ChartOptions = {
        responsive: true,
        scales: {
            yAxes: [{
                stacked: true,
                gridLines: {
                    lineWidth: 2,
                    zeroLineWidth: 0,
                    color: '#eeeeee',
                    drawBorder: false,
                },
                ticks: {
                    fontColor: '#888888',
                    fontFamily: `'Museo Sans Rounded', Helvetica, Arial, sans-serif`,
                    fontSize: 12,
                    padding: 10,
                }
            }],
            xAxes: [{
                stacked: true,
              //  barThickness: 64,
                gridLines: {
                    display: false,
                    drawBorder: false,
                },
            }],
        },
        elements: {
            line: {
                tension: 0
            }
        },
        spanGaps: false,
        legend: {
            display: false
        },
        plugins: {
            datalabels: {
                display: false
            }
        }
    };
    public static API_GROUP = {
        backend: {
            accountdetails: 'account-details',
            customerdetails: 'customers',
            accountmaintenance: 'account-maintenance',
            billingdetails: 'billing-details',
            meterdetails: 'meter-details',
            premisedetails: 'premises-details',
            changeoftenancy: 'change-tenancy',
            paymentUrl: 'realex-payment',
            extract: 'extract'
        },
        frontend: {
            accountdetails: 'account-details/v1',
            customerdetails: 'customer-details/v1',
            accountmaintenance: 'account-maintenance/v1',
            billingdetails: 'billing-details/v1',
            meterdetails: 'meter-details/v1',
            premisedetails: 'premises-details/v1',
            changeoftenancy: 'change-tenancy/v1',
            paymentUrl: 'realex-payment/v1',
            extract: 'extract/v1',
            commercial: 'v1/commercial/full-commercial-details',
            billingdetails_v2: 'billing-details/v2', // Smart meter change v2
            premisedetails_v2: 'premises-details/v2', // Smart meter change v2
            meterdetails_v2: 'meter-details/v2', // Smart meter change v2
            accountdetails_v2: 'account-details/v2', // Smart meter change v2
        }
    };
    public static nps_url = environment.nps_api_url;
    public static DEBUG_MODE = environment.debugMode;
    public static ENABLE_API_TRACE = environment.enableApiTrace;
    public static SUBMIT_READING_NOTIFICATION_DAYS = 15;
    public static loggedOutJourneys: string[] = [
        '/my-account/registration',
        '/my-account/forgot-password',
        '/my-account/forgot-username',
        '/my-account/update-password',
        '/set-password/create-password',
        '/payment',
    ];
    // OIDC CONFIG
    public static APP_BASE_URL = environment.app_base_url;
    public static IDSVR_BASE_URL = environment.idsvr_base_url;
    public static IDSVR_CLIENT_ID = environment.idsClientId;
    // 3DS Config
    public static useDummyValuesFor3DS2 = true;
    public static dummyValuesFor3DS2: Partial<RealexOrderDetail3DS2> = {
        HPP_CUSTOMER_EMAIL: 'dummy@sse.com',
        HPP_CUSTOMER_PHONENUMBER_MOBILE: '1|123456789',
        HPP_BILLING_STREET1: '00000',
        HPP_BILLING_STREET2: '00000',
        HPP_BILLING_STREET3: '00000',
        HPP_BILLING_CITY: '00000',
        HPP_BILLING_POSTALCODE: '00000',
        HPP_BILLING_COUNTRY: '372'
    };
    public static hits_tips_api_url = environment.hits_tips_api_url;
    constructor() {
    }

}
