import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'oss-inline-loader',
  templateUrl: './inline-loader.component.html',
  styleUrls: ['./inline-loader.component.scss']
})
export class InlineLoaderComponent implements OnInit {

  @Input()
  theme: 'light' | 'dark' = 'dark';

  @Input()
  nobackground = false;

  constructor() { }

  ngOnInit() {
  }

}
