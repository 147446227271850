import { OSSAppConfig } from './app-config';
import { isDevMode } from '@angular/core';
import { environment } from '../../../environments/environment';

import {
  OpenIdConfiguration,
  AuthWellKnownEndpoints
} from 'angular-auth-oidc-client';
export class OSSOpenIDConfiguration implements OpenIdConfiguration {
  stsServer = OSSAppConfig.IDSVR_BASE_URL;
  redirect_url = `${OSSAppConfig.APP_BASE_URL}/signin-oidc`;
  client_id = OSSAppConfig.IDSVR_CLIENT_ID;
  response_type = 'id_token token';
  scope = 'openid profile paymentServiceAPI customerExtractAPI commAdminAPI commReportAPI oss ossAPI';
  post_logout_redirect_uri = `${OSSAppConfig.APP_BASE_URL}/signout-callback-oidc`;
  start_checksession = false;
  silent_renew = true;
  silent_renew_url = `${OSSAppConfig.APP_BASE_URL}/silent-renew.html`;
  silent_redirect_url = `${OSSAppConfig.APP_BASE_URL}/silent-renew.html`;
  silent_renew_offset_in_seconds = 0;
  post_login_route = '/my-account';
  // HTTP 403
  forbidden_route = '/forbidden';
  // HTTP 401
  unauthorized_route = '/unauthorized';
  log_console_warning_active = !environment.production ? true : false;
  log_console_debug_active =  !environment.production ? true : false;
  max_id_token_iat_offset_allowed_in_seconds = 1800;

  authorization_endpoint = `${OSSAppConfig.IDSVR_BASE_URL}/connect/authorize`;
  token_endpoint = `${OSSAppConfig.IDSVR_BASE_URL}/connect/token`;
  end_session_endpoint = `${OSSAppConfig.IDSVR_BASE_URL}/connect/endsession`;
  revocation_session_endpoint = `${OSSAppConfig.IDSVR_BASE_URL}/connect/revocation`;
  auto_user_info = true;
  storage = typeof Storage !== 'undefined' ? localStorage : null;
  post_logout_uris = {
    ROI: {
      DMT: 'https://www.sseairtricity.com/ie/home',
      COM: 'https://www.sseairtricity.com/ie/business',
      CSR: 'https://www.sseairtricity.com/ie/home',
    },
    NI: {
      DMT: 'https://www.sseairtricity.com/uk/home',
      COM: 'https://www.sseairtricity.com/uk/business',
      CSR: 'https://www.sseairtricity.com/uk/home',
    }
  };
}

export class OSSAuthWellKnownEndpoints implements AuthWellKnownEndpoints {
  issuer = OSSAppConfig.IDSVR_BASE_URL;
  jwks_uri = `${OSSAppConfig.IDSVR_BASE_URL}/.well-known/openid-configuration/jwks`;
  authorization_endpoint = `${OSSAppConfig.IDSVR_BASE_URL}/connect/authorize`;
  token_endpoint = `${OSSAppConfig.IDSVR_BASE_URL}/connect/token`;
  userinfo_endpoint = `${OSSAppConfig.IDSVR_BASE_URL}/connect/userinfo`;
  end_session_endpoint = `${OSSAppConfig.IDSVR_BASE_URL}/connect/endsession`;
  check_session_iframe = `${OSSAppConfig.IDSVR_BASE_URL}/connect/checksession`;
  revocation_endpoint = `${OSSAppConfig.IDSVR_BASE_URL}/connect/revocation`;
  introspection_endpoint = `${OSSAppConfig.IDSVR_BASE_URL}/connect/introspect`;
}


export const DEFAULT_CONFIG = {
  stsServer : OSSAppConfig.IDSVR_BASE_URL,
  redirect_url : `${OSSAppConfig.APP_BASE_URL}/signin-oidc`,
  client_id : OSSAppConfig.IDSVR_CLIENT_ID,
  response_type : 'id_token token',
  scope : 'openid profile paymentServiceAPI customerExtractAPI commAdminAPI commReportAPI oss ossAPI',
  post_logout_redirect_uri : `${OSSAppConfig.APP_BASE_URL}/signout-callback-oidc`,
  start_checksession : false,
  silent_renew : true,
  silent_renew_url : `${OSSAppConfig.APP_BASE_URL}/silent-renew.html`,
  silent_redirect_url : `${OSSAppConfig.APP_BASE_URL}/silent-renew.html`,
  silent_renew_offset_in_seconds : 0,
  post_login_route : '/my-account',
  // HTTP 403
  forbidden_route : '/forbidden',
  // HTTP 401
  unauthorized_route : '/unauthorized',
  log_console_warning_active :  !environment.production ? true : false,
  log_console_debug_active : !environment.production ? true : false,
  max_id_token_iat_offset_allowed_in_seconds : 1800,

  authorization_endpoint : `${OSSAppConfig.IDSVR_BASE_URL}/connect/authorize`,
  token_endpoint : `${OSSAppConfig.IDSVR_BASE_URL}/connect/token`,
  end_session_endpoint : `${OSSAppConfig.IDSVR_BASE_URL}/connect/endsession`,
  revocation_session_endpoint : `${OSSAppConfig.IDSVR_BASE_URL}/connect/revocation`,
  auto_user_info : true,
  storage : typeof Storage !== 'undefined' ? localStorage : null,
  post_logout_uris : {
    ROI: {
      DMT: 'https://www.sseairtricity.com/ie/home',
      COM: 'https://www.sseairtricity.com/ie/business',
      CSR: 'https://www.sseairtricity.com/ie/home',
    },
    NI: {
      DMT: 'https://www.sseairtricity.com/uk/home',
      COM: 'https://www.sseairtricity.com/uk/business',
      CSR: 'https://www.sseairtricity.com/uk/home',
    }
  },
};

export const DefaultAuthWellKnownEndpoints = {
  issuer : OSSAppConfig.IDSVR_BASE_URL,
  jwks_uri : `${OSSAppConfig.IDSVR_BASE_URL}/.well-known/openid-configuration/jwks`,
  authorization_endpoint : `${OSSAppConfig.IDSVR_BASE_URL}/connect/authorize`,
  token_endpoint : `${OSSAppConfig.IDSVR_BASE_URL}/connect/token`,
  userinfo_endpoint : `${OSSAppConfig.IDSVR_BASE_URL}/connect/userinfo`,
  end_session_endpoint : `${OSSAppConfig.IDSVR_BASE_URL}/connect/endsession`,
  check_session_iframe : `${OSSAppConfig.IDSVR_BASE_URL}/connect/checksession`,
  revocation_endpoint : `${OSSAppConfig.IDSVR_BASE_URL}/connect/revocation`,
  introspection_endpoint : `${OSSAppConfig.IDSVR_BASE_URL}/connect/introspect`,
};
