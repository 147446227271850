<div class="container error-container">  
  <div class="row">
    <div class="oss-panel">
      <h1 class="headline">
        {{'We\'re sorry, but something\'s happened...'|ossContent: 'shared': 'server-error-headline'}}
      </h1>
      <div class="goback">
        <a [routerLink]="goBackLink" href="javacript:;">{{'Back to main dashboard'|ossContent: 'shared': 'server-error-go-back-to-dashboard-link'}}</a>
      </div>
      <div class="notification information">{{'We encountered an unexpected error at this time. We can\'t carry out your request right now.'|ossContent: 'shared': 'server-error-notification'}}</div>
      <!--<div class="notification warning" *ngIf="error_response.title && debug_mode">{{error_response.title}}</div>-->
    <div *ngIf="error_response && debug_mode" class="error-debug">
      <h3>Error Debug Info</h3>
      <pre>{{error_response | json}}</pre>
    </div>
    </div>
  </div>
</div>