import { Component, OnInit , OnDestroy} from '@angular/core';
import { OidcSecurityService, AuthorizationResult, OpenIdConfiguration, AuthWellKnownEndpoints } from 'angular-auth-oidc-client';
import { AuthService } from '../../../core/auth/auth.service';
import {
  Route,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
@Component({
  selector: 'oss-oidc-callback',
  templateUrl: './oidc-callback.component.html',
  styleUrls: ['./oidc-callback.component.scss']
})
export class OidcCallbackComponent implements OnInit, OnDestroy {
  userData;
  constructor(private auth: AuthService, private router: Router,
              private userIdle: UserIdleService,
              private _securityService: OidcSecurityService
      ) {
    if (this.auth.isAuthorized()) {
      if (this.auth.getRoleCode() === 'DMT' || this.auth.getRoleCode() === 'CSR'  || this.auth.getRoleCode() === 'COM') {
        const deepLink: any = localStorage.getItem('post_login_uri') ? localStorage.getItem('post_login_uri') : '/my-account/acc-overview';
        this.router.navigateByUrl(deepLink);
      }
    else if(this.auth.getRoleCode() === 'AIR'){
      this.router.navigateByUrl('/my-account/superuser');
    }
    } else {
        this.userIdle.startWatching();
        console.log('Idle Timer Started');
    }
    // this.callAuth();
  }

  ngOnInit() {
  }
  callAuth(){
    this._securityService.isAuthenticated$.subscribe(
      (isAuthorized: boolean) => {
      //  return isAuthorized;
      if(isAuthorized){
        if (this.auth.getRoleCode() === 'DMT' || this.auth.getRoleCode() === 'CSR'  || this.auth.getRoleCode() === 'COM') {
          const deepLink: any = localStorage.getItem('post_login_uri') ? localStorage.getItem('post_login_uri') : '/my-account/acc-overview';
          this.router.navigateByUrl(deepLink);
        }else if(this.auth.getRoleCode() === 'AIR'){
          this.router.navigateByUrl('/my-account/superuser');
        }
      }else{
        this.userIdle.startWatching();
        console.log('Idle Timer Started');
      }
      });
  }
  ngOnDestroy() {
  }
}
