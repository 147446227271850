import { Injectable } from '@angular/core';
import { ProxyLoginService } from './../services/proxy-login.service';
import { AuthService } from './../auth/auth.service';
import { Resolve, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';

@Injectable()
export class RouteResolver implements Resolve<any> {
    constructor(public proxyLoginService: ProxyLoginService, private activeRoute: ActivatedRoute,
        private auth: AuthService) { }
    resolve(route: ActivatedRouteSnapshot) { 
        // const superuserName = this.activeRoute.snapshot.queryParams.uname;
        const superuserName = localStorage.getItem('superuserName');
        const proxyLoginCMCDebtor = this.activeRoute.snapshot.queryParams.debtorNumber;
        if(proxyLoginCMCDebtor!== undefined && proxyLoginCMCDebtor !== 'null' && proxyLoginCMCDebtor !== null ) {
            localStorage.setItem('proxyCMCDebtor', proxyLoginCMCDebtor);
            localStorage.setItem('loggedinAsCSRUser',"true");
            
            // console.log('proxyCMCDebtor',localStorage.getItem('proxyCMCDebtor'));
            localStorage.removeItem('superuserName');

        }
        if(superuserName!== undefined && superuserName !== 'null' && superuserName !== null) {
        const CSRUser = this.auth.getUserData().sub;
        localStorage.setItem('superuserName', superuserName);
        localStorage.removeItem('proxyCMCDebtor');
        return this.proxyLoginService.retrieveProxyToken(superuserName.toLowerCase(), CSRUser);
        }
    }
}

