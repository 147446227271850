import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { OSSAppConfig } from '../config/app-config';

@Injectable()
class CacheManagerService {

  private cacheStore = {};
  private cacheKey: string;
  private quickCacheStore = {};

  constructor() {
    this.cacheKey = OSSAppConfig.OCP_API_SUB_KEY;
  }

  /**
   * Function to save data in to cache
   * @param key reference name of data to be saved
   * @param data actual data to be stored
   */
  public save(key: string, data: any) {
    const _key = 'OSSR-' + key;
    this.cacheStore[_key] = this.base64Encode(data);
  }


  /**
   * Function to retrive data from cache
   * @param key  reference name of data to be retrieved
   */
  public retrieve(key: string): any {

    if (this.cacheStore && this.cacheStore['OSSR-' + key]) {
      return this.base64Decode(this.cacheStore['OSSR-' + key]);
    } else {
      return false;
    }
  }

 /**
  * Function to save data in to short cache which will not have life if  browser reloads
  * @param key reference name of data to be saved
  * @param data actual data to be stored
  */
  public quickSave(key: string, data: any) {
    const _key = 'OSSR-' + key;
    this.quickCacheStore[_key] = this.base64Encode(data);
  }

 /**
  * Function to save data in to short cache
  * @param key reference name of data to be saved
  * @param data actual data to be stored
  */
  public quickRetrieve(key: string): any {
    if (this.quickCacheStore && this.quickCacheStore['OSSR-' + key]) {
      return this.base64Decode(this.quickCacheStore['OSSR-' + key]);
    } else {
      return false;
    }
  }

   /**
    * Function to remove a particular key object from cache
    * @param key  reference name of data to be retrieved
    */
  public remove(key: string): any {
    if (this.cacheStore && this.cacheStore['OSSR-' + key]) {
      delete this.cacheStore['OSSR-' + key];
    }
  }

   /**
    * Function to remove a particular key object from short cache
    * @param key  reference name of data to be removed
    */
  public quickRemove(key: string): any {
    if (this.quickCacheStore && this.quickCacheStore['OSSR-' + key]) {
      delete this.quickCacheStore['OSSR-' + key];
    }
  }

  /**
   * @memberof CacheManager
   * Function to check  cache empty status
   */
  public isCacheEmpty(): boolean {
    if (Object.keys(this.cacheStore).length < 1) {
      return true;
    }
    return false;
  }

  /**
   * Base64 Encode given data
   * @memberof CacheManager
   * @param data
   * @returns {String} parsedStr Base64 Encoded String
   */
  public base64Encode(data: any) {
    const rawStr: string = JSON.stringify(data);
    const wordArray = CryptoJS.enc.Utf8.parse(rawStr);
    const base64 = CryptoJS.enc.Base64.stringify(wordArray);
    return base64;
  }

  /**
   * Base64 Decode given data
   * @memberof CacheManager
   * @param   {any} data Data
   * @returns {String} parsedStr Base64 Decoded String
   */
  public base64Decode(data: any) {
    const parsedWordArray = CryptoJS.enc.Base64.parse(data);
    const parsedStr = parsedWordArray.toString(CryptoJS.enc.Utf8);
    return JSON.parse(parsedStr);
  }

  /**
   * @memberof CacheManager
   * @param data
   * Function to AES encrypt the given data object
   */
  public aesEncrypt(data: any): string {
    // Encrypt
    const encryptedData: string = CryptoJS.AES.encrypt(JSON.stringify(data), this.cacheKey);
    return encryptedData;
  }

  /**
   * @memberof CacheManager
   * @param data
   * Function to decrypt the given data object
   */
  public aesDecrypt(data: any) {
    // Decrypt
    const bytes = CryptoJS.AES.decrypt(data.toString(), this.cacheKey);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  }


  /**
   * Function to backup quick cache to cache
   *
   * @memberof CacheManagerService
   */
  public backupQuickCache() {
    this.save('quickCacheBackup', this.quickCacheStore);
  }

  /**
   * Function to restore quick cache from cache
   *
   * @memberof CacheManagerService
   */
  public restoreQuickCache() {
    const quickCache = this.retrieve('quickCacheBackup');
    if (quickCache) {
      this.quickCacheStore = quickCache;
      this.remove('quickCacheBackup');
    }
  }


  /**
   * @memberof CacheManager
   * Function to save the current cache data to localstorage
   */
  public saveSession() {
    if (this.cacheStore && !this.isCacheEmpty()) {
      localStorage.setItem('OSSR', this.aesEncrypt(this.cacheStore));
    } else {
    }
  }

  /**
   * @memberof CacheManager
   * Function to Restore the current cache data from localstorage to Cache Manager
   */
  public restoreSession() {
    const value = localStorage.getItem('OSSR');
    if (value && value !== null && value !== '') {
      this.cacheStore = this.aesDecrypt(value);
      this.removeSession();
      this.restoreQuickCache();
    } else {
    }
  }

  /**
   * @memberof CacheManager
   * Function to destroy the save cache detail from localstorage
   */
  public removeSession() {
    localStorage.removeItem('OSSR');
  }


  /**
   * @memberof CacheManager
   * Function to clear data from Cache Manager
   */
  public clear() {
    this.cacheStore = {};
    this.quickCacheStore = {};
  }

  public clearQuickCache() {
    this.quickCacheStore = {};
  }

}


export { CacheManagerService as CacheManager };
