import { Router, NavigationStart } from '@angular/router';
import { Component, ElementRef, OnDestroy, OnInit, AfterViewInit, Input } from '@angular/core';

import { LocalizationService } from '../../../core/services/localization/localization.service';
import { OssContentService } from '../../../core/services/oss-content/oss-content.service';
import { NavMenuItem } from '../../../shared/models/nav-menu-item.model';
import { Region } from '../../../shared/models/region.model';
import { CustomerService } from '../../services/customer.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UserDataCommon } from '../../class/user-data-common';
import { ProxyLoginService } from 'src/app/core/services/proxy-login.service';
import { BillingService } from '../../services/billing.service';
import { Subscription } from 'rxjs';
import { UrlEncryptorService } from '../../services/url-encryptor.service';

@Component({
  selector: 'oss-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() role: any;
  isSearchModalShown = false;
  searchQuery = '';

  public roi_parent_site_url = 'https://www.sseairtricity.com/ie/business';
  public ni_parent_site_url = 'https://www.sseairtricity.com/uk/business';

  currentRegion: Region;
  regionList: Region[];
  navMenuItems_RoI: NavMenuItem[];
  navMenuItems_OSA: NavMenuItem[];
  navMenuItems_CAG: NavMenuItem[];
  navMenuItems_CMC: NavMenuItem[];
  navMenuItems_COM: NavMenuItem[];
  navMenuItems_NIE: NavMenuItem[];
  sectorsROI: NavMenuItem[];

  sectorsNI: NavMenuItem[];

  userRegions: Region[] = [
    new Region('Republic of Ireland', 'ROI', 'ROI'),
    new Region('Northern Ireland Electricity', 'NIE', 'NI'),
    new Region('Northern Ireland Gas', 'NIG', 'NI')
  ];

  submenuProductsServicesROICom: NavMenuItem[];
  submenuAboutROICom: NavMenuItem[];
  mobileNavMenuItemsROICom: NavMenuItem[];
  submenuProductsServicesNICom: NavMenuItem[];
  submenuAboutNICom: NavMenuItem[];
  mobileNavMenuItemsNICom: NavMenuItem[];

  submenuAboutNIDom: NavMenuItem[];
  mobileNavMenuItemsNIDom: NavMenuItem[];

  submenuPlansROIDom: NavMenuItem[];
  submenuAboutROIDom: NavMenuItem[];
  mobileNavMenuItemsROIDom: NavMenuItem[];
  public navToggled = false;
  public toggleSub = false;
  public openedSubMenu = '';
  public regionPopupShow = false;
  // hamburger menu definitions
  public togglePlan = false;
  public toggleAbout = false;
  public isDomMenu = false;
  debtornum;
  currentUrl;

  notifierSubscription: Subscription;
  contactUsUrl: any;
  helpUrl: any;
  greenUrl: any;
  rewardUrl: any;
  homeUrl: any;
  searchUrl: any;
  businessUrl: any;
  isFinalizedAccount = false;
  cmcDebtorNum: number;
  constructor(
    private elementRef: ElementRef,
    private localizationService: LocalizationService,
    private billingService: BillingService,
    private contentService: OssContentService,
    private customerService: CustomerService,
    private auth: AuthService,
    private router: Router,
    private proxyLoginService: ProxyLoginService,
    private urlEncryptor: UrlEncryptorService
  ) {

    this.localizationService.region$.subscribe(region => {
      this.currentRegion = region;
      this.updateRegionList();
    });
    this.router.events
      .subscribe(
        (event) => {
          if (event instanceof NavigationStart) {
            // console.log(event.url);
            this.currentUrl = event.url;
            if (this.currentUrl.includes('my-account/acc-overview?debtorNumber=') && this.proxyLoginService.isCustomerAgent) {
              this.debtornum = (this.currentUrl.split('?')[1]).split('=')[1];
            }
            // console.log(this.currentUrl, 'current url');
          }
        });
  }
  ngOnInit() {
    if (localStorage.getItem('userData') !== null) {
      // this.setHeaderMenu();
      setTimeout(() => {
        this.setHeaderMenu();
      }, 1000);
    }
    this.auth.loggedInRole.subscribe((data) => {
      if (data === 'loggedIn') {
        setTimeout(() => {
          this.setHeaderMenu();
        }, 5000);
      }
    });
    this.notifierSubscription = this.proxyLoginService.subjectNotifier?.subscribe(notified => {
     //  if (notified) {
        this.setHeaderMenu();
     // }
    });



  }
  ngAfterViewInit() {

  }
  setHeaderMenu() {
    let encDebtNum;
    sessionStorage.setItem('from-acc-make-payment', 'false');
    const userDetails = JSON.parse(localStorage.getItem('userData')) as any;
    const userRole = userDetails && userDetails.role;
    if (userRole === 'CSR') {
      this.debtornum = this.customerService.getAccountNo();
      encDebtNum = this.urlEncryptor.aesEncrypt(this.debtornum);
      if (localStorage.getItem('superuserName') !== null && localStorage.getItem('superuserName') !== undefined &&
        localStorage.getItem('superuserName') !== '') {
        if (localStorage.getItem('csr-role') === 'OSA') {
          this.navMenuItems_COM = [
            new NavMenuItem('Account overview', '/my-account/acc-overview'),
            new NavMenuItem('Billing overview', '/my-account/bills-overview'),
            new NavMenuItem('Meter readings', '/my-account/meter-reading'),
            new NavMenuItem('Usage & reports', '/my-account/usage-reports'),
            new NavMenuItem('Admin', '/my-account/adminuser')
          ];
        } else if (localStorage.getItem('csr-role') === 'CAG') {
          this.navMenuItems_COM = [
            new NavMenuItem('Account overview', '/my-account/acc-overview'),
            new NavMenuItem('Billing overview', '/my-account/bills-overview'),
            new NavMenuItem('Meter readings', '/my-account/meter-reading'),
            new NavMenuItem('Usage & reports', '/my-account/usage-reports'),
            new NavMenuItem('Account details', '/my-account/details'),
          ];
        }

      } else {
        this.navMenuItems_COM = [
          new NavMenuItem('Account overview', '/my-account/acc-overview'),
          new NavMenuItem('Bills & payments', `/my-account/bills-overview/bills-payments/${encDebtNum}`),
          new NavMenuItem('Meter readings', '/my-account/meter-reading'),
          new NavMenuItem('Usage & reports', '/my-account/usage-reports'),
          new NavMenuItem('Account details', '/my-account/details')];
      }
    }
    else if (userRole === 'CMC' ) { // && UserDataCommon.passType() !== 'old'
    this.debtornum = parseInt(JSON.parse(localStorage.getItem('userData')).debtor);
    encDebtNum = this.urlEncryptor.aesEncrypt(this.debtornum);
    this.isFinalizedAccount = JSON.parse(localStorage.getItem('isFinalizedAccount'));

    if (this.isFinalizedAccount) {
      if (!this.currentUrl?.includes('update-password')) {
        this.navMenuItems_COM = [
          new NavMenuItem('Account overview', '/my-account/acc-overview'),
          new NavMenuItem('Bills & payments', `/my-account/bills-overview/bills-payments/${encDebtNum}`),
          new NavMenuItem('Usage & reports', '/my-account/usage-reports'),
          new NavMenuItem('Account details', '/my-account/details'),
        ];
      }
    }
    else {
      if (!this.currentUrl?.includes('update-password')) {
        this.navMenuItems_COM = [
          new NavMenuItem('Account overview', '/my-account/acc-overview'),
          new NavMenuItem('Bills & payments', `/my-account/bills-overview/bills-payments/${encDebtNum}`),
          new NavMenuItem('Meter readings', '/my-account/meter-reading'),
          new NavMenuItem('Usage & reports', '/my-account/usage-reports'),
          new NavMenuItem('Account details', '/my-account/details'),
        ];
      }
    }

    } else if (userRole === 'CAG') {
      this.navMenuItems_COM = [
        new NavMenuItem('Account overview', '/my-account/acc-overview'),
        new NavMenuItem('Billing overview', '/my-account/bills-overview'),
        new NavMenuItem('Meter readings', '/my-account/meter-reading'),
        new NavMenuItem('Usage & reports', '/my-account/usage-reports'),
        new NavMenuItem('Account details', '/my-account/details'),
      ];
    } else if (userRole === 'OSA') {
      this.navMenuItems_COM = [
        new NavMenuItem('Account overview', '/my-account/acc-overview'),
        new NavMenuItem('Billing overview', '/my-account/bills-overview'),
        new NavMenuItem('Meter readings', '/my-account/meter-reading'),
        new NavMenuItem('Usage & reports', '/my-account/usage-reports'),
        new NavMenuItem('Admin', '/my-account/adminuser'),
      ];

    } else if (userRole === 'AIR') {
      this.navMenuItems_COM = [
      ];
    }




    this.sectorsROI = [
      new NavMenuItem('At home', this.contentService.getRedirectUrl('headerROIHome'), '', []),
      new NavMenuItem('For business', this.contentService.getRedirectUrl('headerROIBusiness'), '', []),
      new NavMenuItem('SSE Reward', this.contentService.getRedirectUrl('headerROIReward'), '', []),
      new NavMenuItem('Generation Green', this.contentService.getRedirectUrl('headerROIGreen'), '', [])
    ];
    this.sectorsNI = [
      new NavMenuItem('At home', this.contentService.getRedirectUrl('headerNIEHome'), '', []),
      new NavMenuItem('For business', this.contentService.getRedirectUrl('headerNIBusiness'), '', []),
      new NavMenuItem('SSE Reward', this.contentService.getRedirectUrl('headerNIReward'), '', []),
      new NavMenuItem('Generation Green', this.contentService.getRedirectUrl('headerNIGreen'), '', [])
    ];


    this.mobileNavMenuItemsROICom = [
      new NavMenuItem('Contact us', this.contentService.getRedirectUrl('headerCommercialROIContactUs'), 'contactUsROICom'),
      new NavMenuItem('Product and services', '#', 'pdtServicesROICom', ['sub-menu-trigger'], this.submenuProductsServicesROICom),
      new NavMenuItem('Insights', this.contentService.getRedirectUrl('headerCommercialROIInsights'), 'insightsROICom'),
      new NavMenuItem('Help', this.contentService.getRedirectUrl('headerCommercialROIHelp'), 'helpROICom'),
      new NavMenuItem('About us', '#', 'aboutROICom', ['sub-menu-trigger'], this.submenuAboutROICom),
      new NavMenuItem('Login', this.contentService.getRedirectUrl('headerCommercialROILogin'), 'loginROICom'),
    ];
    this.contactUsUrl = this.getContactUsUrl();
    this.helpUrl = this.getHelpUrl();
    this.searchUrl = this.getSearchUrl();
    this.greenUrl = this.getGreenUrl();
    this.rewardUrl = this.getRewardUrl();
    this.homeUrl = this.getHomeUrl();
    this.businessUrl = this.getBusinessUrl();
    this.setActiveTab();

  }
  setActiveTab() {
    // User details local storage
    const userDetails = JSON.parse(localStorage.getItem('userData')) as any;
    const userRole = userDetails && userDetails.role;
    this.currentUrl = this.currentUrl ? this.currentUrl : '';

    if (userRole === 'AIR') { }
    if (userRole === 'CMC' && UserDataCommon.passType() !== 'old') {
      if (this.currentUrl === '/my-account/acc-overview') {
        this.navMenuItems_COM[0].customClasses.push('activeTab');
      } else if (this.currentUrl.indexOf('/my-account/bills-overview/bills-payments') > -1) {
        this.navMenuItems_COM[1].customClasses.push('activeTab');
      } else if (this.currentUrl === '/my-account/meter-reading') {
        this.navMenuItems_COM[2].customClasses.push('activeTab');
      } else if (this.currentUrl === '/my-account/details') {
        this.navMenuItems_COM[3].customClasses.push('activeTab');
      }
    }
    if (userRole === 'CAG') {
      if (this.currentUrl === '/my-account/acc-overview') {
        this.navMenuItems_COM[0].customClasses.push('activeTab');
      } else if (this.currentUrl === '/my-account/bills-overview') {
        this.navMenuItems_COM[1].customClasses.push('activeTab');
      } else if (this.currentUrl === '/my-account/meter-reading') {
        this.navMenuItems_COM[2].customClasses.push('activeTab');
      } else if (this.currentUrl === '/my-account/details') {
        this.navMenuItems_COM[3].customClasses.push('activeTab');
      }
    }
    if (userRole === 'OSA') {
      if (this.currentUrl === '/my-account/acc-overview') {
        this.navMenuItems_COM[0].customClasses.push('activeTab');
      } else if (this.currentUrl === '/my-account/bills-overview') {
        this.navMenuItems_COM[1].customClasses.push('activeTab');
      } else if (this.currentUrl === '/my-account/meter-reading') {
        this.navMenuItems_COM[2].customClasses.push('activeTab');
      } else if (this.currentUrl === '/my-account/adminuser') {
        this.navMenuItems_COM[3].customClasses.push('activeTab');
      }
    }
    // console.log('123456' + JSON.stringify(this.navMenuItems_COM));
  }

  get sectors() {
    switch (this.currentRegion.jurisdiction) {
      case 'ROI': {
        return this.sectorsROI;
      }
      case 'NI': {
        return this.sectorsNI;
      }
      default: {
        return this.sectorsROI;
      }
    }
  }

  getContactUsUrl() {
    switch (this.currentRegion.jurisdiction) {
      case 'ROI': {
        return this.contentService.getRedirectUrl('headerROIContactUs');
      }
      case 'NI': {
        return this.contentService.getRedirectUrl('headerNIContactUs');
      }
      default: {
        return this.contentService.getRedirectUrl('headerROIContactUs');
      }
    }
  }
  getHelpUrl() {
    switch (this.currentRegion.jurisdiction) {
      case 'ROI': {
        return this.contentService.getRedirectUrl('headerCommercialROIHelp');
      }
      case 'NI': {
        return this.contentService.getRedirectUrl('headerCommercialNIHelp');
      }
      default: {
        return this.contentService.getRedirectUrl('headerCommercialROIHelp');
      }
    }
  }
  getRewardUrl() {
    switch (this.currentRegion.jurisdiction) {
      case 'ROI': {
        return this.contentService.getRedirectUrl('headerROIReward');
      }
      case 'NI': {
        return this.contentService.getRedirectUrl('headerNIReward');
      }
      default: {
        return this.contentService.getRedirectUrl('headerROIReward');
      }
    }
  }
  getGreenUrl() {
    switch (this.currentRegion.jurisdiction) {
      case 'ROI': {
        return this.contentService.getRedirectUrl('headerROIGreen');
      }
      case 'NI': {
        return this.contentService.getRedirectUrl('headerNIGreen');
      }
      default: {
        return this.contentService.getRedirectUrl('headerROIGreen');
      }
    }
  }
  getHomeUrl() {
    switch (this.currentRegion.jurisdiction) {
      case 'ROI': {
        return this.contentService.getRedirectUrl('headerROIHome');
      }
      case 'NI': {
        return this.contentService.getRedirectUrl('headerNIEHome');
      }
      default: {
        return this.contentService.getRedirectUrl('headerROIHome');
      }
    }
  }
  getBusinessUrl() {
    switch (this.currentRegion.jurisdiction) {
      case 'ROI': {
        return this.contentService.getRedirectUrl('headerROIBusiness');
      }
      case 'NI': {
        return this.contentService.getRedirectUrl('headerNIBusiness');
      }
      default: {
        return this.contentService.getRedirectUrl('headerROIBusiness');
      }
    }
  }

  get parentSiteUrl() {
    switch (this.currentRegion.jurisdiction) {
      case 'ROI': {
        return this.roi_parent_site_url;
      }
      case 'NI': {
        return this.ni_parent_site_url;
      }
      default: {
        return this.roi_parent_site_url;
      }
    }
  }

  getSearchUrl() {
    switch (this.currentRegion.jurisdiction) {
      case 'ROI': {
        return this.contentService.getRedirectUrl('headerROISearchBusiness');
      }
      case 'NI': {
        return this.contentService.getRedirectUrl('headerNISearchBusiness');
      }
      default: {
        return this.contentService.getRedirectUrl('headerROISearchBusiness');
      }
    }
  }

  get mobileNavMenuItemsCom(): NavMenuItem[] {
    switch (this.currentRegion.jurisdiction) {
      case 'ROI': {
        return this.mobileNavMenuItemsROICom;
      }
      case 'NI': {
        return this.mobileNavMenuItemsNICom;
      }
      default: {
        return this.mobileNavMenuItemsROICom;
      }
    }
  }

  get mobileNavMenuItemsDom(): NavMenuItem[] {
    switch (this.currentRegion.jurisdiction) {
      case 'ROI': {
        return this.mobileNavMenuItemsROIDom;
      }
      case 'NI': {
        return this.mobileNavMenuItemsNIDom;
      }
      default: {
        return this.mobileNavMenuItemsROIDom;
      }
    }
  }

  search() {
    const url = `${this.searchUrl}?q=${this.searchQuery}`;
    window.open(url, '_self');
  }

  /**
   * sets user region in navbar dropdown
   *
   * @param {Region} selectedRegion
   *
   * @memberOf HeaderComponent
   */
  selectUserRegion(selectedRegion: Region) {
    this.currentRegion = selectedRegion;
    // Uncomment to change region and currency according to selected region
    /*
      this.localizationService.setRegion(selectedRegion.code);
    */
    this.updateRegionList();

    // Comment to prevent redirect
    switch (selectedRegion.code) {
      case 'ROI': {
        window.open(this.contentService.getRedirectUrl('headerROIHome'), '_self');
        break;
      }
      case 'NIE': {
        window.open(this.contentService.getRedirectUrl('headerNIEHome'), '_self');
        break;
      }
      case 'NIG': {
        window.open(this.contentService.getRedirectUrl('headerNIGHome'), '_self');
        break;
      }
      default: {
        break;
      }
    }
  }

  selectCommercialUserRegion(selectedRegion: Region) {
    this.currentRegion = selectedRegion;
    // Uncomment to change region and currency according to selected region
    /*
      this.localizationService.setRegion(selectedRegion.code);
    */
    this.updateRegionList();

    // Comment to prevent redirect
    switch (selectedRegion.code) {
      case 'ROI': {
        window.open(this.contentService.getRedirectUrl('headerROIBusiness'), '_self');
        break;
      }
      case 'NIE': {
        window.open(this.contentService.getRedirectUrl('headerNIBusiness'), '_self');
        break;
      }
      case 'NIG': {
        window.open(this.contentService.getRedirectUrl('headerNIGBusiness'), '_self');
        break;
      }
      default: {
        break;
      }
    }
  }

  /**
   * updates user region in navbar dropdown
   *
   * @memberOf HeaderComponent
   */
  updateRegionList() {
    this.regionList = Object.assign([], this.userRegions);
    this.regionList.splice(this.regionList.findIndex((region) => region.name === this.currentRegion.name), 1);
  }

  /**
   * sets the visibility of hamburger menu
   *
   * @memberOf HeaderComponent
   */
  toggleNav() {
    this.navToggled = !this.navToggled;
    if (this.navToggled) {
      this.elementRef.nativeElement.ownerDocument.body.classList.add('has-overlay');
    } else {
      this.elementRef.nativeElement.ownerDocument.body.classList.remove('has-overlay');
    }
  }

  /**
   * sets the visibility of sub menu in mobile(hamburger)
   *
   * @memberOf HeaderComponent
   */
  toggleSubMenu(toggleSub, parentId) {
    if (parentId === this.openedSubMenu) {
      this.toggleSub = !this.toggleSub;
    } else {
      this.toggleSub = true;
      this.openedSubMenu = parentId;
    }
  }

  /**
   * Get menu items for the current selected region.
   *
   * @memberof HeaderComponent
   */
  get navMenuItemsDom() {
    let current_menu = this.navMenuItems_COM;
    // not required below items
    // if (this.currentRegion.name === 'Republic of Ireland') {
    //   current_menu = this.navMenuItems_RoI;
    // } else if (this.currentRegion.name === 'Northern Ireland Electricity') {
    //   current_menu = this.navMenuItems_NIE;
    // }
    return current_menu;
  }

  ngOnDestroy() {
    if (this.notifierSubscription) {
      this.notifierSubscription.unsubscribe();
    }
    this.elementRef.nativeElement.ownerDocument.body.classList.remove('has-overlay'); // remove has-overlay class
  }

  showSearchModal() {
    this.isSearchModalShown = true;
  }

  closeSearchModal($event) {
    this.isSearchModalShown = false;
  }

  goToMyAccount() {
    this.router.navigate(['my-account']);
  }
  logout() {
    this.auth.logout();
    localStorage.removeItem('isFinalizedAccount');
  }

}
