import { ReadingHistory } from './reading-history.model';
export interface Meter {
    deDate?: string;
    dialsNumber?: string;
    model?: string;
    number?: string;
    registers: Register[];
    servicenum?: string;
    type?: string;
    lastReadingArray?: ReadingHistory[]; // not for the response data received from Api
    // lastReadingArray store the last readings of the particular meter
}

export interface PremiseMeter {
    meters: Meter[];
    srn?: string;
    utilityType?: string;
    prePaidMeter?: boolean;
}

export interface Reading {
    dialsNum?: number;
    meterReading?: string;
    registerNum?: number;
}

export interface MeterReadingSubmitElec {
    deDate?: string;
    meterModel?: string;
    meterNumber?: string;
    meterServiceId?: string;
    readings: Reading[];
}

export interface MeterReadingSubmitGas {
    deDate?: string;
    dialsNum?: number;
    meterReading?: string;
    registerNum?: number;
}

export interface Register {
    lastReadingDate?: string;
    lastReadingUnits?: string;
    lastReadingValue?: string;
    latestOssReadInsertDate?: string;
    lastReadingMethod?: string;
    lastReadingStatus?: ReadingStatus;
    number?: number;
    registerType?: string;
    meterType?: string; // not for the response data received from Api
    // meterType store the meter type of particular meter
}

export enum ReadingStatus {
    Pending = 'Pending',
    Processed = 'Accepted',
    Rejected = 'Failed',
    Unknown = ''
}


