import { OSSAppConfig } from '../../config/app-config';
import { environment } from '../../../../environments/environment';
import { LocalDataService } from '../../../shared/services/local-data/local-data.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError, finalize } from 'rxjs/operators';
@Injectable()
export class OssContentService {

  ossContentData: any;

  ossRedirectUrls: any;

  constructor(private http: HttpClient) { }

  loadContents(): Promise<any> {
    this.ossContentData = null;
    const path = `${OSSAppConfig.jsonBasePath}/oss-content.json`;
    return this.http
      .get<any>(path)
      .pipe(map(data => data))
      .toPromise()
      .then(data => this.ossContentData = data)
      .catch(error => Promise.resolve());
  }

  loadRedirectUrls(): Promise<any> {
    this.ossRedirectUrls = null;
    const path = `${OSSAppConfig.jsonBasePath}/oss-redirect-urls.json`;
    return this.http
      .get<any>(path)
      .pipe(map(data => data))
      .toPromise()
      .then(data => this.ossRedirectUrls = data)
      .catch(error => Promise.resolve());
  }

  bootstrapOssLocalData(): Promise<any> {
    return Promise.all([this.loadContents(), this.loadRedirectUrls()]);
  }

  getContent(scope: string, contentKey: string) {
    if (this.ossContentData && this.ossContentData[scope] && this.ossContentData[scope][contentKey]) {
      return this.ossContentData[scope][contentKey];
    }
    return environment.production ? '' : '!!KEY NOT FOUND!!';
  }

  getRedirectUrl(key: string) {

    if (this.ossRedirectUrls && this.ossRedirectUrls[key]) {
      return this.ossRedirectUrls[key];
    }
    // TODO: Handle invalid key
    return '';
  }

}
