import { throwError } from 'rxjs';
import { CDMContact } from '../models/preferences/cdm-contact.model';
import { Preferences } from '../models/preferences/preferences.model';
import { OSSAppConfig } from '../../core/config/app-config';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CacheManager } from '../../core/services/cache-manager.service';
import { Observable } from 'rxjs';
import { map , catchError, finalize, mergeMap } from 'rxjs/operators';

@Injectable()
export class PreferencesService {

  constructor(
    private http: HttpClient,
    private cacheManager: CacheManager
  ) { }

   getPartyId(accountNumber: string): Observable<string> {
    const partyId = this.cacheManager.retrieve('cdmPartyId');
    if (partyId) {
      return Observable.create(observer => {
        observer.next(partyId);
        observer.complete();
      });
    }
    const url = `${OSSAppConfig.CDM_BASE}/AccountSS_c?q=CustomerID_c=${accountNumber}`;
    return this.http.get<any>(url).pipe(map(response => {
      if (response.items && response.items[0] && response.items[0].IndividualName_Id_c) {
        this.cacheManager.save('cdmPartyId', response.items[0].IndividualName_Id_c);
        return response.items[0].IndividualName_Id_c;
      }
  //    throw new throwError({ status: 404, message: 'Account not found' });
    }), catchError(exception => throwError(exception))
      , finalize(() => {

      }));
  }

   getPartyNumber(accountNumber: string): Observable<string> {
    const partyNumber = this.cacheManager.retrieve('cmdPartyNumber');
    if (partyNumber) {
      return Observable.create(observer => {
        observer.next(partyNumber);
        observer.complete();
      });
    }
    return this.getCDMContact(accountNumber).pipe(map(contact => {
      if (contact.PartyNumber) {
        this.cacheManager.save('cdmPartyNumber', contact.PartyNumber);
        return contact.PartyNumber;
      }
    //  throw new throwError({ status: 404, message: 'Account not found' });
    }), catchError(exception => {
      return throwError(exception);
    }));
  }

   getCDMContact(accountNumber: string): Observable<CDMContact> {
    return this.getPartyId(accountNumber).pipe(mergeMap(partyId => {
      const url = `${OSSAppConfig.CDM_BASE}/contacts?q=PartyId=${partyId}`;
      return this.http.get<any>(url).pipe(map(response => {
        if (response.items && response.items[0]) {
          const contact: CDMContact = response.items[0];
          this.cacheManager.save('cdmPartyNumber', contact.PartyNumber);
          return contact;
        }
      //  throw new throwError({ status: 404, message: 'Account not found' });
      }), catchError(exception => throwError(exception))
        , finalize(() => {

        }));
    }), catchError(exception => throwError(exception)));
  }

   mapCDMContactToPreferences(contact: CDMContact): Preferences {
    return {
      marketingEmail: contact.PersonDEO_Level2EmailMarketing_c,
      marketingSMS: contact.PersonDEO_Level2SMSMarketing_c,
      marketingMobile: contact.PersonDEO_Level2MobileMarketing_c,
      marketingLandline: contact.PersonDEO_Level2LandlineMarketing_c,
      marketingPost: contact.PersonDEO_Level2PostMarketing_c,
      rewardsOffersCompetitions: contact.​PersonDEO_Level3EmailRewardsOption4_c,
      rewardsMusic: contact.PersonDEO_Level3EmailRewardsMusic_c,
      rewardsFootball: contact.PersonDEO_Level3EmailRewardsSport_c,
      profilingOptOut: contact.PersonDEO_ProfilingOptinOut_c
    };
  }

   mapPreferencesToCDM(preferences: Preferences): Partial<CDMContact> {
    return {
      PersonDEO_Level2EmailMarketing_c: preferences.marketingEmail,
      PersonDEO_Level2SMSMarketing_c: preferences.marketingSMS,
      PersonDEO_Level2MobileMarketing_c: preferences.marketingMobile,
      PersonDEO_Level2LandlineMarketing_c: preferences.marketingLandline,
      PersonDEO_Level2PostMarketing_c: preferences.marketingPost,
      PersonDEO_Level3EmailRewardsSport_c: preferences.rewardsFootball,
      PersonDEO_Level3EmailRewardsMusic_c: preferences.rewardsMusic,
      ​PersonDEO_Level3EmailRewardsOption4_c: preferences.rewardsOffersCompetitions,
      PersonDEO_ProfilingOptinOut_c: preferences.profilingOptOut,
      PersonDEO_OssUpdateFlag_c: true
    };
  }

  public getPreferences(accountNumber: string): Observable<Preferences> {
    return this.getCDMContact(accountNumber).pipe(map(contact => {
      return this.mapCDMContactToPreferences(contact);
    }), catchError(exception => {
      // TODO: Remove undefined and throw error instead.
      /**
       * Since all accounts do not have oracle CDM mappings, temporarily returning undefined
       * to prevent UI crashes for unmapped accounts
       */
      return Observable.create(observer => {
        observer.next(undefined);
        observer.complete();
      });
      // return Observable.throw(exception);
    }));
  }

  public setPreferences(accountNumber: string, preferences: Preferences): Observable<Preferences> {
    return this.getPartyNumber(accountNumber).pipe(mergeMap(partyNumber => {
      const url = `${OSSAppConfig.CDM_BASE}/contacts/${partyNumber}`;
      return this.http.patch(url, this.mapPreferencesToCDM(preferences)).pipe(map(contact => {
        return this.mapCDMContactToPreferences(contact);
      }), catchError(exception => throwError(exception))
        , finalize(() => {

        }));
    }), catchError(exception => throwError(exception)));
  }

}
