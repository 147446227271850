import { Injectable } from '@angular/core';

@Injectable()
export class DataHelper {

    constructor() { }
    public deepClone(obj, escapeKeys?) {

        if (typeof obj !== 'object') { return obj; }
        const newObj = Array.isArray(obj) ? [] : { };

        for (const key in obj) {
            if (typeof obj[key] === 'object') {
                if (obj[key] == null) {
                    newObj[key] = null;
                } else if (Array.isArray(obj[key])) {
                    if (escapeKeys) {
                        if (escapeKeys.indexOf(key) === -1) {
                            newObj[key] = [];
                            obj[key].forEach(elem => newObj[key].push(this.deepClone(elem, escapeKeys)));
                        } else {
                            newObj[key] = obj[key];
                        }
                    } else {
                        newObj[key] = [];
                        obj[key].forEach(elem => newObj[key].push(this.deepClone(elem)));
                    }
                } else {
                    if (escapeKeys) {
                        if (escapeKeys.indexOf(key) === -1) {
                            newObj[key] = this.deepClone(obj[key], escapeKeys);
                        } else {
                            newObj[key] = obj[key];
                        }
                    } else {
                        newObj[key] = this.deepClone(obj[key]);
                    }
                }

            } else {
                newObj[key] = obj[key];
            }
        }
        return newObj;
    }

    // public _BoolToYesNo(obj) {
    //     let newObj = {}
    //     for (let key in obj) {
    //         if (typeof obj[key] == 'object') {
    //             if (obj[key] == null) {
    //                 newObj[key] = null;
    //             }
    //             else if (Array.isArray(obj[key])) {
    //                 newObj[key] = [];
    //                 obj[key].forEach(elem => newObj[key].push(this._BoolToYesNo(elem)));
    //             }
    //             else
    //                 newObj[key] = this._BoolToYesNo(obj[key]);
    //         }
    //         else if (typeof obj[key] == 'boolean') {
    //             newObj[key] = obj[key] ? 'Y' : 'N';
    //         }
    //         else
    //             newObj[key] = obj[key];
    //     }
    //     return newObj;
    // }

    // public _UniqueArray(a) {
    //     let temp = {};
    //     a.forEach(val => temp[val] = true);
    //     return Object.keys(temp);
    // }

    // public _YesNoToBool(obj) {
    //     let newObj = {}
    //     for (let key in obj) {
    //         if (typeof obj[key] == 'object') {
    //             if (obj[key] == null) {
    //                 newObj[key] = null;
    //             }
    //             else if (Array.isArray(obj[key])) {
    //                 newObj[key] = [];
    //                 obj[key].forEach(elem => newObj[key].push(this._YesNoToBool(elem)));
    //             }
    //             else
    //                 newObj[key] = this._YesNoToBool(obj[key]);
    //         }
    //         else {
    //             if (obj[key] === 'Y')
    //                 newObj[key] = true;
    //             else if (obj[key] === 'N')
    //                 newObj[key] = false;
    //             else
    //                 newObj[key] = obj[key];
    //         }
    //     }
    //     return newObj;
    // }

}
