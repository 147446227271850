import { ProxyLoginService } from './proxy-login.service';
import { FeedbackData } from '../../shared/models/nps/feedback-data.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError } from 'rxjs';
import { Observable, timer } from 'rxjs';
import { map, catchError, finalize  } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { OSSAppConfig } from '../config/app-config';
import { CacheManager } from './cache-manager.service';

export enum NPSStatus {
  Submitted = 'SUBMITTED',
  Pending = 'PENDING',
  Ignored = 'IGNORED'
}

export enum NPSFeedbackDomain {
  General = 'GENERAL',
  Login = 'LOGIN',
  Registration = 'REGISTRATION',
  ForgotUsername = 'FORGOT_USERNAME',
  ForgotPassword = 'FORGOT_PASSWORD',
  Dashboard = 'DASHBOARD',
  MeterReadSubmit = 'METER_READ_SUBMIT',
  MeterReadPremiseList = 'METER_READ_PREMISE_LIST',
  BillsPayments = 'BILLS_PAYMENTS',
  BillDetails = 'BILL_DETAILS',
  AccountHistory = 'ACCOUNT_HISTORY',
  AccountDetails = 'ACCOUNT_DETAILS',
  MoveHome = 'MOVE_HOME',
  BudgetPlan = 'BUDGET_PLAN',
  PaymentLoggedIn = 'PAYMENT_LOGGEDIN',
  PaymentLoggedInSuccess = 'PAYMENT_LOGGEDIN_SUCCESS',
  PaymentLoggedInError = 'PAYMENT_LOGGEDIN_ERROR',
  PaymentLoggedOut = 'PAYMENT_LOGGEDOUT',
  PaymentLoggedOutSuccess = 'PAYMENT_LOGGEDOUT_SUCCESS',
  PaymentLoggedOutError = 'PAYMENT_LOGGEDOUT_ERROR',
  PaymentLoggedOutTopup = 'PAYMENT_LOGGEDOUT_TOPUP',
}

export interface NPSWidgetState {
  isVisible: boolean;
  currentDomain: NPSFeedbackDomain;
}

@Injectable()
export class NpsModalService {

  private initialWidgetState: NPSWidgetState = {
    isVisible: false,
    currentDomain: NPSFeedbackDomain.General
  };

  private npsWidgetState: BehaviorSubject<NPSWidgetState> = new BehaviorSubject(this.initialWidgetState);

  constructor(
    private http: HttpClient,
    private cacheManager: CacheManager,
    private proxyLoginService: ProxyLoginService
  ) { }

  get npsWidgetState$(): Observable<NPSWidgetState> { return this.npsWidgetState.asObservable(); }

  invokeNPSModal(feedbackDomain: NPSFeedbackDomain, autoInvoked = true) {
    if (this.proxyLoginService.isCustomerAgent) {
      return;
    }
    if (
      !autoInvoked
      || (autoInvoked && this.getNPSStatus(feedbackDomain) === NPSStatus.Pending)
    ) {
      timer(OSSAppConfig.NPS_POPUP_DELAY).subscribe(trigger => {
        this.npsWidgetState.next({ isVisible: true, currentDomain: feedbackDomain });
      });
    }
  }

  hideNPSWidget() {
    this.npsWidgetState.next({ isVisible: false, currentDomain: this.npsWidgetState.value.currentDomain });
  }
  /**
   * Method to submit NPS feedback
   *
   * @param {any} feedbackData
   * @returns {Observable<any>}
   * @memberof NpsModalService
   */
  public submitFeedback(feedbackData: FeedbackData): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        Authorization: `Basic ${btoa(`apiadmin:password`)}`,
        'Content-Type': 'application/json',
      }),
      params: new HttpParams({})
    };
    return this.http.post(`${OSSAppConfig.nps_url}`, feedbackData, options)
      .pipe(map((response: Response) => response
      ), catchError((exception: Response | any) => throwError(exception))
      , finalize(() => {
      }));
  }

  getNPSStatus(feedbackDomain: NPSFeedbackDomain): NPSStatus {
    return (this.cacheManager.retrieve('NPSStatus') || {})[feedbackDomain] || NPSStatus.Pending;
  }

  setNPSStatus(feedbackDomain: NPSFeedbackDomain, status: NPSStatus) {
    const npsStatus = this.cacheManager.retrieve('NPSStatus') || {};
    npsStatus[feedbackDomain] = status;
    this.cacheManager.save('NPSStatus', npsStatus);
  }

  mapUrlToDomain(url: string): NPSFeedbackDomain {
    const urlMyAccountMatches = url.match(/(?:.*)\/my-account\/([^\/]*)\/?(.*)?/);
    if (urlMyAccountMatches && urlMyAccountMatches.length > 1) {
      switch (urlMyAccountMatches[1]) {
        case 'meter-reading': {
          if (
            urlMyAccountMatches.length > 2
            && urlMyAccountMatches[2]
            && urlMyAccountMatches[2].split('/')[0]
            && !isNaN(+urlMyAccountMatches[2].split('/')[0])
          ) {
            return NPSFeedbackDomain.MeterReadSubmit;
          }
          return NPSFeedbackDomain.MeterReadPremiseList;
        }
        case 'overview': {
          if (
            urlMyAccountMatches.length > 2
            && urlMyAccountMatches[2]
            && urlMyAccountMatches[2].split('/')[0].match(/(budget-plan)/)
          ) {
            return NPSFeedbackDomain.BudgetPlan;
          }
          return NPSFeedbackDomain.Dashboard;
        }
        case 'bills-payments': {
          if (
            urlMyAccountMatches.length > 2
            && urlMyAccountMatches[2]
            && urlMyAccountMatches[2].split('/')[0].match(/(bill)/g)
            && !isNaN(+urlMyAccountMatches[2].split('/')[1])
          ) {
            return NPSFeedbackDomain.BillDetails;
          }
          if (
            urlMyAccountMatches.length > 2
            && urlMyAccountMatches[2]
            && urlMyAccountMatches[2].split('/')[0].match(/(account-history)/g)
          ) {
            return NPSFeedbackDomain.AccountHistory;
          }
          return NPSFeedbackDomain.BillsPayments;
        }
        case 'details': {
          if (
            urlMyAccountMatches.length > 2
            && urlMyAccountMatches[2]
            && urlMyAccountMatches[2].split('/')[0].match(/(move-home)/g)
          ) {
            return NPSFeedbackDomain.MoveHome;
          }
          return NPSFeedbackDomain.AccountDetails;
        }
        case 'payment': {
          if (
            urlMyAccountMatches.length > 2
            && urlMyAccountMatches[2]
            && urlMyAccountMatches[2].split('/')[0].match(/(success)/g)
          ) {
            return NPSFeedbackDomain.PaymentLoggedInSuccess;
          }
          if (
            urlMyAccountMatches.length > 2
            && urlMyAccountMatches[2]
            && urlMyAccountMatches[2].split('/')[0].match(/(error)/g)
          ) {
            return NPSFeedbackDomain.PaymentLoggedInError;
          }
          return NPSFeedbackDomain.PaymentLoggedIn;
        }
        case 'login': {
          return NPSFeedbackDomain.Login;
        }
        case 'registration': {
          return NPSFeedbackDomain.Registration;
        }
        case 'forgot-username': {
          return NPSFeedbackDomain.ForgotUsername;
        }
        case 'forgot-password': {
          return NPSFeedbackDomain.ForgotPassword;
        }
      }
    }
    // Payment Logged out journeys
    if (!url.includes('my-account')) {
      const urlPaymentMatches = url.match(/(payment)\/?(.*)?/);
      if (urlPaymentMatches && urlPaymentMatches.length > 1) {
        switch (urlPaymentMatches[2]) {
          case 'success': {
            return NPSFeedbackDomain.PaymentLoggedOutSuccess;
          }
          case 'error': {
            return NPSFeedbackDomain.PaymentLoggedOutError;
          }
          case 'top-up': {
            return NPSFeedbackDomain.PaymentLoggedOutTopup;
          }
        }
        return NPSFeedbackDomain.PaymentLoggedOut;
      }
    }
    return NPSFeedbackDomain.General;
  }
}
