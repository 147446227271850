<div class="container error-container">  
  <div class="row">
    <div class="oss-panel">
      <h1 class="headline">
        <span>Essential maintenance in progress.</span>
      </h1>
      <div class="goback">
        <a [routerLink]="goBackLink" href="javacript:;">{{'Back to main dashboard'|ossContent: 'shared': 'server-error-go-back-to-dashboard-link'}}</a>
      </div>
      <div class="notification information">
        <span>Apologies for the inconvenience, this page is under maintenance at the moment.</span><br />
        <span>To retrieve your consumption report, please try again later or contact our customer service team. We appreciate your understanding.</span>
      </div>
      <!--
      <div *ngIf="error_response && debug_mode" class="error-debug">
        <h3>Error Debug Info</h3>
        <pre>{{error_response | json}}</pre>
      </div>
      -->
    </div>
  </div>
</div>