import { Pipe, PipeTransform } from '@angular/core';
import { AccountService } from '../services/account.service';
import { CacheManager } from '../../core/services/cache-manager.service';
@Pipe({
  name: 'filterBills'
})
export class FilterBillsPipe implements PipeTransform {


  constructor(public cacheManager: CacheManager) {

  }

  // tslint:disable-next-line: ban-types
  removeWhiteSpace(text): String {
    return text.replace(/\s/g, '');
  }

  transform(items: any[], searchAccountText: string, searchPremiseFuelType: any): any[] {
    searchAccountText = searchAccountText ? searchAccountText : '';
    if (!items) { return []; }
    
    if (!searchAccountText) {
      return items;
    }
    if(items && items.length > 0 && items[0].premisesList) {
      items = this.cacheManager.quickRetrieve('filterResponseInitial');
    }

    return items.filter(accounts => {
      if (accounts.linkedAccounts && accounts.linkedAccounts.length > 0) {
        return accounts.linkedAccounts.some(accountItem => {


          let accDetails = '';

          if (accountItem.accountName) {
            // Object.setPrototypeOf(premiseItem.address, Address.prototype);
            accDetails = accDetails.concat(accountItem.accountName);
          }
          if (accountItem.debtornum) {
            // Object.setPrototypeOf(premiseItem.address, Address.prototype);
            accDetails = accDetails.concat(accountItem.debtornum);
          }

          return !searchAccountText || accDetails.toUpperCase().indexOf(searchAccountText.toUpperCase()) > -1;
        });
      } else {
        const initialItem = this.cacheManager.quickRetrieve('filterResponseInitial');
        if (searchAccountText === '') {
          return accounts.premisesList = initialItem[0].premisesList
        } else {
          if (accounts.premisesList && accounts.premisesList.length > 0) {
            return accounts.premisesList = initialItem[0].premisesList.filter(premise => {
              return premise.mprn.includes(searchAccountText)
                || premise.combinedAddress.toUpperCase().includes(searchAccountText.toUpperCase())

            });
          }
        }
      }
    });

  }

}
