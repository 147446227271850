import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../core/auth/auth.service';
import {
  Route,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

@Component({
  selector: 'oss-oidc-redirect',
  templateUrl: './oidc-redirect.component.html',
  styles: []
})
export class OidcRedirectComponent implements OnInit {
  currentRegion: any;
  constructor(private auth: AuthService, private router: Router) {



    if (this.auth.isAuthorized()) {
      this.router.navigate(['/my-account/acc-overview']);
    } else {
      this.auth.authorize();
    }
  }

  ngOnInit() {
  }

}
