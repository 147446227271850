import { MarketingComms } from '../models/account/marketing-comms.model';
import { Address } from '../models/account/address.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { HttpOptions } from '../../core/services/http-options.service';
import { OSSAppConfig } from '../../core/config/app-config';
import { Account } from '../../shared/models/account/account.model';
import { AccountServices } from '../../shared/models/account/account-services.model';
import { CacheManager } from '../../core/services/cache-manager.service';

@Injectable()
export class AccountService {

  private apiGroupURL = OSSAppConfig.API_GROUP[OSSAppConfig.API_SRC].accountdetails;
  private apiCommercialURL = OSSAppConfig.API_GROUP[OSSAppConfig.API_SRC].commercial;
  private apiGroupURL2 = OSSAppConfig.API_GROUP[OSSAppConfig.API_SRC].accountdetails_v2;

  constructor(
    private http: HttpClient,
    private cacheManager: CacheManager
  ) { }

  public getAccountDetails(accountNumber: string): Observable<Account> {
    /*...For proxy issue fix start.... */
    if(localStorage.getItem('proxyCMCDebtor') !== null && localStorage.getItem('proxyCMCDebtor') !== undefined && localStorage.getItem('proxyCMCDebtor') !== ''
    && localStorage.getItem('isCustomerAgent') == 'true' && (accountNumber === undefined || accountNumber === 'NaN' ||
      accountNumber === null) ) {
        accountNumber = localStorage.getItem('proxyCMCDebtor');
    }
     /*...For proxy issue fix end.... */
    const url = `${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL}/${accountNumber}`;
    return this.http.get<Account>(url)
      .pipe(map((accountDetails: Account) => {
        this.cacheManager.save('paymentMethod', accountDetails.paymentmethod);
        this.cacheManager.quickSave('accountDetails' + accountNumber, accountDetails);
        return accountDetails;
      }), catchError((exception: Response | any) => throwError(exception))
        , finalize(() => {

        }));
  }
  /**
   * Gets commercial customers
   * @returns commercial customers customers
   */
  public getCommercialCustomers(accountNumber: string): Observable<Account> {
    const comCustomersCache = this.cacheManager.quickRetrieve('comCustomers');
    if (comCustomersCache) {
      return Observable.create(observer => {
        observer.next(comCustomersCache);
        observer.complete();
      });
    }
    const url = `${OSSAppConfig.jsonBasePath}/mocks/account-details.json`;
    //  const url = `${OSSAppConfig.DATA_API_BASE}/${this.apiCommercialURL}`;
    //   const url = `${OSSAppConfig.DATA_API_BASE}/comm-admin/v2/full-commercial-details`;
    return this.http.get<any>(url)
      .pipe(map((comCustomers: any) => {
        this.cacheManager.quickSave('comCustomers', comCustomers);
        return comCustomers;
      }), catchError((exception: Response | any) => throwError(exception))
        , finalize(() => {

        }));
  }
  /**
   * Saves subscription
   * @param accountNumber
   * @param formData
   * @returns
   */
  public saveSubscription(accountNumber: string, formData) {
    this.cacheManager.quickRemove('accountDetails' + accountNumber);
    const url = `${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL}/${accountNumber}/meter-read-reminders`;
    return this.http.put(url, formData)
      .pipe(map((response) => {
        return response;
      }), catchError((exception: Response | any) => throwError(exception))
        , finalize(() => {
        }));
  }
  /**
   * Gets account services
   * @param accountNumber
   * @returns account services
   */
  public getAccountServices(accountNumber: string): Observable<AccountServices[]> {
    const accountServicesCache = this.cacheManager.quickRetrieve('accountServices');
    if (accountServicesCache) {
      return Observable.create(observer => {
        observer.next(accountServicesCache);
        observer.complete();
      });
    }
    const url = `${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL2}/${accountNumber}/services-v2`;
    // const url = `${OSSAppConfig.jsonBasePath}/mocks/services.json`;
    return this.http.get<AccountServices[]>(url)
      .pipe(map((serviceDetails: AccountServices[]) => {
        this.cacheManager.quickSave('accountServices', serviceDetails);
        return serviceDetails;
      }), catchError((exception: Response | any) => throwError(exception))
        , finalize(() => {

        }));
  }
  /**
   * Gets customer pay method
   * @returns
   */
  public getCustomerPayMethod() {
    const paymentMethod = this.cacheManager.retrieve('paymentMethod');
    return paymentMethod;
  }
  /**
   * Updates billing address
   * @param accountNumber
   * @param billingAddress
   * @returns
   */
  public updateBillingAddress(accountNumber: string, billingAddress: Partial<Address>) {
    this.cacheManager.quickRemove('customerDetails');
    this.cacheManager.quickRemove('accountDetails' + accountNumber);
    const url = `${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL}/${accountNumber}/billing-address`;
    return this.http.put(url, billingAddress)
      .pipe(map((response) => {
        return response;
      }), catchError((exception: Response | any) => throwError(exception))
        , finalize(() => {

        }));
  }
  /**
   * Updates ebilling option
   * @param debtornum
   * @param data
   * @returns
   */
  public updateEBillingOption(debtornum, data) {
    this.cacheManager.quickRemove('accountDetails' + debtornum);
    return this.http.put(`${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL}/${debtornum}/ebilling`, data)
      .pipe(map((response: Response) => response
      ), catchError((exception: Response | any) => throwError(exception))
        , finalize(() => {
        }));
  }
  /**
   * Updates marketing preferences
   * @param accountNumber
   * @param marketingPreferences
   * @returns
   */
  public updateMarketingPreferences(accountNumber: string, marketingPreferences: MarketingComms) {
    this.cacheManager.quickRemove('accountDetails' + accountNumber);
    const url = `${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL}/${accountNumber}/marketing-comms`;
    return this.http.put(url, marketingPreferences)
      .pipe(map((response) => {
        return response;
      }), catchError((exception: Response | any) => throwError(exception))
        , finalize(() => {

        }));
  }
  /**
   * Gets multi account user info
   * @returns
   */
  public getMultiAccountUserInfo() {
    // const url = `${OSSAppConfig.jsonBasePath}/mocks/multiaccount-admin-group.json`;
    const multiAccountDetailsCache = this.cacheManager.quickRetrieve('multiAccountDetails');
    if (multiAccountDetailsCache) {
      return Observable.create(observer => {
        observer.next(multiAccountDetailsCache);
        observer.complete();
      });
    }
    const url = `${OSSAppConfig.DATA_API_BASE}/comm-admin/v1/full-commercial-detailsV2`;

    return this.http.get(url)
      .pipe(map((multiAccountAdminInfo: any) => {
        this.cacheManager.quickSave('multiAccountDetails', multiAccountAdminInfo);
        return multiAccountAdminInfo;
      }), catchError((exception: Response | any) => Observable.throw(exception))
        , finalize(() => {
        }));
  }
  public getCommercialAccounts() {
    // const url = `${OSSAppConfig.jsonBasePath}/mocks/multiaccount-admin-group.json`;
    const url = `${OSSAppConfig.DATA_API_BASE}/comm-admin/v1/customers`;

    return this.http.get(url)
      .pipe(map((commercialAccounts: any) => {
        return commercialAccounts;
      }), catchError((exception: Response | any) => Observable.throw(exception))
        , finalize(() => {
        }));
  }
  /**
   * Gets meter read stats
   * @returns
   */
  public getMeterReadStats() {
    // const url = `${OSSAppConfig.jsonBasePath}/mocks/multiaccount-admin-group.json`;
    const url = `${OSSAppConfig.DATA_API_BASE}/comm-admin/v1/meter-read-stats`;

    return this.http.get(url)
      .pipe(map((multiAccountAdminInfo: any) => {
        return multiAccountAdminInfo;
      }), catchError((exception: Response | any) => Observable.throw(exception))
        , finalize(() => {
        }));
  }
  /**
   * Gets meter history info
   * @param accountNumber
   * @returns
   */
  public getMeterHistoryInfo(accountNumber: string) {
    const url = `${OSSAppConfig.jsonBasePath}/mocks/meter-history.json`;
    return this.http.get(url)
      .pipe(map((meterHistoryInfo: any) => {
        return meterHistoryInfo;
      }), catchError((exception: Response | any) => Observable.throw(exception))
        , finalize(() => {
        }));
  }
  public getConsolidatedBalace() {
    const url =  `${OSSAppConfig.DATA_API_BASE}/comm-admin/v1/consolidated-details`;
    return this.http.get(url)
      .pipe(map((consolidatedData: any) => {
        return consolidatedData;
      }), catchError((exception: Response | any) => Observable.throw(exception))
        , finalize(() => {
        }));
  }
}
