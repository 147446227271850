import { Component, OnInit } from '@angular/core';
import { CacheManager } from '../../../core/services/cache-manager.service';
import { OSSAppConfig } from '../../../core/config/app-config';
@Component({
  selector: 'oss-server-error',
  templateUrl: './server-error.component.html',
  styleUrls: ['./server-error.component.scss']
})
export class ServerErrorComponent implements OnInit {
  goBackLink = '/my-account';
  error_response: any;
  // tslint:disable-next-line: ban-types
  debug_mode: Boolean = false;
  constructor(private cacheManager: CacheManager) { }

  ngOnInit() {
    this.debug_mode = OSSAppConfig.DEBUG_MODE;
    this.error_response = this.cacheManager.quickRetrieve('err_response');
  }

}
