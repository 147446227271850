<div [config]="{ show: true, animated: true }" (onHidden)="closeModal()" bsModal class="modal oss-modal fade search-modal" tabindex="-1" role="dialog" aria-hidden="true">


  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button title="Close the popup window" type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h2>Search</h2>
        <form novalidate (ngSubmit)="search()" class="search-form">
          <input type="text" placeholder="Search" name="search" [(ngModel)]="searchQuery" autocomplete="off">
          <button class="btn-submit" type="submit"></button>
        </form>
      </div>
    </div>
  </div>

</div>