import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'oss-search-modal',
  templateUrl: './search-modal.component.html',
  styleUrls: ['./search-modal.component.scss']
})
export class SearchModalComponent implements OnInit {

  @Output()
  hide = new EventEmitter();
  @Input()
  searchUrl = 'https://www.sseairtricity.com/ie/home/help-centre/search';

  searchQuery = '';

  constructor() { }

  ngOnInit() {
  }

  closeModal() {
    this.hide.emit();
  }

  search() {
    const url = `${this.searchUrl}?q=${this.searchQuery}`;
    window.open(url, '_self');
  }


}
