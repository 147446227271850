import { CustomerService } from '../../services/customer.service';
import { AuthService } from '../../../core/auth/auth.service';
import { FeedbackData } from '../../models/nps/feedback-data.model';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CustomUiService } from '../../services/custom-ui.service';
import { OSSAppConfig } from '../../../core/config/app-config';
import { LocalizationService } from '../../../core/services/localization/localization.service';
import { NPSFeedbackDomain, NpsModalService, NPSStatus } from '../../../core/services/nps-modal.service';
import { Premise } from '../../../shared/models/premise/premise.model';
@Component({
  selector: 'oss-nps-modal',
  templateUrl: './nps-modal.component.html',
  styleUrls: ['./nps-modal.component.scss']
})
export class NpsModalComponent implements OnInit, OnChanges {

  premise: Premise;

  //   config = {
  //     displayKey: 'displayName', //if objects array passed which key to be displayed defaults to description
  //     placeholder:'Choose an Option', // text to be displayed when no item is selected defaults to Select,
  // }

  @ViewChild('childModal') public npsModal: ModalDirective;
  // @Input() config: any;
  @Input() isOpen: boolean;
  @Input() modalRef: any;
  @Input() customClass: any = '';
  @Input() modalSize: any = 'lg'; // accepted values 'lg' or 'sm'
  @Output() hide = new EventEmitter<any>();
  @Input() isModalShown = false;
  @Input() feedbackDomain = NPSFeedbackDomain.General;

  satisfactionRating: number;
  improveRating: number;
  helpRating: number;
  comment: any;
  comment2: any;
  jurisdiction: any;
  isFeedbackSubmitted = false;
  sending = false;
  feedbackFlag = false;
  mandetoryFlag = false;
  satisfyFlag = false;
  improveFlag = false;
  helpFlag = false;
  selectedRoleType;
  roleType: any[] = [
    { type: 'business_owner', name: 'Business Owner / Manager' },
    { type: 'account_payable', name: 'Accounts Payable'},
    { type: 'em_fm', name: 'Energy Manager / Facilities Manager' },
    { type: 'broker_tpi', name: 'Broker / TPI' },
    { type: 'procurement', name: 'Procurement' },
    { type: 'other', name: 'Other' }
  ];

  constructor(
    private localizationService: LocalizationService,
    private renderer: Renderer2,
    private customUiService: CustomUiService,
    private npsModalService: NpsModalService,
    private authService: AuthService,
    private customerService: CustomerService
  ) {
    this.localizationService.region$.subscribe(region => {
      this.jurisdiction = region.jurisdiction;
    });
  }

  ngOnInit() { }

  ngOnChanges(changes) {
    if (changes && changes.isModalShown && changes.isModalShown.currentValue) {
      // this.satisfactionRating = 5;
      // this.improveRating = 5;
      // this.helpRating = 5;
      this.comment = undefined;
      this.renderer.addClass(document.body, 'hide-bg');
      this.isFeedbackSubmitted = (this.npsModalService.getNPSStatus(this.feedbackDomain) === NPSStatus.Submitted);
    }
    if (changes && changes.feedbackDomain) {
      if (!this.feedbackDomain) {
        this.feedbackDomain = NPSFeedbackDomain.General;
      }
    }
  }
  /**
   *
   * method to hide modal
   * @memberof HowReadModalComponent
   */
  public hideModal(): void {
    this.isModalShown = false;
    this.renderer.removeClass(document.body, 'hide-bg');
    this.customUiService.unSetModalOpenClass();
    this.hide.emit();
    if (!this.isFeedbackSubmitted) {
      this.npsModalService.setNPSStatus(this.feedbackDomain, NPSStatus.Ignored);
    }
  }

  public setRating(category, rating) {
    if (category === 'satisfaction') {
      this.satisfactionRating = rating;
      this.satisfyFlag = true;
    } else if (category === 'improvement') {
      this.improveRating = rating;
      this.improveFlag = true;
    } else if (category === 'help') {
      this.helpRating = rating;
      this.helpFlag = true;
      if( this.helpRating <=5){
        this.feedbackFlag = true;
      }
      else{
        this.feedbackFlag = false;
      }
    }
  }

  public onSubmit() {
    const feedbackData: FeedbackData = {
      Comment2: this.comment2,
      Comment: this.comment,
      URL: window.location.href,
      NPS: this.improveRating,
      Satisfaction: this.satisfactionRating,
      HelpSatisfaction: this.helpRating,
      Jurisdiction: this.jurisdiction,
      Role: this.selectedRoleType.name
    };
    if (this.authService.isAuthorized()) {
      feedbackData.CustomerID = this.customerService.getAccountNo() || '';
      feedbackData.CustomerType = JSON.parse(localStorage.getItem('userData')).custtype || 'CO';
    }
    this.sending = true;
    this.npsModalService.submitFeedback(feedbackData)
      .subscribe(response => {
        this.isFeedbackSubmitted = true;
        this.sending = false;
        this.npsModalService.setNPSStatus(this.feedbackDomain, NPSStatus.Submitted);
      });
  }
}

