import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'oss-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  goBackLink = '/my-account';
  constructor(
    private route: ActivatedRoute,
    private location: Location
  ) { }

  ngOnInit() { }

}
