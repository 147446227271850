export class Address {
    addressLine1?: string;
    addressLine2?: string;
    town?: string;
    addressName?: string;
    addressTitle?: string;
    country?: string;
    county?: string;
    postCode?: string;

    private _completeAddress?: string;
    private _completeAddressWithTitle?: string;

    get completeAddress(): string {
        if (!this._completeAddress) {
            this._completeAddress = getAddressArray(this).join(' ');
        }
        return this._completeAddress;
    }

    get completeAddressWithTitle(): string {
        if (!this._completeAddressWithTitle) {
            this._completeAddressWithTitle = getAddressArray(this, true).join(' ');
        }
        return this._completeAddressWithTitle;
    }
}

export function getAddressArray(address: Address, includeAddressTitle = false): string[] {
    const addressLines: string[] = [];
    const cleanAddressLine = (addressLine) => addressLine
        // Replace multiple spaces
        .replace(/ {2,}/g, ' ')
        // Replace trailing comma, full stop or empty space
        .replace(/[,.\s]*$/, '');
    if (includeAddressTitle && address.addressTitle && cleanAddressLine(address.addressTitle)) {
        addressLines.push(cleanAddressLine(address.addressTitle));
    }
    if (address.addressLine1 && cleanAddressLine(address.addressLine1)) {
        addressLines.push(cleanAddressLine(address.addressLine1));
    }
    if (address.addressLine2 && cleanAddressLine(address.addressLine2)) {
        addressLines.push(cleanAddressLine(address.addressLine2));
    }
    if (address.town && cleanAddressLine(address.town)) {
        addressLines.push(cleanAddressLine(address.town));
    }
    if (address.postCode && cleanAddressLine(address.postCode)) {
        addressLines.push(cleanAddressLine(address.postCode));
    }
    addressLines.map((addressLine, index, addressArray) => {
        if (index < addressArray.length - 1) {
            addressArray[index] = addressArray[index] + ',';
        }
    });
    return addressLines;
}
