import { ProxyLoginService } from '../services/proxy-login.service';
import { Observable, BehaviorSubject, Subject, of, throwError } from 'rxjs';

import { Injectable, Injector } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../../environments/environment';
import { OSSAppConfig } from '../config/app-config';
import { Router } from '@angular/router';
import { LoaderService } from '../../shared/components/loader/loader.service';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpErrorResponse,
    HttpHandler,
    HttpEvent,
    HttpSentEvent,
    HttpHeaderResponse,
    HttpProgressEvent,
    HttpUserEvent
} from '@angular/common/http';
import { CacheManager } from '../../core/services/cache-manager.service';
import { OSSOpenIDConfiguration } from '../config/oss-oidc-config';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { switchMap, timeout, catchError } from 'rxjs/operators';
@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
    private _refreshRequest: Observable<HttpResponse<any>> | null = null;
    public authService = <AuthService>this._injector.get(AuthService);
    private _refreshSubject: Subject<any> = new Subject<any>();
    constructor(
        private _injector: Injector,
        private router: Router,
        private loader: LoaderService,
        private proxyLoginService: ProxyLoginService,
        private cacheManager: CacheManager,
        private OIdcConfig: OSSOpenIDConfiguration,
        private _securityService: OidcSecurityService
    ) {
        this.overrideSecurityService();
    }

    /**
     * Add a subject to OIDC client library to notify when new authorization data is set
     *
     * @private
     * @memberof AppHttpInterceptor
     */
    private overrideSecurityService() {
        (OidcSecurityService as any).prototype.authorizeDataModified = new Subject<number>();
        OidcSecurityService.prototype['setAuthorizationData'] = function (access_token: any, id_token: any) {
            if (this.oidcSecurityCommon.accessToken !== '') {
                this.oidcSecurityCommon.accessToken = '';
            }

            this.loggerService.logDebug(access_token);
            this.loggerService.logDebug(id_token);
            this.loggerService.logDebug('storing to storage, getting the roles');
            this.oidcSecurityCommon.accessToken = access_token;
            this.oidcSecurityCommon.idToken = id_token;
            this.setIsAuthorized(true);
            this.oidcSecurityCommon.isAuthorized = true;
            this.authorizeDataModified.next(new Date().getTime());
            this.authorizeDataModified.complete();
            this.authorizeDataModified = new Subject<number>();
        };
    }

    private _addTokenHeader(request: HttpRequest<any>) {
        let authToken: any;
        authToken = this.authService.getAccessToken();
        const url = request.url;
        // TODO: Added for Oracle CDM calls. Remove when Version1 API is available for preference center
        if (url.includes(OSSAppConfig.CDM_BASE)) {
            const setHeadersCDM = {
                Authorization: `Basic ${btoa(`${OSSAppConfig.CDM_USERNAME}:${OSSAppConfig.CDM_PASSWORD}`)}`,
                // 'Content-Type': 'application/json'
            };
            return request.clone({
                url,
                setHeaders: setHeadersCDM
            });
        }
        const setHeaders = {};
        let headers = request.headers;
        // Add authentication token (if available) if not loggedOutPaymentJourney
        if (!request.headers.has('isLoggedOutJourney') && authToken) {
            setHeaders['Authorization'] = 'Bearer ' + authToken;
        }
        // Remove isLoggedOutJourney header if present
        if (request.headers.has('isLoggedOutJourney')) {
            headers = request.headers.delete('isLoggedOutJourney');
        }
        if (this.proxyLoginService.isCustomerAgent && this.proxyLoginService.proxyDebtorNumber) {
            setHeaders['csr-customer'] = this.proxyLoginService.proxyDebtorNumber;
        }
        if(localStorage.getItem('proxyCMCDebtor') !== null && localStorage.getItem('proxyCMCDebtor') !== undefined &&
        localStorage.getItem('proxyCMCDebtor') !== '') {
            setHeaders['csr-customer'] = localStorage.getItem('proxyCMCDebtor');
        }
        if (OSSAppConfig.API_SRC === 'frontend' && request.url.startsWith(OSSAppConfig.API_GATEWAY_URL)) {
            setHeaders['Ocp-Apim-Subscription-Key'] = OSSAppConfig.OCP_API_SUB_KEY;
            setHeaders['Content-Type'] = 'application/json';
            if (OSSAppConfig.ENABLE_API_TRACE) {
                setHeaders['Ocp-Apim-Trace'] = 'true';
            }
        }
        if (localStorage.getItem('csr-token') !== null && !url.includes('connect/userinfo') 
        && !url.includes('retrieve-proxytoken/sg-username')) {
            setHeaders['Authorization'] = 'Bearer ' + localStorage.getItem('csr-token');
        }
       
        return request.clone({
            url,
            headers,
            setHeaders
        });
    }

    private _fail() {
        // this._tokenStorage.clearTokens();
        // this._router.navigate(['/login']);
        return of(new HttpResponse({ status: 401 }));
    }
    private _sendRequest(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        return next.handle(request).pipe(timeout(120000), catchError(err => { // Initila time out was 60000 due to usage report API issues increasde the timeout
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    if (!this.authService.isAuthorized()) {
                        // If user is not authorized, go to login page
                        this.authService.refreshSession();
                    } else {
                        // User is authorized. Token expired
                        // Do not retry while checking account validity for proxy login
                        if (this.proxyLoginService.isCustomerAgent &&
                            !this.proxyLoginService.proxyDebtorNumber &&
                            err.url &&
                            // tslint:disable-next-line:max-line-length
                            err.url.includes(`${OSSAppConfig.DATA_API_BASE}/${OSSAppConfig.API_GROUP[OSSAppConfig.API_SRC].customerdetails}/`)) {
                            return throwError(err);
                        } else {
                            if (OSSAppConfig.API_SRC === 'frontend' && !this.isWhitelisted(request.url) &&
                                !request.headers.has('isLoggedOutJourney')) {
                                return this._ifTokenExpired().
                                    pipe(switchMap(() => {
                                        return (this._securityService as any).authorizeDataModified.asObservable().switchMap(
                                            (isAuthorized: any) => {
                                                return next.handle(this._addTokenHeader(request));
                                            });
                                    }));
                            }
                        }
                    }
                }
                if (err.status === 403) {
                    this.authService.refreshSession();
                }

                if (err.status === 500) {
                    // handle 500
                    this.loader.stop();
                    this.cacheManager.quickRemove('err_response');
                    this.cacheManager.quickSave('err_response', err.error);
                    this.router.navigate(['/my-account/service-error']);
                }
                if (err.status === 503) {
                    this.loader.stop();
                    this.cacheManager.quickRemove('err_response');
                    this.cacheManager.quickSave('err_response', err.error);
                    this.router.navigate(['/my-account/service-error']);
                }
                return throwError(err);
            }
            return throwError('REQ_TIMEOUT');
        }));
    }
    // tslint:disable-next-line: ban-types
    private isWhitelisted(url): Boolean {
        const IDS_BASE_URL = (OSSAppConfig.API_SRC === 'frontend') ? OSSAppConfig.API_GATEWAY_URL : OSSAppConfig.API_BASE;
        const whiteList = [
            OSSAppConfig.FAQ_URL,
            OSSAppConfig.nps_url,
            OSSAppConfig.billHoverTextUrl,
            `${IDS_BASE_URL}${OSSAppConfig.ID_SERVICE_URL}`,
            `${OSSAppConfig.OSS_COMM_BASE}${OSSAppConfig.OSS_COMM_TOKEN_URL}`,
            this.OIdcConfig.revocation_session_endpoint
        ];
        return whiteList.indexOf(url) > -1 || url.indexOf('assets/data') > -1 ? true : false;
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let req: HttpRequest<any> = request;
        if (!this.isWhitelisted(request.url)) {
            req = this._addTokenHeader(request);
        }
        return this._sendRequest(req, next);
    }
    private _ifTokenExpired() {
        this._refreshSubject.subscribe({
            complete: () => {
                this._refreshSubject = new Subject<any>();
            }
        });
        if (this._refreshSubject.observers.length === 1) {
            this.authService.refreshToken();
            // .subscribe(this._refreshSubject);
        }
        return this._refreshSubject;
    }
}
