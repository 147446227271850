import { Address } from '../models/account/address.model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterSupplyAddress'
})
export class FilterSupplyAddressPipe implements PipeTransform {
  // tslint:disable-next-line: ban-types
  ALL: String = 'All';
  // tslint:disable-next-line: ban-types
  GAS: String = 'gas';
  // tslint:disable-next-line: ban-types
  ELECTRICITY: String = 'elec';



  // tslint:disable-next-line: ban-types
  removeWhiteSpace(text): String {
    return text.replace(/\s/g, '');
  }
  removeExcessWhiteSpace(text): String {
    return text.replace(/\s\s+/g, ' ');
  }

  transform(items: any[], searchPremiseText: string, searchPremiseFuelType: any): any[] {
    searchPremiseText = searchPremiseText ? searchPremiseText : '';
    if (!items) { return []; }
    return items.filter(premiseItem => { 
      let searchFound = false;
      if (searchPremiseText) {
        if (premiseItem.address) {
          if (premiseItem.address.addressTitle &&
            this.removeExcessWhiteSpace(premiseItem.address.addressTitle).toUpperCase().includes(searchPremiseText.toUpperCase())) {
              searchFound = true;
          }
          if (premiseItem.address.addressLine1 &&
            this.removeExcessWhiteSpace(premiseItem.address.addressLine1).toUpperCase().includes(searchPremiseText.toUpperCase())) {
              searchFound = true;
          }
          if (premiseItem.address.addressLine2 &&
            this.removeExcessWhiteSpace(premiseItem.address.addressLine2).toUpperCase().includes(searchPremiseText.toUpperCase())) {
              searchFound = true;
          }
          if (premiseItem.address.town &&
            this.removeExcessWhiteSpace(premiseItem.address.town).toUpperCase().includes(searchPremiseText.toUpperCase())) {
              searchFound = true;
          }
          if (premiseItem.address.postCode &&
            this.removeExcessWhiteSpace(premiseItem.address.postCode).toUpperCase().includes(searchPremiseText.toUpperCase())) {
              searchFound = true;
          }
        }
      }
      if (searchFound && searchPremiseText || !searchPremiseText) {
        if (searchPremiseFuelType.type === this.ALL){
          return true;
        } else if (searchPremiseFuelType.type === this.GAS && premiseItem.gprn){
          return true;
        } else if (searchPremiseFuelType.type === this.ELECTRICITY && premiseItem.mprn){
          return true;
        }
      }
     return false;
    });
  }

}
