import { ReadingHistory } from '../models/meter/reading-history.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable , of, throwError} from 'rxjs';
import { map , catchError, finalize, take, delay, retryWhen, concat, concatMap, mergeMap } from 'rxjs/operators';

import { OSSAppConfig } from '../../core/config/app-config';
import { Meter, PremiseMeter } from '../models/meter/meter.model';
import { LoaderService } from '../../shared/components/loader/loader.service';
import * as moment from 'moment';

@Injectable()
export class MeterService {

  private apiGroupURL = OSSAppConfig.API_GROUP[OSSAppConfig.API_SRC].meterdetails;
  meters: PremiseMeter[];
  constructor(private http: HttpClient, private loader: LoaderService) { }

  public getReadingHistory(
    accountNumber: string,
    premiseId: string,
    utilityType: 'ELECTRICITY' | 'GAS'
  ): Observable<ReadingHistory[]> {
    let url = `${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL}/premises/${premiseId}/customer-id/${accountNumber}`;
    if (utilityType === 'ELECTRICITY') {
      url = `${url}/meter-read-history-elec`;
    } else if (utilityType === 'GAS') {
      url = `${url}/meter-read-history-gas`;
    }
    return this.http.get<ReadingHistory[]>(url)
      .pipe(map((historyArray) => {
        return historyArray;
      }), catchError((exception: Response | any) => throwError(exception))
      , finalize(() => {

      }));
  }

  public getMetersByPremise(premnum: any): Observable<PremiseMeter[]> {
    const url = `${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL}/premises/${premnum}/srn`;
    return this.http.get<PremiseMeter[]>(url)
      .pipe(map((meters: PremiseMeter[]) => {
        return meters;
      })

      , retryWhen(retryError => {
        return retryError.pipe(
          mergeMap((err: any) => {
            if (err.status === 500) {
              return of(err.status);
            }
            return throwError({ error: 'No retry' });
          }),
          delay(1000),
          take(3),
          concat(throwError({ error: 'Sorry, there was an error (after 3 retries)' })));
      })

      , catchError((exception: Response | any) => throwError(exception))
      , finalize(() => {

      })
      );
  }

  public submitRead(utilityType: any, data: any, premnum: any, meterType: any): Observable<any> {

    let url;
    if (utilityType === 'ELECTRICITY') {
      url = `${this.apiGroupURL}/premises/${premnum}/elec-meter-read`;
    }
    if (utilityType === 'GAS') {
      url = `${this.apiGroupURL}/premises/${premnum}/gas-meter-read`;
    }
    return this.http.post(`${OSSAppConfig.DATA_API_BASE}/${url}`, data)
      .pipe(map((response: Response) => response)
      , catchError((exception: Response | any) => throwError(exception))
      , finalize(() => {
      }));
  }

  // download last reading report 
//   public downloadLastReadingReport(): Observable<any> {

//     // let test = 'https://api.ossapi2.sseairtricity.com';
//     const url = `${OSSAppConfig.DATA_API_BASE}/comm-admin/v1/meter-read-report-download`;
//     // const url = `${OSSAppConfig.DATA_API_BASE}/meter-details/v2/premises/${premiseId}/srn-v2`;
//     return this.http.get(url);
//     // console.log('api det...' ,url );
//     //         // return this.http.get(url);

//     //         // const url = `${OSSAppConfig.DATA_API_BASE}/billing-details/v1/customers/bulk-pdf-api-availability`;

//     //         return this.http.get(url)
//     //             .pipe(map((info: any) => {
//     //                 return info;
//     //             }), catchError((exception: Response | any) => Observable.throw(exception))
//     //                 , finalize(() => {
//     //                 }));
// }
public downloadLastReadingReport(): Observable<any> {


  //  const url = `${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL}/customers/${debtornum}/transaction-history`;
  // const url = `${OSSAppConfig.jsonBasePath}/mocks/usage-segment-list.json`;
  const url = `${OSSAppConfig.DATA_API_BASE}/comm-admin/v1/meter-read-report-download`;
  const options = {
    observe: 'response' as 'body',
    responseType: 'blob' as 'json'
  };
  let headers = new HttpHeaders();
  // headers = headers.set('Accept', 'application/xls');
  headers = headers.set('Accept', 'application/csv');
  return this.http.get<any>(url, options)
    .pipe(map((data) => {
      let blob; let name;
      if (data) {
        let nameHeader = data.headers.get('Content-Disposition');
        name = nameHeader.split('=')[1];

        // blob = new Blob([data.body], { type: 'application/xls' });
        blob = new Blob([data.body], { type: 'application/csv' });
      }
      return {
        filename: name,
        data: blob
      };
      // return segmentList;
    }), catchError((exception: Response | any) => throwError(exception))
      , finalize(() => {
      }));

  // const headers: HttpHeaders = new HttpHeaders({
  //   'Accept': 'application/csv'
  // });
  // const url = `${OSSAppConfig.DATA_API_BASE}/comm-admin/v1/meter-read-report-download`;
  // return this.http.get(url)
  //   .pipe(map((response: any) => {
  //     return response;
  //   }), catchError((exception: Response | any) => throwError(exception))
  //     , finalize(() => {
  //     }));
}

// public downloadLastReadingReport() {
//   const headers: HttpHeaders = new HttpHeaders({
//     'Accept': 'application/csv'
//   });
//   const url = `${OSSAppConfig.DATA_API_BASE}/comm-admin/v1/meter-read-report-download`;
//   return this.http.get(url, {headers, responseType: 'text'})
//     .pipe(map((response: any) => {
//       return response;
//     }), catchError((exception: Response | any) => throwError(exception))
//       , finalize(() => {
//       }));
// }
}
