import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OidcCallbackComponent } from './shared/components/oidc-callback/oidc-callback.component';
import { OidcRedirectComponent } from './shared/components/oidc-redirect/oidc-redirect.component';
import { OidcSignoutComponent } from './shared/components/oidc-signout/oidc-signout.component';
import { UnauthorizedComponent } from './shared/components/unauthorized/unauthorized.component';
import { AuthGuard } from './core/guards/auth-guard.service';
import { NotFoundComponent, ServerErrorComponent } from './shared/components';
import { RouteResolver } from './core/services/route-resolver.service'; //Import our new resolver

const routes: Routes = [
  {
    path: '',
    resolve: {
      csrProxyLogin: RouteResolver
    },
    redirectTo: 'my-account',
    pathMatch: 'full',
  },
  {
    path: 'my-account',
    resolve: {
      csrProxyLogin: RouteResolver
    },
    children: [
      {
        path: '',
        resolve: {
          csrProxyLogin: RouteResolver
        },
        redirectTo: 'acc-overview',
        pathMatch: 'full',
          canActivate: [AuthGuard]
      },
      {
        path: 'details',
        loadChildren: () => import('./features/account-details/account-details.module')
        .then(mod => mod.AccountDetailsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'payments',
        loadChildren: () => import('./features/payments/payment.module')
        .then(mod => mod.PaymentModule),
           canActivate: [AuthGuard]
      },
      {
        path: 'meter-reading',
        loadChildren: () => import('./features/meter-reading/meter-reading.module')
        .then(mod => mod.MeterReadingModule),
          canActivate: [AuthGuard]
      },
      {
        path: 'usage-reports',
        loadChildren: () => import('./features/usage-reports/usage-reports.module')
        .then(mod => mod.UsageReportsModule),
          canActivate: [AuthGuard]
      },
      { path: 'superuser',
       loadChildren: () => import('./super-user/super-user.module').then(m => m.SuperUserModule),
         canActivate: [AuthGuard]
      },
      { path: 'adminuser',
       loadChildren: () => import('./admin-user/admin-user.module').then(m => m.AdminUserModule),
         canActivate: [AuthGuard]
      },
      {
        path: 'proxy-login',
        loadChildren: () => import('./proxy-user/proxy-login/proxy-login.module').then(m => m.ProxyLoginModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'acc-overview',
        loadChildren: () => import('./features/account-overview/account-overview.module').then(mod => mod.AccountOverviewModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'bills-overview',
        loadChildren: () => import('./features/billing/billing.module').then(mod => mod.BillingModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'update-password',
        loadChildren: () => import('./update-password/update-password.module').then(mod => mod.UpdatePasswordModule),
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'payment',
    loadChildren: () => import('./features/payment-logged-out/payment-logged-out.module')
    .then(mod => mod.PaymentLoggedOutModule)
  },
  {
    path: 'set-password',
    loadChildren: () => import('./create-password/create-password.module')
    .then(mod => mod.CreatePasswordModule)
  },
  
  // {
  //   path: 'set-password',
  //   loadChildren: 'src/app/create-password/create-password.module#CreatePasswordModule'
  // },
   { path: 'signin-oidc', component: OidcCallbackComponent },
  { path: 'signin-redirect', component: OidcRedirectComponent },
  { path: 'signout-callback-oidc', component: OidcSignoutComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: '', redirectTo: 'my-account', pathMatch: 'full' },
  { path: '**', component: ServerErrorComponent },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [RouteResolver]
})
export class AppRoutingModule {}
