import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { OSSAppConfig } from '../../core/config/app-config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError, finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsageReportService {

  constructor(
    private http: HttpClient
  ) { }
  /**
   * getConsumptionReportForGraph
   */
  public getConsumptionReportForGraph() {
    // const url = `${OSSAppConfig.DATA_API_BASE}/commercial-reports/v1/reports/consumption?readType=${readType}&startDate=${startDate}&endDate=${endDate}&scale=${scale}&reportingIds=${customerIds}&utilityType=${fuel}&idType=${idType}`;

    const url = `${OSSAppConfig.jsonBasePath}/mocks/consumption-api.json`;
    return this.http.get<any>(url)
      .pipe(map(data => data)
        , catchError(error => throwError(error))
        , finalize(() => {

        }));

  }
  /**
   * getCostBreakDownReportForGraph
   */
  public getCostBreakDownReportForGraph(readType, startDate, endDate, scale, utilityType, reportingIds, idType) {
    let url: any;
    if (utilityType === 'G' && (readType === 'INTERVAL' || readType === 'SMARTINTERVAL')) {
      url = `${OSSAppConfig.jsonBasePath}/cost-breakdown-empty-gas-response.json`; // No data from API for interval gas . using mock response here

    } else {
      url = `${OSSAppConfig.DATA_API_BASE}/commercial-reports/v1/reports/costbreakdown?readType=${readType}&startDate=${startDate}&endDate=${endDate}&scale=${scale}&utilityType=${utilityType}&reportingIds=${reportingIds}&idType=${idType}`;
    }
    return this.http.get<any>(url)
      .pipe(map(data => data)
        , catchError(error => throwError(error))
        , finalize(() => {

        }));

  }

  /**
   * getMaxImportReportForGraph
   */
  public getMaxImportReportForGraph() {
    const url = `${OSSAppConfig.jsonBasePath}/mocks/max-import-graph.json`;
    return this.http.get<any>(url)
      .pipe(map(data => data)
        , catchError(error => throwError(error))
        , finalize(() => {

        }));

  }
  public getAccountsForUsageReport(): Observable<any> {
    //  const url = `${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL}/customers/${debtornum}/transaction-history`;
    // const url = `${OSSAppConfig.jsonBasePath}/mocks/usage-account-list.json`;
    const url = `${OSSAppConfig.DATA_API_BASE}/comm-admin/v1/customers`;
    return this.http.get<any>(url)
      .pipe(map((accountList) => {
        return accountList;
      }), catchError((exception: Response | any) => throwError(exception))
        , finalize(() => {
        }));
  }

  public getAccountsForIntervalPremises(debtornum: string): Observable<any> {
    //  const url = `${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL}/customers/${debtornum}/transaction-history`;
    // const url = `${OSSAppConfig.jsonBasePath}/mocks/usage-account-list.json`;
    const url = `${OSSAppConfig.DATA_API_BASE}/comm-admin/v1/customers`;
    return this.http.get<any>(url)
      .pipe(map((accountList) => {
        return accountList;
      }), catchError((exception: Response | any) => throwError(exception))
        , finalize(() => {
        }));
  }
  public getSegmentForIntervalPremises(): Observable<any> {
    //  const url = `${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL}/customers/${debtornum}/transaction-history`;
    // const url = `${OSSAppConfig.jsonBasePath}/mocks/usage-segment-list.json`;
    const url = `${OSSAppConfig.DATA_API_BASE}/comm-admin/v1/segments-V2`;
    return this.http.get<any>(url)
      .pipe(map((segmentList) => {
        return segmentList;
      }), catchError((exception: Response | any) => throwError(exception))
        , finalize(() => {
        }));
  }
  public downloadIntervalConsumptionReport(startDate, endDate, reportingIds, idType): Observable<any> {
    //  const url = `${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL}/customers/${debtornum}/transaction-history`;
    // const url = `${OSSAppConfig.jsonBasePath}/mocks/usage-segment-list.json`;
    const url = `${OSSAppConfig.DATA_API_BASE}/commercial-reports/v1/extracts/intervalconsumption?startDate=${startDate}&endDate=${endDate}&reportingIds=${reportingIds}&idType=${idType}`;
    const options = {
      observe: 'response' as 'body',
      responseType: 'blob' as 'json'
    };
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/xls');
    return this.http.get<any>(url, options)
      .pipe(map((data) => {
        let blob; let name;
        if (data) {
          let nameHeader = data.headers.get('Content-Disposition');
          name = nameHeader.split('=')[1];

          blob = new Blob([data.body], { type: 'application/xls' });
        }
        return {
          filename: name,
          data: blob
        };
      }), catchError((exception: Response | any) => throwError(exception))
        , finalize(() => {
        }));
  }
  public downloadInvoiceReport(startDate, endDate, customerIds): Observable<any> {
    // debugger; // commenting out for now, but should be removed if not needed.

    // console.log('customerIds...' , customerIds) ;
    let url ;
    if(customerIds){
     url = `${OSSAppConfig.DATA_API_BASE}/commercial-reports/v1/extracts/invoicesummary?startDate=${startDate}&endDate=${endDate}&customerIds=${customerIds}`;
      
    }else{
      url = `${OSSAppConfig.DATA_API_BASE}/commercial-reports/v1/extracts/invoicesummary?startDate=${startDate}&endDate=${endDate}`;

    }

    //  const url = `${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL}/customers/${debtornum}/transaction-history`;
    // const url = `${OSSAppConfig.jsonBasePath}/mocks/usage-segment-list.json`;
    // const url = `${OSSAppConfig.DATA_API_BASE}/commercial-reports/v1/extracts/invoicesummary?startDate=${startDate}&endDate=${endDate}&customerIds=${customerIds}`;
    const options = {
      observe: 'response' as 'body',
      responseType: 'blob' as 'json'
    };
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/xls');
    return this.http.get<any>(url, options)
      .pipe(map((data) => {
        let blob; let name;
        if (data) {
          let nameHeader = data.headers.get('Content-Disposition');
          name = nameHeader.split('=')[1];

          blob = new Blob([data.body], { type: 'application/xls' });
        }
        return {
          filename: name,
          data: blob
        };
        // return segmentList;
      }), catchError((exception: Response | any) => throwError(exception))
        , finalize(() => {
        }));
  }
  public downloadConsumptionReport(startDate, endDate, customerIds,
    fuel = 'E', scale = 'MONTH', readType = 'ALL', idType = 'PREM'): Observable<any> {
    //  const url = `${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL}/customers/${debtornum}/transaction-history`;
    // const url = `${OSSAppConfig.jsonBasePath}/mocks/usage-segment-list.json`;
    // const url = `${OSSAppConfig.DATA_API_BASE}/commercial-reports/v1/extracts/invoicesummary?startDate=${startDate}&endDate=${endDate}&customerIds=${customerIds}`;
    const url = `${OSSAppConfig.DATA_API_BASE}/commercial-reports/v1/reports/consumptionextract?readType=${readType}&startDate=${startDate}&endDate=${endDate}&scale=${scale}&reportingIds=${customerIds}&utilityType=${fuel}&idType=${idType}`;
    // readType=${readType}
    const options = {
      observe: 'response' as 'body',
      responseType: 'blob' as 'json'
    };
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/xls');
    return this.http.get<any>(url, options)
      .pipe(map((data) => {
        let blob; let name;
        if (data) {
          let nameHeader = data.headers.get('Content-Disposition');
          name = nameHeader.split('=')[1];

          blob = new Blob([data.body], { type: 'application/xls' });
        }
        return {
          filename: name,
          data: blob
        };
        // return segmentList;
      }), catchError((exception: Response | any) => throwError(exception))
        , finalize(() => {
        }));
  }
  public downloadConsumptionGraph(startDate, endDate, customerIds,
    fuel = 'E', scale = 'MONTH', readType = 'INTERVAL', idType = 'PREM'): Observable<any> {
    let url: any;
    // const url = `${OSSAppConfig.jsonBasePath}/mocks/usage-segment-list.json`;
    if (fuel === 'G' && (readType === 'INTERVAL'|| readType === 'SMARTINTERVAL')) {
      url = `${OSSAppConfig.jsonBasePath}/consumption-gas-interval.json`; // No data from API for interval gas . using mock response here
    } else {
      url = `${OSSAppConfig.DATA_API_BASE}/commercial-reports/v1/reports/consumption?readType=${readType}&startDate=${startDate}&endDate=${endDate}&scale=${scale}&reportingIds=${customerIds}&utilityType=${fuel}&idType=${idType}`;
    }
    return this.http.get<any>(url)
      .pipe(map((data) => {

        return data;
      }), catchError((exception: Response | any) => throwError(exception))
        , finalize(() => {
        }));
  }
  public downloadCostBreakdownReport(
    startDate, endDate, customerIds, fuel = 'E', scale = 'MONTH', readType = 'ALL', idType = 'PREM', sheetType = 'CONS'): Observable<any> {
    //  const url = `${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL}/customers/${debtornum}/transaction-history`;
    // const url = `${OSSAppConfig.jsonBasePath}/mocks/usage-segment-list.json`;
    // const url = `${OSSAppConfig.DATA_API_BASE}/commercial-reports/v1/extracts/invoicesummary?startDate=${startDate}&endDate=${endDate}&customerIds=${customerIds}`;
    const url = `${OSSAppConfig.DATA_API_BASE}/commercial-reports/v1/reports/costbreakdownextract?readType=${readType}&startDate=${startDate}&endDate=${endDate}&scale=${scale}&reportingIds=${customerIds}&utilityType=${fuel}&idType=${idType}&sheetType=${sheetType}`;
    // readType=${readType}
    const options = {
      observe: 'response' as 'body',
      responseType: 'blob' as 'json'
    };
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/xls');
    return this.http.get<any>(url, options)
      .pipe(map((data) => {
        let blob; let name;
        if (data) {
          let nameHeader = data.headers.get('Content-Disposition');
          name = nameHeader.split('=')[1];

          blob = new Blob([data.body], { type: 'application/xls' });
        }
        return {
          filename: name,
          data: blob
        };
        // return segmentList;
      }), catchError((exception: Response | any) => throwError(exception))
        , finalize(() => {
        }));
  }
  public downloadMicReport(startDate, endDate, customerIds, scale = 'MONTH'): Observable<any> {
    //  const url = `${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL}/customers/${debtornum}/transaction-history`;
    // const url = `${OSSAppConfig.jsonBasePath}/mocks/usage-segment-list.json`;
    // const url = `${OSSAppConfig.DATA_API_BASE}/commercial-reports/v1/extracts/invoicesummary?startDate=${startDate}&endDate=${endDate}&customerIds=${customerIds}`;
    const url = `${OSSAppConfig.DATA_API_BASE}/commercial-reports/v1/reports/micextract?startDate=${startDate}&endDate=${endDate}&scale=${scale}&reportingIds=${customerIds}`;
    const options = {
      observe: 'response' as 'body',
      responseType: 'blob' as 'json'
    };
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/xls');
    return this.http.get<any>(url, options)
      .pipe(map((data) => {
        let blob; let name;
        if (data) {
          let nameHeader = data.headers.get('Content-Disposition');
          name = nameHeader.split('=')[1];

          blob = new Blob([data.body], { type: 'application/xls' });
        }
        return {
          filename: name,
          data: blob
        };
        // return segmentList;
      }), catchError((exception: Response | any) => throwError(exception))
        , finalize(() => {
        }));
  }

  public getInsightsData(debtornum, mprn, date, insightIndex) {

    //  debtornum = 2563435;
    // mprn = 10303683785;
    // date = 10082021;
    // const url = `${OSSAppConfig.jsonBasePath}/mocks/usage-insights-list.json`;
    const url = `${OSSAppConfig.DATA_API_BASE}/billing-details/v1/${debtornum}/mprn/${mprn}/insights/${date}/insightIndex/${insightIndex}`;
    const options = {
      responseType: 'text' as 'json'
    };
    return this.http.get<any>(url, options)
      .pipe(map((insightList) => {
        return insightList;
      }), catchError((exception: Response | any) => throwError(exception))
        , finalize(() => {
        }));

    // const url = 'https://www.sseairtricity.com/api/v1/KBArticle';
    // const params: any = {
    //   Location: 'ROI',
    //   Page: 'OSSBilling',
    //   Tag: 'Standard',
    //   limit: 5,
    //   sort: 'Importance',
    //   dir: 'desc'
    // };
    // const options = {
    //   params,
    //   responseType: 'text' as 'json'
    // };
    // return this.http.get<any>(url, options)

    //   .pipe(map((insightList) => {

    //     return insightList;

    //   }), catchError((exception: Response | any) => throwError(exception))

    //     , finalize(() => {

    //     }));
  }

  getEnergyTipsData(location) {
    //const url = `${OSSAppConfig.jsonBasePath}/mocks/energy-tips.json`;

    const url = `${OSSAppConfig.hits_tips_api_url}?Location=${location}&Page=SMART_HINTS_AND_TIPS&Tag=SMART`;

    const options = {
      responseType: 'text' as 'json'
    };
    return this.http.get<any>(url, options)
      .pipe(map((energyTips) => {
        return energyTips;
      }), catchError((exception: Response | any) => throwError(exception))
        , finalize(() => {
        }));
  }
  downloadMicGraphData(startDate, endDate, customerIds, scale = 'MONTH') {
    // const url = `${OSSAppConfig.jsonBasePath}/mocks/mic.json`;
    const url = `${OSSAppConfig.DATA_API_BASE}/commercial-reports/v1/reports/mic?startDate=${startDate}&endDate=${endDate}&scale=${scale}&reportingIds=${customerIds}`;
    const options = {
      responseType: 'text' as 'json'
    };
    return this.http.get<any>(url)
      .pipe(map((mic) => {
        return mic;
      }), catchError((exception: Response | any) => throwError(exception))
        , finalize(() => {
        }));
  }
}
