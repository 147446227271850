import { ProxyLoginService } from '../services/proxy-login.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class ProxyLoginGuard implements CanActivate {

  constructor(
    private proxyLoginService: ProxyLoginService,
    private router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.proxyLoginService.isCustomerAgent) {
      if (this.proxyLoginService.proxyDebtorNumber) {
        this.router.navigate(['my-account/details']);
      }
      return true;
    }
    this.router.navigate(['my-account/acc-overview']);
    return false;
  }
}
