import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable()
export class DateHandlerService {

  constructor(private datePipe: DatePipe) { }

  private _monthIndex = {
    'jan': 1, 'feb': 2, 'mar': 3, 'apr': 4, 'may': 5, 'jun': 6, 'jul': 7, 'aug': 8, 'sep': 9, 'oct': 10, 'nov': 11, 'dec': 12
  };

  parse(dateString: string | undefined): Date | null {
    const yyyymmdd = /^[0-9]{4}[-/][0-9]{1,2}[-/][0-9]{1,2}$/;
    const ddmmyyyy = /^[0-9]{1,2}[-/][0-9]{1,2}[-/][0-9]{4}$/;
    const dmmmyyyy = /^([0-9]{1,2})(?:st|nd|rd|th)?[-/ ]([a-zA-Z]+)[-/ ,]+([0-9]{4})$/;
    const mmmdyyyy = /^([a-zA-Z]+)[-/ ]([0-9]{1,2})(?:st|nd|rd|th)?[-/ ,]+([0-9]{4})$/;
    if (!dateString) {
      return null;
    }
    if (yyyymmdd.test(dateString)) {
      const [year, month, day] = (dateString).split(/[-/]+/);
      return new Date(Number(year), Number(month) - 1, Number(day));
    }
    if (ddmmyyyy.test(dateString)) {
      const [day, month, year] = (dateString).split(/[-/]+/);
      return new Date(Number(year), Number(month) - 1, Number(day));
    }
    if (dmmmyyyy.test(dateString)) {
      const regexArray = dmmmyyyy.exec(dateString);
      if (regexArray && regexArray.length > 3) {
        const day = regexArray[1];
        const month = this._monthIndex[regexArray[2].slice(0, 3).toLowerCase()];
        const year = regexArray[3];
        return new Date(Number(year), Number(month) - 1, Number(day));
      }
    }
    if (mmmdyyyy.test(dateString)) {
      const regexArray = mmmdyyyy.exec(dateString);
      if (regexArray && regexArray.length > 3) {
        const month = this._monthIndex[regexArray[1].slice(0, 3).toLowerCase()];
        const day = regexArray[2];
        const year = regexArray[3];
        return new Date(Number(year), Number(month) - 1, Number(day));
      }
    }
    if (dateString !== '' && !isNaN(Number(dateString))) {
      return new Date(Number(dateString));
    }
    if (this._isValidTimestamp(dateString)) {
      return new Date(dateString);
    }
    return null;
  }

  transform(date: string | Date | undefined, format = 'd MMM yyyy'): string {
    if (!date) {
      return '';
    }
    let dateObj: Date | null;
    if (this._isValidDateObject(<Date> date)) {
      dateObj = <Date> date;
    } else {
      dateObj = this.parse(<string> date);
    }
    if (dateObj) {
      return this.datePipe.transform(dateObj, format) || '';
    } else {
      return '';
    }
  }

  private _isValidTimestamp(timestamp: string): boolean {
    const date = new Date(timestamp);
    return !isNaN(date.getTime());
  }

  private _isValidDateObject(date: Date): boolean {
    if (Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date.getTime())) {
      return true;
    }
    return false;
  }

}
