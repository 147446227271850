import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'supplyAddressSearch'
})
export class SupplyAddressSearchPipe implements PipeTransform {

// tslint:disable-next-line: ban-types
ALL: String = 'All';
// tslint:disable-next-line: ban-types
GAS: String = 'gas';
// tslint:disable-next-line: ban-types
ELECTRICITY: String = 'elec';

removeExcessWhiteSpace(text): String {
  return text.replace(/\s+/g, '').trim();
}

transform(items: any[], searchPremiseText: string, searchPremiseFuelType: any): any[] {
  searchPremiseText = searchPremiseText ? searchPremiseText.replace(/\s+/g, '').trim() : '';
  if (!items) { return []; }
  return items.filter(premiseItem => {
    let searchFound = false;
    if (searchPremiseText) {
      const supplyAddress: any[] = [];
      if (premiseItem.address) {
        supplyAddress.length = 0;
        if (premiseItem.address.addressTitle) {
          supplyAddress.push(premiseItem.address.addressTitle);
        }
        if (premiseItem.address.addressLine1) {
          supplyAddress.push(premiseItem.address.addressLine1);
        }
        if (premiseItem.address.addressLine2) {
          supplyAddress.push(premiseItem.address.addressLine2);
        }
        if (premiseItem.address.town) {
          supplyAddress.push(premiseItem.address.town);
        }
        if (premiseItem.address.postCode) {
          supplyAddress.push(premiseItem.address.postCode);
        }

        const addressData = supplyAddress.join(',').replace(/\s+/g, '').trim().toUpperCase();
        const searText = searchPremiseText.replace(/\s+/g, '').trim().toUpperCase();

        if (addressData && addressData.includes(searText)) {
          searchFound = true;
        }
      }

      if (premiseItem.gprn && this.removeExcessWhiteSpace(premiseItem.gprn).includes(searchPremiseText)) {
        searchFound = true;
      }

      if (premiseItem.mprn && this.removeExcessWhiteSpace(premiseItem.mprn).includes(searchPremiseText)) {
        searchFound = true;
      }
    }
    if (searchFound && searchPremiseText || !searchPremiseText) {
      if (searchPremiseFuelType.type === this.ALL) {
        return true;
      } else if (searchPremiseFuelType.type === this.GAS && premiseItem.gprn) {
        return true;
      } else if (searchPremiseFuelType.type === this.ELECTRICITY && premiseItem.mprn) {
        return true;
      }
    }
    return false;
  });
}

}
