import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, catchError, finalize } from 'rxjs/operators';
import { Customer } from '../../shared/models/customer/customer.model';
import { OSSAppConfig } from '../config/app-config';
import { CacheManager } from './cache-manager.service';
import { LocalizationService } from './localization/localization.service';
import { environment } from '../../../environments/environment';
import { Subject } from 'rxjs';

@Injectable()
export class ProxyLoginService {
  subjectNotifier: Subject<null> = new Subject<null>();
  private customerAPIURL = OSSAppConfig.API_GROUP[OSSAppConfig.API_SRC].customerdetails;
  constructor(
    private cacheManager: CacheManager,
    private router: Router,
    private http: HttpClient,
    private localizationService: LocalizationService
  ) { }

  private _isCustomerAgent$ = new BehaviorSubject<boolean>(this.isCustomerAgent);

  get isCustomerAgent$(): Observable<boolean> {
    return this._isCustomerAgent$.asObservable();
  }

  get isCustomerAgent(): boolean {
    return localStorage.getItem('isCustomerAgent') === 'true';
  }

  set isCustomerAgent(isCSR: boolean) {
    if (isCSR) {
      localStorage.setItem('isCustomerAgent', 'true');
    } else {
      localStorage.removeItem('isCustomerAgent');
    }
    this._isCustomerAgent$.next(isCSR);
  }

  get proxyDebtorNumber(): string | null {
    return localStorage.getItem('proxyDebtorNumber');
  }

  set proxyDebtorNumber(debtorNumber: string | null) {
    if (!!debtorNumber) {
      localStorage.setItem('proxyDebtorNumber', debtorNumber);
    this.notifyAboutChange();
    } else {
      localStorage.removeItem('proxyDebtorNumber');
    }
  }
  notifyAboutChange() {
    this.subjectNotifier.next();
  }

  public retrieveProxyToken(userName: any, csrUsername): Observable<any[]> {
    const url = `${OSSAppConfig.DATA_API_BASE}/comm-admin/v1/retrieve-proxytoken/sg-username/${userName}`;
    const headers = new HttpHeaders({ 'csr-username': csrUsername });
    return this.http.get(url, { headers })
    .pipe(map((tokenInfo: any) => {
      if(tokenInfo && tokenInfo.access_token) {
        localStorage.setItem('csr-role', tokenInfo.role);
        localStorage.setItem('csr-token', tokenInfo.access_token.split(' ')[1]);
        localStorage.setItem('loggedinAsCSRUser',"true");
        this.notifyAboutChange();
      } else {
        localStorage.removeItem('csr-token');
        localStorage.removeItem('csr-role');
        localStorage.removeItem('superuserName');
        localStorage.removeItem('loggedinAsCSRUser');
      }
      return tokenInfo;
    }), catchError((exception: Response | any) => Observable.throw(exception))
      , finalize(() => {
      }));
  }

  logoutProxySession() {
    this.cacheManager.clearQuickCache();
    this.proxyDebtorNumber = null;
    sessionStorage.clear();
    localStorage.removeItem('superuserName');
    localStorage.removeItem('proxyCMCDebtor');
    localStorage.removeItem('csr-role');
   // this.router.navigate(['my-account/proxy-login']);
   window.location.href = environment.app_base_url_oss;

  }

  startProxySession(debtorNumber: string): Observable<string> {
    /**
     *  Re-writing customer API call here to prevent circular dependency between customer service and proxy login service
     */
    const url = `${OSSAppConfig.DATA_API_BASE}/${this.customerAPIURL}/${debtorNumber}`;
    const headers = new HttpHeaders({ 'csr-customer': debtorNumber });
    return this.http.get<Customer>(url, { headers }).pipe(map((customerDetails: Customer) => {
      // Given debtorNumber is a valid customer.
      this.proxyDebtorNumber = debtorNumber;
      if (customerDetails.custJurisdiction) {
        this.localizationService.setRegion(customerDetails.custJurisdiction);
      } else {
        // TODO: Handle missing customer jurisdiction
      }
      if (customerDetails.custType !== 'DO') {
        this.proxyDebtorNumber = null;
        return 'Please use commercial proxy login for commercial accounts';
      }
      this.router.navigate(['my-account/details']);
      return '';
    }), catchError((exception: Response | any) => {
      // Request failed. Remove debtor number for proxy login
      this.proxyDebtorNumber = null;

      // Check for 404 status OR invalid account number message
      if (exception &&
        (exception.status === 404 ||
          (exception.error
            && exception.error.detail === 'No customer found for username/customerId were provided with CSR login.'))) {
        // Is invalid debtor number
        return of(`The customer id you entered doesn't exist`);
      }
      // Some other error occured. Can't verify if the debtor number is valid.
      return throwError(exception);
    }));
  }

}
