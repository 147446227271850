/**
 * Enviroment config for  production environment
 */
import { OSSEnvironment } from '../app/shared/models/environment/environment.model';
export const environment: OSSEnvironment = {
  production: true,
  backendUrl: 'https://my.sseairtricity.com/ws/oss_api/v1',
  identityUrl: '/ws/IdentityService/authenticate',
  baseUrl: 'https://my.sseairtricity.com',
  backendApiBaseUrl: '/ws/oss_api/v1',
  paymentBaseUrl: 'https://devdoss.sseairtricity.com/ws/PaymentService/v1',
  FAQUrl: 'https://www.sseairtricity.com/api/v1/KBArticle',
  ViewAllFAQUrl: {
    ROI: 'https://www.sseairtricity.com/ie/business/help-centre/understanding-your-bill/',
    NI: 'https://www.sseairtricity.com/uk/business/help-centre/understanding-your-bill/'
  },
  ViewAllMeterReadingFAQUrl:  {
    ROI: 'https://www.sseairtricity.com/ie/business/help-centre/meters/',
    NI: 'https://www.sseairtricity.com/uk/business/help-centre/meter-readings/'
  },
  ViewAllAccOverviewFAQUrl:  {
    ROI: 'https://www.sseairtricity.com/ie/business/help-centre/',
    NI: 'https://www.sseairtricity.com/uk/business/help-centre/'
  },
  //  TODO: Remove when Version1 API is ready
  oracleCdmUrl: 'https://einq-test.fa.em2.oraclecloud.com/crmRestApi/resources/latest',
  oracleCdmUsername: 'DummyUser',
  oracleCdmPassword: 'DummyPassword',
  billHoverTextUrl: 'https://www.sseairtricity.com/api/v1/BillHoverText',
  dataPortabilityBase: '/ws/CustomerExtracts/v1',
  api_version: 'v1',
  oss_comm_base: 'https://my.sseairtricity.com/oss_web',
  oss_comm_mobile: 'https://m.sseairtricity.com',
  APIM_BASE_URL: 'https://ossapi.sseairtricity.com',
  apiSrc: 'frontend',
  ocp_apim_sub_key: 'e0d373fb482c4d85b53616bc6c0ec6f5',
  nps_api_url: 'https://www.sseairtricity.com/api/v1/CustomerFeedback',
  apim_identity_service: '/IdentityService/v1/authenticate',
  debugMode: false,
  enableApiTrace: false,
  // ID Server Config [env specific]
  app_base_url: 'https://businessaccounts.sseairtricity.com',
  app_base_url_oss: 'https://account.sseairtricity.com',
  idsvr_base_url: 'https://id.ossapi.sseairtricity.com',
  domestic_proxy_url:'https://account.sseairtricity.com/my-account/proxy-login',
  idsClientId: 'ossResp',
  hits_tips_api_url: 'https://www.sseairtricity.com/api/v1/TipsItemDataObject'
};
