<!-- <div class="container d-flex" id="feedback-container-desktop">
    <button type="button" (click)="openNPSModal()" class="btn btn-medium btn-primary feedbackBtn" *ngIf="!customerAgent" id="feedbackButton">{{'Give us your feedback'|ossContent:'shared':'footer-feedback-button-text'}}</button>
</div> -->

<footer>
    <div class="container footercontainer"   >
        <div class="row">
            <!-- <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                <div class="card" id="about-sse">
                    <div class="card-body icon-body">
                        <h1 class="card-title">{{'About'|ossContent:'shared':'footer-about-title'}}</h1>
                        <p *ngIf="ROI" class="card-text">
                            {{'SSE Airtricity is a trading name of SSE Airtricity Limited which is a member of the SSE Group. The Registered Office of SSE Airtricity Limited is SSE Airtricity, Red Oak South, South County Business Park, Leopardstown, Dublin 18, D18 W688, Ireland.
                            Registered in the Republic of Ireland No. 317386.'|ossContent:'shared':'footer-about-content-roi'}}
                        </p>
                        <p *ngIf="NI" class="card-text">
                            {{'SSE Airtricity is a trading name of SSE Airtricity Energy Supply (NI) Limited which is a member of the SSE Group. The Registered Office of SSE Airtricity Energy Supply (NI) Limited is 3rd Floor Millennium House 19-25 Great Victoria Street Belfast Northern
                            Ireland BT2 7AQ Registered in Northern Ireland No. NI041956. '|ossContent:'shared':'footer-about-content-ni'}}
                        </p>
                        <p class="card-text aboutsection">© SSE Airtricity {{year}}</p>
                    </div>
                </div>
            </div> -->
            <div *ngIf="NI" class="col-lg-3 col-md-3 col-sm-2 col-xs-12">
                <div class="card">
                    <div class="card-body  icon-body">
                        <h1 class="card-title">{{'Business'|ossContent:'shared':'footer-information-title-3'}}</h1>
                        <ul class="info-links">
                            <li *ngFor="let item of footerLinks_gasAndElectricity_NI">
                                <a target="_blank" href="{{item.link}}">{{item.text}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div *ngIf="ROI" class="col-lg-3 col-md-3 col-sm-2 col-xs-12">
               
                <div class="card">
                    <div class="card-body  icon-body">
                        <h1 class="card-title">{{'Business'|ossContent:'shared':'footer-information-title-3'}}</h1>
                        <ul class="info-links">
                            <li *ngFor="let item of footerLinks_gasAndElectricity_ROI">
                                <a target="_blank" href="{{item.link}}">{{item.text}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div *ngIf="NI" class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div class="card" id="aboutCard">
                    <div class="card-body  icon-body">
                        <h1 class="card-title">{{'About SSE Airtricity'|ossContent:'shared':'footer-information-title-2'}}</h1>
                        <ul class="info-links">
                            <li *ngFor="let item of footerLinks_aboutSSEA_NI">
                                <a target="_blank" href="{{item.link}}">{{item.text}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div *ngIf="ROI" class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div class="card" id="aboutCard">
                    <div class="card-body  icon-body">
                        <h1 class="card-title">{{'About SSE Airtricity'|ossContent:'shared':'footer-information-title-2'}}</h1>
                        <ul class="info-links">
                            <li *ngFor="let item of footerLinks_aboutSSEA_ROI">
                                <a target="_blank" href="{{item.link}}">{{item.text}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>



            <div *ngIf="NI" class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div class="card">
                    <div class="card-body  icon-body">
                        <h1 class="card-title">{{'Information'|ossContent:'shared':'footer-information-title'}}</h1>
                        <ul class="info-links">
                            <li *ngFor="let item of footerLinks_NI">
                                <a target="_blank" href="{{item.link}}">{{item.text}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div *ngIf="ROI" class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div class="card">
                    <div class="card-body  icon-body">
                        <h1 class="card-title">{{'Information'|ossContent:'shared':'footer-information-title'}}</h1>
                        <ul class="info-links">
                            <li *ngFor="let item of footerLinks_ROI">
                                <a target="_blank" href="{{item.link}}">{{item.text}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- <div class="div-only-tablet">
                
            </div> -->

            <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12" id="warning">
            <h1 class="card-title div-only-mobile">Connect</h1>

                <div class="icon-body card-body" id="social-icons" >
                    <a href="https://www.facebook.com/sseairtricity" target="_blank" class="facebook-icon" title="SSE Airtricity on Facebook"></a>
                    <a href="https://www.twitter.com/sseairtricity" target="_blank" class="twitter-icon" title="SSE Airtricity on Twitter"></a>
                    <a href="http://www.youtube.com/c/sseairtricity" target="_blank" class="youtube-icon" title="SSE Airtricity on Youtube"></a>
                    <!-- <a href="https://www.linkedin.com/company/airtricity" target="_blank" class="linkedin-icon" title="SSE Airtricity on Linkedin"></a> -->
                    <a href="https://www.instagram.com/sseairtricity" target="_blank" class="instagram-icon" title="SSE Airtricity on Instagram"></a>

                </div>
                <div *ngIf="ROI" class="card">
                    <div class="card-body card-text " id="feedback-part">
                        <p>{{'If you smell gas you should contact Gas Networks Ireland immediately on '|ossContent:'shared': 'footer-feedback-content-p1-sec1'}}<b>{{'1800 20 50 50'|ossContent:'shared': 'footer-feedback-content-p1-sec2'}}</b>{{' (24 hours).'|ossContent:'shared':
                            'footer-feedback-content-p1-sec3'}}</p>
                        <!-- <p class="feedback_Sec_part">{{'^Charged at LoCall rate. Rates from landline and mobile companies may vary so please check with your operator.'|ossContent:'shared': 'footer-feedback-content-p2-sec'}} -->
                        <!-- </p> -->
                    </div>
                </div>
                <div *ngIf="NI" class="card">
                    <div class="card-body card-text " id="feedback-part">
                        <p>If you have a power cut, you should call Northern Ireland Electricity Networks Limited on <b>03457 643 643</b> (24 hours)</p>
                        <p class="feedback_Sec_part">If you smell gas phone the 24-hour gas emergency service on <b>0800 002 001</b></p>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div *ngIf="ROI" class="fbButtonROI" id="feedbackButton" #footerBack>
            <button type="button" (click)="openNPSModal()" class="btn btn-medium btn-primary feedbackBtn" *ngIf="!customerAgent && !isServiceError" id="feedbackNewBtn">{{'How is your online experience with SSE Airtricity?'|ossContent:'shared':'footer-feedback-button-text'}}</button>
        </div>
        <div *ngIf="NI" class="fbButtonNI" id="feedbackButton" #footerBack>
            <button type="button" (click)="openNPSModal()" class="btn btn-medium btn-primary feedbackBtn" *ngIf="!customerAgent && !isServiceError" id="feedbackNewBtn">{{'How is your online experience with SSE Airtricity?'|ossContent:'shared':'footer-feedback-button-text'}}</button>
        </div> -->
    </div>

</footer>

<div class="visuallyhidden">
    load oss font
</div>
<oss-nps-modal *ngIf="npsModalOpen" [isModalShown]="npsModalOpen" [feedbackDomain]="npsModalDomain" (hide)="closeNPSModal()">
</oss-nps-modal>

<!-- <div class="nps-widget" [ngClass]="{'is-visible': npsWidgetState.isVisible}" id="c-nps-widget">
    <button aria-label="Close" type="button" class="close" (click)="closeNPSWidget()" href="#footerBack" >
    <span aria-hidden="true">×</span>
  </button>
    <a href="javascript:;"> -->
        <!-- (click)="openNPSModal(npsWidgetState.currentDomain)" -->
        <!-- <div class="inner">
            <span>How was this experience?</span>
        </div>
        <div class="circle"></div>
    </a>
</div> -->