import { Injectable } from '@angular/core';
import { OSSAppConfig } from '../../core/config/app-config';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map , catchError, finalize } from 'rxjs/operators';

@Injectable()
export class FaqService {

  constructor(private http: HttpClient) { }
  /**
   * Method to fetch top 5 FAQs from CMS
   *
   * @param {any} jurisdiction
   * @param {any} page
   * @param {any} tag
   * @returns {Observable} faqData
   * @memberof FaqService
   */
  public getFAQ(jurisdiction, page, tag) {
    const url = OSSAppConfig.FAQ_URL;  //need to be uncommented
    // const url = 'https://staging.sseairtricity.com/api/v1/KBArticle';
    const params: any = {
      Location: jurisdiction,
      Page: page,
      Tag: tag,
      limit: 5,
      sort: 'Importance',
      dir: 'desc'
    };
    const options = {
      params,
      responseType: 'text' as 'json'
    };
    return this.http.get<any>(url, options)
      .pipe(map((data: any) => {
        return data;
      })
      , catchError((exception) => throwError(exception))
      , finalize(() => {

      }));
  }
}
