import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../core/auth/auth.service';
import { OSSOpenIDConfiguration } from '../../../core/config/oss-oidc-config';
@Component({
  selector: 'oss-oidc-signout',
  templateUrl: './oidc-signout.component.html',
  styles: []
})
export class OidcSignoutComponent implements OnInit {
  // tslint:disable-next-line: ban-types
  role: string;
  jur: string;
  redirectHome: any;
  constructor(private router: Router,
              private route: ActivatedRoute,
              private auth: AuthService,
              private ossOidcConfig: OSSOpenIDConfiguration
  ) {
    // tslint:disable-next-line: no-string-literal
    this.redirectHome = this.ossOidcConfig.post_logout_uris.ROI['COM'];
    if (this.auth.getState() !== '') {
      const postLogoutState = JSON.parse(this.auth.getState());
      if (postLogoutState.flow === 'com_redirect') {
        this.auth.redirectCommSite();
      } else if (postLogoutState.flow === 'timeout') {
        this.auth.setCustomRequestParameters({role: postLogoutState.role, jur: postLogoutState.jur, mode: 'timeout'});
        localStorage.setItem('post_login_uri', 'my-account/acc-overview');
        this.auth.setState('');
        this.router.navigate(['/signin-redirect']);
      } else {
        this.redirectHome = this.ossOidcConfig.post_logout_uris[postLogoutState.jur][postLogoutState.role];
        this.auth.clearStorage();
        window.location.href = this.redirectHome;
      }
    } else {
      if(localStorage.getItem('flow') === 'std'){
        const postLogoutState = JSON.parse(localStorage.getItem('logoutState'));
        this.redirectHome = this.ossOidcConfig.post_logout_uris[postLogoutState.jur][postLogoutState.role];
        this.auth.clearStorage();
        window.location.href = this.redirectHome;
      }else{
        this.router.navigate(['/signin-redirect']);

      }
    }
  }

  ngOnInit() {
  }

}
