export class Region {
    name: string;
    code: string;
    jurisdiction: 'ROI' | 'NI';
    locale: string;
    currencyCode: 'EUR' | 'GBP';

    constructor(name: string, code: string, jurisdiction: 'ROI' | 'NI', locale?: string, currencyCode?: 'EUR' | 'GBP') {
        this.name = name;
        this.code = code;
        this.jurisdiction = jurisdiction;
        this.locale = locale || '';
        if (currencyCode) {
            this.currencyCode = currencyCode;
        } else {
            switch (jurisdiction) {
                case 'ROI': {
                    this.currencyCode = 'EUR';
                    break;
                }
                case 'NI': {
                    this.currencyCode = 'GBP';
                    break;
                }
            }
        }
    }
}
