import { ProxyLoginService } from '../services/proxy-login.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
@Injectable()
export class LoginGuard implements CanActivate {
    constructor(
        private router: Router,
        private proxyLoginService: ProxyLoginService,
        private auth: AuthService
    ) { }
    canActivate() {
        const isAuthorized: any = this.auth.isAuthorized();
        if (isAuthorized) {
        //    if (this.proxyLoginService.isCustomerAgent) {
        //        this.router.navigate(['/my-account/proxy-login']);
        //    } else {
                this.router.navigate(['/my-account/acc-overview']);
        //    }
        }

        return true;
    }
}
