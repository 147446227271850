import { forkJoin } from 'rxjs';
import { LocalDataService } from '../local-data/local-data.service';
import { County } from '../../models/county.model';
import { Country } from '../../models/country.model';
import { Injectable } from '@angular/core';

@Injectable()
export class AddressParserService {

  countryList: Country[];
  countyListNI: County[];
  countyListROI: County[];

  constructor(
    private localDataService: LocalDataService
  ) {
    const countryList$ = this.localDataService.getCountryList();
    const countyList$ = this.localDataService.getCountyList();
    forkJoin(countryList$, countyList$).subscribe(([countryList, countyList]) => {
      this.countryList = countryList;
      this.countyListNI = countyList.NI;
      this.countyListROI = countyList.ROI;
    }, error => {
      // TODO: List fetch failed. Handle
    }, () => {

    });
  }

  parseTown(address: string | undefined): [Country | undefined, County | undefined, string] {
    let country: Country | undefined;
    let county: County | undefined;
    let town = '';
    let splitList = (address || '').split(',').reverse();
    splitList = splitList.map(text => text.trim());

    if (address && splitList.length > 0) {
      [country, splitList] = this.matchCountry(splitList, address);
      if (!!country) {
        // Valid country
        if (splitList.length > 0) {
          // Check for a valid county if there are more elements in address
          switch (country.displayName) {
            case 'Republic of Ireland': {
              county = this.matchCounty(splitList[0], this.countyListROI);
              break;
            }
            case 'Northern Ireland': {
              county = this.matchCounty(splitList[0], this.countyListNI);
              break;
            }
            // Need not match county for other countries
          }
          if (!!county) {
            // If county is matched, remove it from splitList
            splitList.splice(0, 1);
          }
        }
      } else {
        // No valid country found. Treat last part of address as county
        // Try to match with ROI county list
        county = this.matchCounty(splitList[0], this.countyListROI);
        if (!!county) {
          // Valid ROI county
          country = this.countryList.find(c => c.displayName === 'Republic of Ireland');
          splitList.splice(0, 1);
        } else {
          // Couldn't match with any county in ROI. Try to match with NI county list
          county = this.matchCounty(splitList[0], this.countyListNI);
          if (!!county) {
            // Valid NI county
            country = this.countryList.find(c => c.displayName === 'Northern Ireland');
            splitList.splice(0, 1);
          }
        }
      }
      town = splitList.reverse().join(', ');
    }
    return [country, county, town];
  }

  matchCountry(addressComponentsReversed: string[], completeAddress: string): [Country | undefined, string[]] {
    let country: Country | undefined;
    if (addressComponentsReversed[0].toUpperCase() === 'REPUBLIC OF IRELAND'
      || addressComponentsReversed[0].toUpperCase() === 'ROI') {
      country = this.countryList.find(c => c.displayName === 'Republic of Ireland');
      addressComponentsReversed.splice(0, 1);
    } else if (addressComponentsReversed[0].toUpperCase() === 'NORTHERN IRELAND'
      || addressComponentsReversed[0].toUpperCase() === 'NI') {
      country = this.countryList.find(c => c.displayName === 'Northern Ireland');
      addressComponentsReversed.splice(0, 1);
    } else {
      let matchingCountryFound = false;
      // Try to match address with country list
      this.countryList.forEach(countryListItem => {
        if (!matchingCountryFound && completeAddress.toUpperCase().includes(countryListItem.displayName.toUpperCase())) {
          let isCountryMatch = true;
          let countryLength = 0;
          countryListItem.displayName
            .split(',')
            .reverse()
            .map(text => text.trim())
            .forEach((countryNameSplit, index) => {
              countryLength++;
              if (countryNameSplit.toUpperCase() !== addressComponentsReversed[index].toUpperCase()) {
                isCountryMatch = false;
              }
            });
          if (isCountryMatch) {
            country = countryListItem;
            addressComponentsReversed.splice(0, countryLength);
            matchingCountryFound = true;
          }
        }
      });
    }
    return [country, addressComponentsReversed];
  }

  matchCounty(countyName: string, countyList: County[]): County | undefined {
    countyName = this.standardizeCountyName(countyName);
    const matchingCounty = countyList.find(countyListItem => countyListItem.displayName.toUpperCase() === countyName.toUpperCase());
    if (!!matchingCounty) {
      return matchingCounty;
    } else {
      return undefined;
    }
  }

  standardizeCountyName(county: string): string {
    return county.replace(/^(?:[Cc][Oo](?:\.)? )(.*)/, '$1');
  }

  /**
   * Return phone number in the format {countryCode}|{phoneNumber}
   * Return empty string if invalid
   *
   * @param {string} phoneNumber
   * @param {Country} country
   * @returns {string}
   * @memberof AddressParserService
   */
  parsePhoneNumber(phoneNumber: string, country: Country): string {
    // Only digits, + and space is allowed in phone number
    phoneNumber = phoneNumber.replace(/[^\d\+ ]+/, '').trim();
    if (!phoneNumber) {
      return '';
    }
    if (!phoneNumber.startsWith('+')) {
      // Local phone number. Add country code from country input
      const countryCode = country.diallingCode
        // Use first code if a country has multiple codes. Multiple codes differ only in area code
        .split(' ')[0]
        // Remove area code
        .split('-')[0];
      // Remove leading zeroes
      phoneNumber = phoneNumber.replace(/^0+/, '');
      return `${countryCode}|${phoneNumber}`;
    } else {
      // Starts with +. Verify country code
      const tempPhoneNumber = phoneNumber
        // Replace all non-numeric characters
        .replace(/\D/g, '')
        // Replace leading zeroes
        .replace(/^0+/, '');
      const matchingDiallingCode = country.diallingCode.split(' ').find(diallingCode => {
        // Remove any non-numeric characters
        diallingCode = diallingCode.replace(/\D/g, '');
        return tempPhoneNumber.startsWith(diallingCode);
      });
      if (matchingDiallingCode) {
        const diallingCodeWithoutAreaCode = matchingDiallingCode.split('-')[0];
        return `${diallingCodeWithoutAreaCode}|${tempPhoneNumber.slice(diallingCodeWithoutAreaCode.length)}`;
      }
      return phoneNumber;
    }
  }
}
