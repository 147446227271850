import { LocalizationService } from '../../core/services/localization/localization.service';
import { ProxyLoginService } from '../../core/services/proxy-login.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { HttpOptions } from '../../core/services/http-options.service';
import { OSSAppConfig } from '../../core/config/app-config';
import { LoaderService } from '../components/loader/loader.service';
import { Customer } from '../models/customer/customer.model';
import { CacheManager } from '../../core/services/cache-manager.service';

@Injectable()
export class CustomerService {

  private apiGroupURL = OSSAppConfig.API_GROUP[OSSAppConfig.API_SRC].customerdetails;
  private accNo: any;
  private custRole: any;
  constructor(
    private http: HttpClient,
    private localizationService: LocalizationService,
    public proxyLoginService: ProxyLoginService,
    private cacheManager: CacheManager
  ) { }
  public getCustomer(accountNumber: string): Observable<Customer> {
    //const customerDetailsCache = this.cacheManager.quickRetrieve('customerDetails');
    //if (customerDetailsCache) {
     // return Observable.create(observer => {
        //observer.next(customerDetailsCache);
        //observer.complete();
      //});
    //}
    
    const url = `${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL}/${accountNumber}`;
    return this.http.get<Customer>(url)
      .pipe(map((customerDetails: Customer) => {
        if (customerDetails.custJurisdiction) {
          this.localizationService.setRegion(customerDetails.custJurisdiction);
        } else {
          // TODO: Handle missing customer jurisdiction
        }
        this.cacheManager.quickSave('customerDetails', customerDetails);
        return customerDetails;
      }), catchError((exception: Response | any) => throwError(exception))
      , finalize(() => {

      }));
    
    
    // const url = `${OSSAppConfig.jsonBasePath}/mocks/customerInfo.json`;

    
  }

  public getAccountNo() {
    if (this.proxyLoginService.isCustomerAgent) {
      if (this.proxyLoginService.proxyDebtorNumber !== undefined && 
        this.proxyLoginService.proxyDebtorNumber !== null) {
        return this.proxyLoginService.proxyDebtorNumber;
      } else if (localStorage.getItem('proxyCMCDebtor') !== undefined &&
        localStorage.getItem('proxyCMCDebtor') !== null) {
        return localStorage.getItem('proxyCMCDebtor');
      }
    } else {
      return localStorage.getItem('debtor');
    }
  }

  public updateContactDetailsEmail(accountNumber: string, formDataEmail) {
    this.cacheManager.quickRemove('customerDetails');
    const url = `${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL}/${accountNumber}/contact-details/email`;
    return this.http.put(url, formDataEmail)
      .pipe(map((response) => {
        return response;
      }), catchError((exception: Response | any) => throwError(exception))
      , finalize(() => {

      }));
  }

  public updateContactDetailsPhone(accountNumber: string, formDataPhone) {
    this.cacheManager.quickRemove('customerDetails');
    const url = `${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL}/${accountNumber}/contact-details/phone-numbers`;
    return this.http.put(url, formDataPhone)
      .pipe(map((response) => {
        return response;
      }), catchError((exception: Response | any) => throwError(exception))
      , finalize(() => {

      }));
  }
  public updateContactDetails(body) {
    this.cacheManager.quickRemove('customerDetails');
    const url = `${OSSAppConfig.DATA_API_BASE}/customer-details/v1/contact-details/multi-debtor`;
    return this.http.put(url, body)
      .pipe(map((response) => {
        return response;
      }), catchError((exception: Response | any) => throwError(exception))
      , finalize(() => {

      }));
  }

  setAccNo(accountNumber) {
    this.accNo = accountNumber;
  }

  getAccNo() {
    return this.accNo;
  }

  setCustRole(customerRole) {
    this.custRole = customerRole;
  }

  getCustRole() {
    return this.custRole;
  }

}
