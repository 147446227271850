import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { UserIdleModule } from 'angular-user-idle';
import { ModalModule } from 'ngx-bootstrap/modal';

import { AuthService } from './auth/auth.service';
import { OSSAppConfig } from './config/app-config';
import { AuthGuard } from './guards/auth-guard.service';
import { LoginGuard } from './guards/login-guard.service';
import { ProxyLoginGuard } from './guards/proxy-login.guard';
import { CacheManager } from './services/cache-manager.service';
import { DataHelper } from './services/data-helper.service';
import { HttpErrorHandler } from './services/error-handler.service';
import { HttpOptions } from './services/http-options.service';
import { LocalizationService } from './services/localization/localization.service';
import { NpsModalService } from './services/nps-modal.service';
import { OssContentService } from './services/oss-content/oss-content.service';
import { ProxyLoginService } from './services/proxy-login.service';
import { ViewportService } from './services/viewport/viewport.service';
import { RouterModule } from '@angular/router';
import { UrlEncryptorService } from '../shared/services/url-encryptor.service';


@NgModule({
  imports: [
    CommonModule,
    ModalModule.forRoot(), RouterModule,
    UserIdleModule.forRoot({idle: 1800, timeout: 5, ping: 120}),
    HttpClientModule
  ],
  declarations: [],

  providers: [
    AuthService,
    AuthGuard,
    LoginGuard,
    OSSAppConfig,
    LocalizationService,
    DataHelper,
    CacheManager,
    UrlEncryptorService,
    ViewportService,
    ProxyLoginGuard,
    ProxyLoginService,
    OssContentService,
    NpsModalService,
    HttpErrorHandler,
    HttpOptions
  ]
})
export class CoreModule { }
