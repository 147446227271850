import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { OSSAppConfig } from '../config/app-config';
/**
 * Common service to set http headers and params for each http request
 *
 * @export
 * @class HttpOptions
 */
@Injectable()
export class HttpOptions {

    /**
     * Method to set http headers and params
     * @param headers
     * @param params
     */
    public set( params: HttpParams | any, headers?: any) {
        const httpOptions = {
            headers: new HttpHeaders({}),
            observe: 'response' as 'body',
            params: new HttpParams({}),

        };
        if (headers) {
            httpOptions.headers = headers;
        }
        httpOptions.headers.append('Access-Control-Allow-Origin', '*');
        httpOptions.headers.append('Cache-Control', 'no-cache');
        httpOptions.headers.append('Pragma', 'no-cache');
        httpOptions.headers.append('Content-Type', 'application/json');
        if (params) {
            httpOptions.params = this.setQueryParams(params);
        }
        return httpOptions;
    }
    /**
     * Method to set query params from of array of parameters
     * @private
     * @param {*} rawParams
     * @returns {HttpParams}
     * @memberof HttpOptions
     */
    public setQueryParams(rawParams: any): HttpParams {
        let queryParams: HttpParams = new HttpParams();
        for (const key in rawParams) {
            if (rawParams.hasOwnProperty(key)) {
                const val = rawParams[key];
                queryParams = queryParams.append(key, val);
            }
        }
        return queryParams;
    }
}
