import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterAccountNumber'
})
export class FilterAccountNumberPipe implements PipeTransform {

    transform(items: any[], searchAccountText: string, filterType: string) {

        searchAccountText = searchAccountText ? searchAccountText : '';

        if (!items) { return []; }

        if (!searchAccountText) {
            return items;
        }

        return items.filter(acc => {

            let accDetails = '';
            if (acc.debtornum) {
                accDetails = accDetails.concat(acc.debtornum);
            }
            // let otherFilter = this.getFilteredAccountByOtherFilters(acc.premises, searchAccountText);

            // tslint:disable-next-line: max-line-length
            return !searchAccountText || this.getFilteredAccountByOtherFilters(acc.premises, searchAccountText) || accDetails.toUpperCase().indexOf(searchAccountText.toUpperCase()) > -1;


        });
    }



    getFilteredAccountByOtherFilters(items, searchAccountText) {
        let store = [];
        store =  items.filter(premise => {


            let premiseDetails = '';
            if (premise.address) {
                // Object.setPrototypeOf(premise.address, Address.prototype);
                premiseDetails = premiseDetails.concat(premise.combinedAddress);
            }
            // if (!searchAccountText || premiseDetails.toUpperCase().indexOf(searchAccountText.toUpperCase()) > -1) {
            //     return !searchAccountText || premiseDetails.toUpperCase().indexOf(searchAccountText.toUpperCase()) > -1;
            // }
            if ((premise.mprn && premise.mprn !== null && premise.mprn !== undefined)) {
                //  if (!searchAccountText || premise.mprn.indexOf(searchAccountText) > -1) {
                premiseDetails = premiseDetails.concat(premise.mprn);
                //  }
            }
            if ((premise.gprn && premise.gprn !== null && premise.gprn !== undefined)) {
                // if (!searchAccountText || premise.gprn.indexOf(searchAccountText) > -1) {
                premiseDetails = premiseDetails.concat(premise.gprn);
                // }
            }
            return !searchAccountText || premiseDetails.toUpperCase().indexOf(searchAccountText.toUpperCase()) > -1;


        });

        return store.length > 0 ? true : false ;
    }

}
