import { Component, OnInit, Renderer2, HostListener } from '@angular/core';
import { Observable } from 'rxjs';
import { OidcClientNotification, OidcSecurityService } from 'angular-auth-oidc-client';
import { AuthService } from './core/auth/auth.service';
import { RouterEvent, Router, ActivatedRoute, NavigationCancel, NavigationError, NavigationStart, NavigationEnd } from '@angular/router';
import { OssContentService } from './core/services/oss-content/oss-content.service';
import { ViewportService } from './core/services/viewport/viewport.service';
import { CacheManager } from './core/services/cache-manager.service';
import { LoaderService } from './shared/components/loader/loader.service';
import { LocalizationService } from './core/services/localization/localization.service';
import { UserIdleService } from 'angular-user-idle';
import { Location } from '@angular/common';
import { OSSAppConfig } from './core/config/app-config';
import { ProxyLoginService } from './core/services/proxy-login.service';
import { UserDataCommon } from './shared/class/user-data-common';

@Component({
  selector: 'oss-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  hideHeader = false;
  title = 'wholesale-beossr';
  userDataChanged$: Observable<OidcClientNotification<any>>;
  userData$: Observable<any>;
  isAuthenticated$: Observable<boolean>;
  checkSessionChanged$: Observable<boolean>;
  region: any;
  constructor(public oidcSecurityService: OidcSecurityService, private auth: AuthService,
    private userIdle: UserIdleService,
    private renderer: Renderer2,
    private localizationService: LocalizationService,
    private loader: LoaderService,
    private cacheManager: CacheManager,
    private viewportService: ViewportService,
    private ossContentService: OssContentService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private proxyLoginService: ProxyLoginService
  ) {

    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });

    // localStorage.setItem('debtor', '277940');
    // localStorage.setItem('_isAuthorized', 'true');
    // tslint:disable-next-line: max-line-length
    // localStorage.setItem('userData', '{"role":"CMC","jur":"ROI","debtor":"277940","custtype":"DO","email":"anjali@gmail.com","sub":"DF277940"}');



    this.route.queryParams.subscribe(params => {
      // Set region based on url parameters if there is no active user session
      // For logged-in users, region will be set based on the customer-details API
      if (params && params.nt && !this.auth.isAuthorized()) {
        this.localizationService.setRegion(params.nt);
      }
    });

    this.viewportService.setDisplayMode(window.innerWidth);
    this.auth.idleTimer.subscribe(
      message => {
        if (message && message === 'start') {
          this.startWatching();
        } else {
          this.stopWatching();
        }
        return message;
      });

    // Handle browser reload
    const isOnIOS = navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i);
    const eventName = isOnIOS ? 'pagehide' : 'beforeunload';
    /**
     * Call back on reload event
     */
    window.addEventListener(eventName, (event) => {
      this.handleBeforeReload();
    });
    const isAuthorized: any = this.auth.isAuthorized();
    const isLoggedOutPage = OSSAppConfig.loggedOutJourneys.some(loggedOutPath => {
      return this.location.path().startsWith(loggedOutPath);
    });
    if (isAuthorized) {
      this.handleAfterReload();
    } else {

      if (isLoggedOutPage) {
        this.userIdle.stopWatching();
      } else {
        this.checkOidcSetup(); // to be uncommented

      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.viewportService.setDisplayMode(event.target.innerWidth);
  }
  /**
   * @returns void
   */
  stop(): void {
    this.userIdle.stopTimer();
  }
  /**
   * @returns void
   */
  stopWatching(): void {
    console.log('Idle Timer Stopped');
    this.userIdle.stopWatching();
  }
  /**
   * @returns void
   */
  startWatching(): void {
    console.log('Idle Timer Started');
    this.userIdle.startWatching();
  }
  /**
   * Restarts app component
   */
  restart(): void {
    this.userIdle.resetTimer();
  }
  /**
   * Updates header
   * @param component
   */
  updateHeader(component): void {
    this.hideHeader = component.hideHeader ? true : false;
  }
  /**
   * @returns void
   */
  updateBackground(component): void {
    if (component.hideBackground) {
      this.renderer.removeClass(document.body, 'bg-image');
    } else {
      this.renderer.addClass(document.body, 'bg-image');
    }
  }
  /**
   * @returns void
   */
  updateView(component): void {
    this.updateHeader(component);
    this.updateBackground(component);
  }
  /**
   * @returns void
   */
  handleBeforeReload(): void {
    console.log('Before Reload');
    // tslint:disable-next-line: jsdoc-format
    /** Save Cache if any **/
    this.cacheManager.saveSession();
  }
  /**
   * @returns void
   */
  handleAfterReload(): void {
    console.log('After Reload');
    try {
      // tslint:disable-next-line: jsdoc-format
      /** Recover Cached Data if any **/
      this.cacheManager.restoreSession();
      this.localizationService.restoreRegionData();
    } catch (e) {
      if (this.proxyLoginService.isCustomerAgent) {
        localStorage.clear();
      }
    }
  }
  // Shows and hides the loading spinner during RouterEvent changes
  /**
   * @returns void
   */
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loader.start();
      window.scrollTo(0, 0);
    }
    if (event instanceof NavigationEnd) {
      localStorage.removeItem('chunkFailed');
      setTimeout(() => {
        this.loader.stop();
      }, 200); // changing from 500 to 200
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.loader.stop();
    }
    if (event instanceof NavigationError) {
      if (event && event.error && event.error.message) {
        const errorPattern = /Loading chunk .* failed/;
        if (errorPattern.test(event.error.message)) {
          if (localStorage.getItem('chunkFailed')) {
            // Consecutive chunk failures on reload
            this.router.navigate(['my-account', 'service-error']);
          } else {
            localStorage.setItem('chunkFailed', 'true');
            this.cacheManager.backupQuickCache();
            this.location.go(event.url);
            window.location.reload();
          }
        }
      }
      this.loader.stop();
    }
  }

  /**
   * Checks oidc setup
   */
  checkOidcSetup(): void {
    this.userData$ = this.oidcSecurityService.userData$;
    this.isAuthenticated$ = this.oidcSecurityService.isAuthenticated$;
    this.checkSessionChanged$ = this.oidcSecurityService.checkSessionChanged$;
    this.getTokenData();
    this.oidcSecurityService.checkAuth().subscribe((isAuthenticated) => {
      //   localStorage.setItem('_isAuthorized', 'true');
      if (isAuthenticated) {
        this.region = JSON.parse(localStorage.getItem('userData')).jur;
        this.localizationService.setRegion(this.region);
        this.isAuthenticated$.subscribe((data) => {
          if (data) {
            localStorage.setItem('_isAuthorized', 'true');
            this.auth.onAuthorizationResultComplete(data);
          } else {
            const flow = localStorage.getItem('flow') || null;
            this.auth.logout(flow);
          }
        });

      } else {
        this.auth.onAuthorizationResultComplete(isAuthenticated);
      }
    });
  }

  private getTokenData() {
    let debtor_val = '';
    let sub_val = '';
    if (window.location.hash) {
      this.loader.start();
      const decodedHash: string = decodeURIComponent(window.location.hash).substr(1);
      if (decodedHash.indexOf('id_token') !== -1) {
        var token_id = decodedHash.split('&access_token')[0];
        var token = token_id.split('=')[1];
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        const pass_value = JSON.parse(jsonPayload).password;
        sub_val = JSON.parse(jsonPayload).sub;
        debtor_val = JSON.parse(jsonPayload).debtor;
        const _role = JSON.parse(jsonPayload).role;
        if (_role === 'CMC' && pass_value === 'old') {
          localStorage.setItem('passValue', this.auth.encryptTxt(pass_value));
          localStorage.setItem('post_login_uri', '/my-account/update-password');
        }
      }
    }
  }

  ngOnInit() {

    this.loader.start();
    // Initially set as stopWatching;
    this.stopWatching();
    const isAuthorized: any = this.auth.isAuthorized();
    if (isAuthorized) {
      this.startWatching();
    }
    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe(count => {
      // Do not logout the user if already in logged-out journey
      const isLoggedOutPage = OSSAppConfig.loggedOutJourneys.some(loggedOutPath => {
        return this.location.path().startsWith(loggedOutPath);
      });
      if (isLoggedOutPage) {
        this.userIdle.stopWatching();
      }
      if (count) {
        // console.log(count);
      }
    });

    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => {
      this.auth.logout('timeout');
      console.log('timeout');
      setTimeout(() => {
        this.auth.setTimeoutMessage('show');
      }, 200);

    });

    if (!this.ossContentService.ossContentData || !this.ossContentService.ossRedirectUrls) {
      // TODO: Couldn't load oss contents from local json. Handle
    }
  }
}


