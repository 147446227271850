import { ProxyLoginService } from '../services/proxy-login.service';
import { Subject, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription, of } from 'rxjs';
import { HttpOptions } from '../services/http-options.service';
import { OSSAppConfig } from '../config/app-config';
import { CacheManager } from '../services/cache-manager.service';
import * as CryptoJS from 'crypto-js';
import { OidcSecurityService, AuthorizationResult, OpenIdConfiguration, AuthWellKnownEndpoints } from 'angular-auth-oidc-client';
import { map, catchError, finalize } from 'rxjs/operators';
import { LoaderService } from '../../shared/components/loader/loader.service';
import { OSSOpenIDConfiguration } from '../config/oss-oidc-config';
import { LocalizationService } from '../services/localization/localization.service';
import { BillingService } from '../../shared/services/billing.service';
import { environment } from '../../../environments/environment';






/**
 *
 *
 * @export
 * @class AuthService
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public isLoggedIn = false;
  public idleTimer = new Subject<string>();
  public onTimeout = new Subject<string>();
  public loggedInRole = new Subject<string>();
  //public salt: any = 'oss-responsive';
  public salt: any = OSSAppConfig.OCP_API_SUB_KEY;
  public landingPageUrl = '/my-account/acc-overview';

  // tslint:disable-next-line: ban-types
  isUserAuthorized: Boolean = false;
  userData: any = null;
  currentRegion: any;
  isFinalizedAccount = false;
  constructor(
    private http: HttpClient,
    private router: Router,
    // private httpOptions: HttpOptions,
    private cacheManager: CacheManager,
    private proxyLoginService: ProxyLoginService,
    public _securityService: OidcSecurityService,
    public billingService: BillingService,
    //   private loader: LoaderService,
    private oidcConfig: OSSOpenIDConfiguration,
    // private authConfig: OpenIdConfiguration,
    // private authWellKnownEndpoints: AuthWellKnownEndpoints,
    private locale: LocalizationService
  ) {
    if (window.location.href.includes('my-account/acc-overview?debtorNumber=')) {
      const val = window.location.href.split('?')[1].split('=')[1];
      localStorage.setItem('proxyCMCDebtor', val);
    } else if (window.location.href.includes('my-account/acc-overview?uname=')) {
      const val = window.location.href.split('?')[1].split('=')[1];
      localStorage.setItem('superuserName', val);
    }
    if (this.locale.region$ !== undefined) {
      this.locale.region$.subscribe(region => {
        this.currentRegion = region;
      });
    }
  }
  /**
   * get customer details
   * @returns Observable
   */
  public getCustomer() {
    const customerAPIURL = OSSAppConfig.API_GROUP[OSSAppConfig.API_SRC].customerdetails;
    const url = `${OSSAppConfig.DATA_API_BASE}/${customerAPIURL}/${localStorage.getItem('debtor')}`;
    const options = {
      responseType: 'text' as 'json'
    };
    return this.http.get(url, options)
      .pipe(map((response: any) => {
        return response;
      }), catchError((exception: Response | any) => throwError(exception))
        , finalize(() => {
        }));
  }
  /**
   * Redirect to commercial site if user role is commercial
   *
   * @memberof LoginComponent
   */
  redirectCommSite() {
    window.location.href = `${OSSAppConfig.OSS_COMM_BASE}${OSSAppConfig.OSS_COMM_DASHBOARD}`;
  }
  /**
   * Logout OSSR before redirecting to Commercial site
   *
   * @memberof AuthService
   */
  commercialHandover() {
    this.pushCommSiteToken()
      .subscribe(
        data => {
          this.logout('com_redirect');
        }
        , error => {
          this.logout('com_redirect');
        });
  }
  /**
   * Service to set token in OSS Legacy to redirect businss users to OSSL
   *
   * @returns {Observable<any>}
   * @memberof AuthService
   */
  public pushCommSiteToken(): Observable<any> {
    const url = `${OSSAppConfig.OSS_COMM_BASE}${OSSAppConfig.OSS_COMM_TOKEN_URL}`;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.getAccessToken()
    });
    return this.http.get<any>(url, { headers, withCredentials: true, responseType: 'text' as 'json' })
      .pipe(map((response: any) => {
        return response;
      }), catchError((exception: Response | any) => throwError(exception))
        , finalize(() => {
        }));
  }
  /**
   * Method to clear client side storage
   *
   * @memberof AuthService
   */
  public clearStorage() {
    localStorage.clear();
    sessionStorage.clear();
    this.cacheManager.clear();
    this.eraseCookie('token');
    this._securityService.logoff();

  }
  /**
   * Function to trigger Idle Timer
   */
  public idleWatching(message: string) {
    this.idleTimer.next(message);
  }

  public setTimeoutMessage(message: string) {
    this.onTimeout.next(message);
  }
  public getUsernameByCode(code: string): Observable<any> {
    const acMaintURL = OSSAppConfig.API_GROUP[OSSAppConfig.API_SRC].accountmaintenance;
    const url = `${OSSAppConfig.DATA_API_BASE}/${acMaintURL}/verification-code/${code}/username`;
    return this.http.get<any>(url)
      .pipe(map((response: any) => {
        return response;
      }), catchError((exception: Response | any) => throwError(exception))
        , finalize(() => {

        }));
  }
  setCookie(name, value, days) {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = '; expires= ' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  }
  getCookie(name) {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  }
  eraseCookie(name) {
    document.cookie = name + '=; Max-Age=-99999999;';
  }
  encrypt(data: any): string {
    // Encrypt
    const encryptedData: string = CryptoJS.AES.encrypt(data, this.salt);
    return encryptedData;
  }
  decrypt(data: any) {
    if (data) {
      CryptoJS.AES.decrypt(data, this.salt);
    }
  }

  encryptTxt(data: any): string {
    // Encrypt
    const encryptedData: string = CryptoJS.AES.encrypt(data, this.salt).toString();
    return encryptedData;
  }
  decryptTxt(data: any) {
    if (data) {
      const bytes = CryptoJS.AES.decrypt(data, this.salt);
      return bytes.toString(CryptoJS.enc.Utf8);
    }
  }


  // OIDC facade methods
  /**
   * retrieve access token
   *
   * @returns <String>
   * @memberof AuthService
   */
  public getAccessToken() {

    return this._securityService.getToken();

  }
  /**
   * returns id token
   *
   * @returns <String>
   * @memberof AuthService
   */
  public getIdToken() {
    return this._securityService.getIdToken();
  }
  /**
   * Check if authorized
   *
   * @returns {Boolean}
   * @memberof AuthService
   */
  // tslint:disable-next-line: ban-types
  public isAuthorized(): Boolean {
    this._securityService.isAuthenticated$.subscribe(
      (isAuthorized: boolean) => {
        if (isAuthorized) {
          this.isUserAuthorized = isAuthorized;
          this.setUserData();  // to be uncommented
        }
      });
    return this.isUserAuthorized;
  }
  public authorize() {
    const idSvrState: any = {
      jur: this.currentRegion?.code === 'NI' ? 'NI' : 'ROI',
      role: this.getRoleCode()
    };
    if (localStorage.getItem('flow') === 'timeout') {
      idSvrState['mode'] = 'timeout';
      localStorage.setItem('flow', 'std');
    }
    this._securityService.authorize({ customParams: idSvrState });
  }
  /**
   * Refresh OIDC token
   *
   * @returns {void}
   * @memberof AuthService
   */
  public refreshToken(): void {

    return this._securityService.authorize();
  }
  public setUserData() {
    this._securityService.userData$.subscribe(
      (data: any) => {
        this.userData = data;
        localStorage.setItem('userData', JSON.stringify(data));
      });
  }
  /**
   * Get user data
   *
   * @returns {*}
   * @memberof AuthService
   */
  public getUserData(): any {
    return this.userData;
  }
  /**
   * Set oidc state
   *
   * @param {string} state
   * @memberof AuthService
   */
  public setState(state: string): void {
    this._securityService.setState(state);
  }
  /**
   * Retrieve oidc state
   *
   * @returns {string}
   * @memberof AuthService
   */
  public getState(): string {
    return this._securityService.getState() ? this._securityService.getState() : '';
  }
  /**
   * set custom request params and calls authorize
   *
   * @param {({ [key: string]: string | number | boolean })} params
   * @memberof AuthService
   */
  public setCustomRequestParameters(params: { [key: string]: string | number | boolean }) {
    //  this._securityService.setCustomRequestParameters({ 'ui_locales': culture});

    this._securityService.authorize({ customParams: params });
  }
  /**
   * Complete oidc authorization
   *
   * @param {AuthorizationResult} authorizationResult
   * @memberof AuthService
   */
  public onAuthorizationResultComplete(authResult: boolean) {
    // if (authorizationResult === AuthorizationResult.authorized) {
    if (authResult) {
      this.isUserAuthorized = true;
      this.setUserData();
      this.loggedInRole.next('loggedIn');
      this.checkCustomStateData(this.getState());
      this.setState('');
      switch (this.getRoleCode()) {
        case 'DMT': {
          this.proxyLoginService.isCustomerAgent = false;
          localStorage.setItem('debtor', this.userData.debtor);
          window.location.href = environment.app_base_url_oss;
          break;
        }
        case 'COM': {
          this.proxyLoginService.isCustomerAgent = false;
          if ((this.userData?.role) === 'CMC') {
            localStorage.setItem('debtor', this.userData.debtor);
            //  this.setFinalizedAccountFlag(this.userData.debtor);
          } else {
            localStorage.removeItem('debtor');
          }
          break;
        }
        case 'CAG': {
          this.proxyLoginService.isCustomerAgent = false;
          localStorage.removeItem('debtor');
          break;
        }
        case 'CMC': {
          this.proxyLoginService.isCustomerAgent = false;
          localStorage.setItem('debtor', this.userData.debtor);
          // this.setFinalizedAccountFlag(this.userData.debtor);
          break;
        }
        case 'AIR': {
          this.proxyLoginService.isCustomerAgent = false;
          this.router.navigate(['/my-account/superuser']);
          break;
        }
        case 'CSR': {
          this.proxyLoginService.isCustomerAgent = true;
          break;
        }
        case 'IND': {
          this.logout();
          break;
        }
        default: {
          this.proxyLoginService.isCustomerAgent = false;
          this.setPostLoginRoute('/signin-oidc');
          // this.commercialHandover();
          break;
        }
      }
    }
    else {
      this.authorize();
    }
  }
  /**
   * Logout
   *
   * @param {string} [flow='std']
   * @memberof AuthService
   */
  public logout(flow = 'std') {
    localStorage.removeItem('debtor');
    localStorage.removeItem('post_login_uri');
    localStorage.removeItem('proxyCMCDebtor');
    localStorage.removeItem('loggedinAsCSRUser');
    localStorage.removeItem('superuserName');
    localStorage.removeItem('csr-token');
    localStorage.removeItem('csr-role');
    localStorage.removeItem('userData');
    localStorage.removeItem('authorizationResult');
    localStorage.removeItem('authorizationData');
    localStorage.removeItem('authorizationDataIdToken');
    localStorage.removeItem('storage_custom_request_params');
    localStorage.clear();
    localStorage.setItem('flow', flow);
    this.setPostLogoutState(flow);
    this._securityService.logoff();
    this.cacheManager.clear();
    this.deleteAllCookies();
    this.eraseCookie('token');
    sessionStorage.clear();
  }

  deleteAllCookies = () => {
    const cookies = document.cookie.split(";");
    for (const cookie of cookies) {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }

  public setFinalizedAccountFlag(debtorNumber) {
    if (sessionStorage.getItem('isFinalizedAccount') === null || sessionStorage.getItem('isFinalizedAccount') === undefined) {
      this.billingService.getFinalisedSingleAccountData(parseInt(debtorNumber)).subscribe((data) => {
        this.isFinalizedAccount = data.finalizedAccount;
        sessionStorage.setItem('isFinalizedAccount', this.isFinalizedAccount?.toString());
        // localStorage.setItem('isFinalizedAccount', this.isFinalizedAccount?.toString());
        return this.isFinalizedAccount;
      });
    }
  }


  public getPayloadFromIdToken(encode: boolean = false) {
    return this._securityService.getPayloadFromIdToken(encode);
  }
  /**
   * Set post logout route
   *
   * @param {any} flow
   * @memberof AuthService
   */
  public setPostLogoutState(flow) {
    const userData = this.userData;
    if (userData) {
      const role = this.getRoleCode();
      const jur = userData.jur === 'NI' ? 'NI' : 'ROI';
      this.setState(JSON.stringify({ role, jur, flow }));
      localStorage.setItem('logoutState', JSON.stringify({ role, jur, flow }));

    }
  }
  /**
   * Set post login route
   *
   * @param {any} route
   * @memberof AuthService
   */
  public setPostLoginRoute(route) {
    this.oidcConfig.post_login_route = route;
    //  this.authConfig.init(this.oidcConfig);
  }
  public enableAutoLogin(otac?: string, ntParam?: string) {
    let customParams: {
      [key: string]: string | number | boolean;
    } = {};
    if (otac) {
      // tslint:disable-next-line: no-string-literal
      customParams['acr_values'] = otac;
    }
    if (ntParam) {
      customParams = {
        ...customParams,
        ...this.generateStateFromNT(ntParam)
      };
    }
    localStorage.setItem('post_login_uri', 'my-account/acc-overview');
    this.setCustomRequestParameters(customParams);
  }
  public revokeToken(): Observable<any> {
    const token: string = this.getAccessToken();

    if (token !== '') {
      const revocationEndpoint: string = this.oidcConfig.revocation_session_endpoint;
      const client_id = 'client';
      const client_secret = 'secret';
      const headers: HttpHeaders = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Basic ${btoa(`${client_id}:${client_secret}`)}`
      });
      const params: any = {
        token,
        token_type_hint: 'access_token'
      };

      const body: string = this.encodeParams(params);
      return this.http.post<any>(revocationEndpoint, body, { headers })
        .pipe(map((response: Response) => {
          return response;
        }),
          catchError((exception: Response | any) => throwError(exception))
          , finalize(() => {
          }));
    }
    return of(false);
  }
  private encodeParams(params: any): string {
    let body = '';
    for (const key of Object.keys(params)) {
      if (body.length) {
        body += '&';
      }
      body += key + '=';
      body += encodeURIComponent(params[key]);
    }
    return body;
  }
  refreshSession() {
    localStorage.setItem('_isAuthorized', 'false');
    this.isUserAuthorized = false;
    const url: string = this.router.url;
    const idSvrState: any = {
      // tslint:disable-next-line: object-literal-key-quotes
      'jur': this.currentRegion?.code === 'NI' ? 'NI' : 'ROI',
      // tslint:disable-next-line: object-literal-key-quotes
      'role': this.getRoleCode()
    };
    localStorage.setItem('post_login_uri', url);
    this.setCustomRequestParameters(idSvrState);
    this.authorize();
  }

  getRoleCode() {
    let userDom = 'COM';
    if (this.userData && this.userData != null) {
      if (this.userData.custtype === 'DO' && (this.userData.role === 'DMT' || this.userData.role === 'CUS')) {
        userDom = 'DMT';
      } else if (this.userData.role === 'CSR') {
        userDom = 'CSR';
      } else if (this.userData.custtype === 'CO' && this.userData.role === 'IND') {
        userDom = 'IND';
      } else {
        if (this.userData.role === 'CMC' || this.userData.role === 'CAG' || this.userData.role === 'OSA') {
          userDom = 'COM';
        }
        if (this.userData.role === 'AIR') {
          userDom = 'AIR';
        }
      }
    }
    return userDom;

  }

  generateStateFromNT(ntParam: string) {
    ntParam = ntParam.toUpperCase();
    const idServerState = {
      jur: 'ROI',
      role: 'DMT'
    };
    if (ntParam === 'NIDOM' || ntParam === 'NICOM') {
      idServerState.jur = 'NI';
    }
    if (ntParam === 'ROICOM' || ntParam === 'NICOM') {
      idServerState.role = 'COM';
    }
    return idServerState;
  }

  checkCustomStateData(state: any) {
    try {
      JSON.parse(state);
    } catch (e) {
      return;
    }
    state = JSON.parse(state);
    // Check for password reset journey
    if (state && state.flow && state.flow === 'passwordReset') {
      sessionStorage.setItem('passwordReset', 'true');
    }
  }
}
