import { Customer } from '../models/customer/customer.model';
import { County } from '../models/county.model';
import { Country } from '../models/country.model';
import { AddressParserService } from './address-parser/address-parser.service';
import { Address } from '../models/account/address.model';
import { RealexOrderDetail, RealexOrderDetail3DS2 } from '../models/payment/realex-order-detail.model';
import { LocalizationService } from '../../core/services/localization/localization.service';
import { BudgetPlanDetail } from '../models/payment/budget-plan-detail.model';
import { PaymentConfirmationResponse, PaymentOrderDetail } from '../models/payment/payment-confirmation-response.model';
import { CardChargeDetail } from '../models/payment/card-charge-detail.model';
import { PaymentDetail, PaymentRegion } from '../models/payment/payment-detail.model';
import { ParentPage } from './billing.service';
import { OSSAppConfig } from '../../core/config/app-config';
import { Observable, throwError } from 'rxjs';
import { map , catchError, finalize } from 'rxjs/operators';

import { CardType } from '../models/payment/card-type.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Renderer2 } from '@angular/core';
import { CacheManager } from '../../core/services/cache-manager.service';
import { BillingOverview } from '../models/billing/billing-overview.model';

@Injectable()
export class PaymentService {
  private paymentURL = OSSAppConfig.API_GROUP[OSSAppConfig.API_SRC].paymentUrl;

  constructor(
    private http: HttpClient,
    private cacheManager: CacheManager,
    private localizationService: LocalizationService,
    private addressParser: AddressParserService
  ) { }

  get defaultValuesFor3DS2(): Partial<RealexOrderDetail3DS2> {
    if (OSSAppConfig.useDummyValuesFor3DS2) {
      return OSSAppConfig.dummyValuesFor3DS2;
    }
    // Use blank values as default
    return {
      HPP_CUSTOMER_EMAIL: '',
      HPP_CUSTOMER_PHONENUMBER_MOBILE: '',
      HPP_BILLING_STREET1: '',
      HPP_BILLING_STREET2: '',
      HPP_BILLING_STREET3: '',
      HPP_BILLING_CITY: '',
      HPP_BILLING_POSTALCODE: '',
      HPP_BILLING_COUNTRY: '000'
    };
  }
  realexValidAddressSetNegated = /[^a-zA-Z0-9\/\-\_\.\s\(\):;',"{}]/g;
  realexValidPostCode = /^[a-zA-Z0-9-\s]{1,16}$/;
  realexValidEmail = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,24})*$/;

  public setPaymentParentPage(from: ParentPage) {
  //  this.cacheManager.save('paymentParent', from);
    localStorage.setItem('paymentParent', from);
  }

  public getPaymentParentPage(): ParentPage {
    return <ParentPage> localStorage.getItem('paymentParent') || ParentPage.AccountOverview;
  }

  public getCardTypes(paymentRegion: PaymentRegion): Observable<CardType[]> {
    const path = `${OSSAppConfig.jsonBasePath}/payment-card-types.json`;
    return this.http.get<any>(path)
      .pipe(map(data => data.cardTypes
        .filter((card: CardType) => (card.paymentRegions || [])
          .includes(paymentRegion)))
      , catchError(error => throwError(error))
     , finalize(() => {

      }));
  }

/**
 * Service call to create Order ID
 * @param paymentDetail Payment Details
 */
  createPaymentOrder(paymentDetail: PaymentDetail, debtorNum: any, isLoggedOutJourney = false): Observable<any> {

    let headers = new HttpHeaders();
    if (isLoggedOutJourney) {
      headers = headers.append( 'isLoggedOutJourney', 'true' );     
    }
    // console.log("before",headers); 
    if(debtorNum!='0'){      
      headers = headers.append('csr-customer', debtorNum);      
    }
    // console.log("after",headers); 
    
    return this.http.post(`${OSSAppConfig.PAYMENT_API_BASE}/${this.paymentURL}/ordersV2`,
      paymentDetail, { headers, observe: 'response' as 'body', })
      .pipe(map((res: Response) => res)
      , catchError((exception: Response | any) => {
        if (exception?.error?.errors?.mprn?.length > 0)
          {
             return throwError('The MPRN provided is not valid.');
          }
        else if (exception?.error?.errors?.gprn?.length > 0){
            return throwError('A valid GPRN must be provided.');
        }
        else if (exception?.error?.errors?.postCode?.length > 0){
             return throwError('A valid post code must be provided.');
        }
        else if (exception?.error?.errors?.general?.length > 0){
          return throwError('Invalid Customer Number.');
     }
        else {
          return exception;
        }
        // return Observable.throw(exception.error.errors.customAccountGroupName[0].message);
        })
     ,finalize(() => {

      }));
  }

  /**
   * Service to get Payment Information based on order Id
   * @param orderDetailsUrl
   */
  getRealexOrderDetails(orderDetailsUrl: string, debtorNum: any, isLoggedOutJourney = false): Observable<RealexOrderDetail3DS2> {

    let headers = new HttpHeaders();
    if (isLoggedOutJourney) {
      headers = new HttpHeaders({ isLoggedOutJourney: 'true' });     
    }
    // console.log("before",headers); 
    if(debtorNum!='0'){      
      headers = headers.append('csr-customer', debtorNum);      
    }
    // console.log("after",headers); 

    return this.http.get(orderDetailsUrl, { headers })
      .pipe(map((res: RealexOrderDetail) => this.addDefault3DS2MandatoryFields(res))
      , catchError((exception: Response | any) => throwError(exception))
     , finalize(() => {

      }));
  }

  addDefault3DS2MandatoryFields(orderDetails: RealexOrderDetail): RealexOrderDetail3DS2 {
    return {
      // Add mandatory fields with default values
      ...this.defaultValuesFor3DS2,
      // Add new fields and replace default values using orderDetail
      ...orderDetails,
      // Add any mandatory constant values for 3DS2 below

    };
  }

  addEmailToOrderDetails(orderDetails: RealexOrderDetail3DS2, email: string = ''): RealexOrderDetail3DS2 {
    if (this.realexValidEmail.test(email)) {
      orderDetails.HPP_CUSTOMER_EMAIL = email;
    }
    return orderDetails;
  }

  addPhoneNumberToOrderDetails(
    orderDetails: RealexOrderDetail3DS2,
    country: Country,
    homePhone: string = '',
    mobilePhone: string = ''
  ): RealexOrderDetail3DS2 {
    if (homePhone) {
      const formattedPhone = this.addressParser.parsePhoneNumber(homePhone, country);
      if (formattedPhone) {
        orderDetails.HPP_CUSTOMER_PHONENUMBER_HOME = formattedPhone;
      }
    }
    if (mobilePhone) {
      const formattedPhone = this.addressParser.parsePhoneNumber(mobilePhone, country);
      if (formattedPhone) {
        orderDetails.HPP_CUSTOMER_PHONENUMBER_MOBILE = formattedPhone;
      }
    }
    return orderDetails;
  }

  addCustomerDataToOrderDetails(
    orderDetails: RealexOrderDetail3DS2,
    billingAddress: Address,
    customerDetails: Customer): RealexOrderDetail3DS2 {
    let country: Country | undefined;
    let county: County | undefined;
    let town = '';
    if (billingAddress) {
      if (billingAddress.addressLine1) {
        orderDetails.HPP_BILLING_STREET1 = this.transformAddressLineForRealex(billingAddress.addressLine1);
      }
      if (billingAddress.addressLine2) {
        orderDetails.HPP_BILLING_STREET2 = this.transformAddressLineForRealex(billingAddress.addressLine2);
      }
      if (billingAddress.postCode && this.realexValidPostCode.test(billingAddress.postCode.trim())) {
        orderDetails.HPP_BILLING_POSTALCODE = billingAddress.postCode.trim();
      }
      if (billingAddress.town) {
        [country, county, town] = this.addressParser.parseTown(billingAddress.town);
        if (town) {
          orderDetails.HPP_BILLING_STREET3 = this.transformAddressLineForRealex(town);
        }
        if (county) {
          orderDetails.HPP_BILLING_CITY = this.transformAddressLineForRealex(county.displayName);
        }
        if (country) {
          orderDetails.HPP_BILLING_COUNTRY = country.numericCode;
          if (country.code === 'US' || country.code === 'CA') {
            // State field is mandatory for US and Canada
            // Add blank string since we don't have state info for abroad addresses
            orderDetails.HPP_BILLING_STATE = '';
          }
        }
      }
    }
    if (customerDetails && country) {
      orderDetails = this.addPhoneNumberToOrderDetails(orderDetails, country, customerDetails.homePhone, customerDetails.mobilePhone);
    }
    orderDetails = this.addEmailToOrderDetails(orderDetails, customerDetails.contactEmail);
    return orderDetails;
  }

  redirectToRealex(orderDetails: RealexOrderDetail, renderer: Renderer2) {
    const realexUrl = `${orderDetails.hppHost}/${orderDetails.hppUrl}`;
    delete orderDetails.hppHost;
    delete orderDetails.hppUrl;
    delete orderDetails.payerRef;
    delete orderDetails.pmtRef;
    const paymentForm: HTMLFormElement = renderer.createElement('form');
    paymentForm.setAttribute('method', 'POST');
    paymentForm.setAttribute('action', realexUrl);
    Object.keys(orderDetails).forEach(inputField => {
      const inputElement: HTMLInputElement = renderer.createElement('input');
      inputElement.setAttribute('id', inputField);
      inputElement.setAttribute('name', inputField);
      inputElement.setAttribute('value', orderDetails[inputField]);
      paymentForm.appendChild(inputElement);
    });
    const submitButton: HTMLButtonElement = renderer.createElement('button');
    submitButton.setAttribute('type', 'submit');
    paymentForm.appendChild(submitButton);
    paymentForm.setAttribute('style', 'width: 0; height: 0; overflow: hidden;');
    document.body.appendChild(paymentForm);
    submitButton.click();
  }

  getCardCharges(cardName: string, amount: string, isLoggedOutJourney = false): Observable<CardChargeDetail> {

    let headers = new HttpHeaders();
    if (isLoggedOutJourney) {
      headers = new HttpHeaders({ isLoggedOutJourney: 'true' });
    }

    const url = `${OSSAppConfig.PAYMENT_API_BASE}/${this.paymentURL}/cardcharge`;
    const params = {
      paymentamount: amount,
      card: cardName
    };
    return this.http.get<CardChargeDetail>(url, { params, headers })
      .pipe(map((res) => res)
      , catchError((exception: Response | any) => throwError(exception))
     , finalize(() => {

      }));
  }

  confirmPayment(orderId: string, isLoggedOutJourney = false, debtorNum: any): Observable<PaymentConfirmationResponse> {
    let headers = new HttpHeaders();
    if (isLoggedOutJourney) {
      headers = headers.append( 'isLoggedOutJourney', 'true' );     
    }
    // console.log("before",headers); 
    if(debtorNum!='0'){      
      headers = headers.append('csr-customer', debtorNum);      
    }
    // console.log("after",headers); 
    const url = `${OSSAppConfig.PAYMENT_API_BASE}/${this.paymentURL}/paymentConfirmation/${orderId}`;
    return this.http.get<PaymentConfirmationResponse>(url, { headers })
      .pipe(map(confirmationResponse => {
        this.setPaymentConfirmation(confirmationResponse);
        this.localizationService.setRegion((confirmationResponse.orderDetail as PaymentOrderDetail).region || 'ROI');
        return confirmationResponse;
      }), catchError(exception => throwError(exception))
     , finalize(() => {

      }));
  }

  setPaymentConfirmation(confirmationResponse: PaymentConfirmationResponse) {
    this.cacheManager.quickSave('paymentConfirmation', confirmationResponse);
  }

  getPaymentConfirmation(): PaymentConfirmationResponse | null {
    const paymentConfirmation = this.cacheManager.quickRetrieve('paymentConfirmation');
    if (paymentConfirmation) {
      return paymentConfirmation;
    }
    return null;
  }

  setPaymentConfirmationErrorOrderId(orderId: string) {
    this.cacheManager.quickSave('paymentConfirmationErrorOrderId', orderId);
  }

  getPaymentConfirmationErrorOrderId(): string | null {
    const errorId = this.cacheManager.quickRetrieve('paymentConfirmationErrorOrderId');
    if (errorId) {
      return errorId;
    }
    return null;
  }

  clearPaymentConfirmationData() {
    this.cacheManager.quickRemove('paymentConfirmation');
    this.cacheManager.quickRemove('paymentConfirmationErrorOrderId');
  }

  isBudgetPlan(debtorNumber: string): Observable<boolean> {
    // This API doesn't require authentication
    const headers = new HttpHeaders({ isLoggedOutJourney: 'true' });
    const url = `${OSSAppConfig.PAYMENT_API_BASE}/${this.paymentURL}/checkBudgetPlan/${debtorNumber}`;
    return this.http.get<BudgetPlanDetail>(url, { headers })
      .pipe(map(userDetail => userDetail.budgetPlan)
      , catchError(exception => throwError(exception))
     , finalize(() => {

      }));
  }

  /**
   * Remove invalid characters from address line and truncate at 50 characters
   *
   * @private
   * @param {string} addressLine
   * @returns {string}
   * @memberof PaymentService
   */
  private transformAddressLineForRealex(addressLine: string): string {
    return addressLine.trim()
      // remove invalid characters
      .replace(this.realexValidAddressSetNegated, '')
      // remove multiple white spaces
      .replace(/\s+/g, ' ')
      .slice(0, 50);
  }

}



