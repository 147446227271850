import { OssContentService } from '../../core/services/oss-content/oss-content.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ossContent'
})
export class OssContentPipe implements PipeTransform {

  constructor(private ossContentService: OssContentService) { }

  transform(value: any, scope: string, contentKey: string): any {
    const content = this.ossContentService.getContent(scope, contentKey);
    // console.log('transform...' , content);
    // console.log('transform...123' , value);
    return content ? content : value;
  }

}
