import * as CryptoJS from 'crypto-js';

export class UserDataCommon {

    private static userData = JSON.parse(localStorage.getItem('userData')) as any;
    private static salt = 'oss-responsive';

    public static getUserRole() {
        let role = '';
        const _data = JSON.parse(localStorage.getItem('userData')) as any
        if (_data) {
            role = _data.role;
        }
        return role;
    }

    public static getDebtorNumber() {
        let number = '0';
        const data = JSON.parse(localStorage.getItem('userData')) as any
        if (data) {
            number = data.debtor;
        }
        return number;
    }

    public static passType() {
        let type = '';
        const passVal = localStorage.getItem('passValue');
        if(passVal){
            type = UserDataCommon.decryptTxt(passVal);
        }
        return type;
    }

    public static encryptTxt(data: any): string {
        const encryptedData: string = CryptoJS.AES.encrypt(data, this.salt).toString();
        return encryptedData;
    }

    public static decryptTxt(data: any) {
        if (data) {
            const bytes = CryptoJS.AES.decrypt(data, this.salt);
            return bytes.toString(CryptoJS.enc.Utf8);
        }
    }
}
