import { ProxyLoginService } from '../services/proxy-login.service';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';

import { AuthService } from '../auth/auth.service';
import { Observable } from 'rxjs';
import { map, finalize, catchError } from 'rxjs/operators';
import { LocalizationService } from '../services/localization/localization.service';
import { environment } from 'src/environments/environment';
/**
 *
 *
 * @export
 * @class AuthGuard
 */
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  currentRegion: any;
  constructor(
    private auth: AuthService,
    private router: Router,
    private proxyLoginService: ProxyLoginService,
    private oidcSecurityService: OidcSecurityService,
    private locale: LocalizationService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const url: string = state.url;
    return this.checkAccess(url);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.canActivate(route, state);
  }

  canLoad(route: Route): Observable<boolean> | boolean {
    const url = `/${route.path}`;
    return this.checkAccess(url);
  }
  /**
   *
   * @param {string} url
   * @returns {boolean}
   * @memberof AuthGuard
   */
  checkAccess(url: string): boolean | Observable<boolean> {
    if (this.auth.isAuthorized()) {
      if (this.proxyLoginService.isCustomerAgent
         && !this.proxyLoginService.proxyDebtorNumber
         && !this.proxyLoginService.proxySuperUsername
         && url !== '/my-account/proxy-login') {
         this.router.navigate(['/my-account/proxy-login']);
         return true;
       }
      if (url === '/my-account/meter-reading' && sessionStorage.getItem('premCount') === '0') {
        this.router.navigate(['/my-account/acc-overview']);
      }
      if (localStorage.getItem('post_login_uri')) {
        const postloginUrl: any = localStorage.getItem('post_login_uri') ? localStorage.getItem('post_login_uri') : '/my-account/acc-overview';
        url = postloginUrl;
        const params = this.getParamList(postloginUrl);
        localStorage.removeItem('post_login_uri');
        if (params) {
          const paramObj = JSON.parse(`{"${params}"}`);
          const route = postloginUrl.split('?')[0];
          this.router.navigate([route], { queryParams: paramObj });  // navigate with query params
        } else {
          this.router.navigate([url]);
        }
      }
      if (localStorage.getItem('isCustomerAgent') === 'true' && url.includes('my-account/acc-overview?debtorNumber=')) {
        localStorage.removeItem('superuserName');
        if(localStorage.getItem('isCustomerAgent') === 'true') {
          localStorage.setItem('proxyCMCDebtor', (url.split('?')[1]).split('=')[1]);
        }
      }
      if (url.includes('my-account/acc-overview?uname=')) {
        localStorage.removeItem('proxyCMCDebtor');
      }
      if (url.includes('my-account/acc-overview') && localStorage.getItem('isCustomerAgent') === 'true') {
        //   if ((localStorage.getItem('userData') !== 'null' && localStorage.getItem('userData') !== 'null'
        //     && localStorage.getItem('userData') !== undefined) || localStorage.getItem('loggedinAsCSRUser') === 'true') {

        if (localStorage.getItem('loggedinAsCSRUser') === 'true') {
          if ((localStorage.getItem('proxyCMCDebtor') === 'null' || localStorage.getItem('proxyCMCDebtor') === null
            || localStorage.getItem('proxyCMCDebtor') === undefined) && url.includes('my-account/acc-overview?debtorNumber=')) {
            if (url.split('?')[1] && url.split('?')[1].split('=')[0] && url.split('?')[1].split('=')[0] === 'debtorNumber') {
              const val = url.split('?')[1].split('=')[1];
              if (val !== undefined && val !== null) {
                localStorage.setItem('proxyCMCDebtor', val);
                // console.log('proxyCMCDebtor',localStorage.getItem('proxyCMCDebtor'));
                localStorage.setItem('loggedinAsCSRUser', "true");
                localStorage.removeItem('superuserName');
              }
            }
          }
          else if (localStorage.getItem('proxyCMCDebtor') !== 'null' && localStorage.getItem('proxyCMCDebtor') !== null
            && localStorage.getItem('proxyCMCDebtor') !== undefined) {
            localStorage.setItem('loggedinAsCSRUser', "true");

            // console.log('proxyCMCDebtor',localStorage.getItem('proxyCMCDebtor'));
            localStorage.removeItem('superuserName');
          }
          else if ((localStorage.getItem('superuserName') === 'null' || localStorage.getItem('superuserName') === null
            || localStorage.getItem('superuserName') === undefined) && url.includes('my-account/acc-overview?uname=')) {
            if (url.split('?')[1] && url.split('?')[1].split('=')[0] && url.split('?')[1].split('=')[0] === 'uname') {
              const CSRUser = this.auth.getUserData().sub;
              const val = url.split('?')[1].split('=')[1];
              localStorage.setItem('superuserName', val);
              localStorage.removeItem('proxyCMCDebtor');
              this.proxyLoginService.retrieveProxyToken(val.toLowerCase(), CSRUser);
            }
          }
          else if (localStorage.getItem('superuserName') !== 'null' && localStorage.getItem('superuserName') !== null
            && localStorage.getItem('superuserName') !== undefined) {
            localStorage.removeItem('proxyCMCDebtor');

            const CSRUser = this.auth.getUserData().sub;
            this.proxyLoginService.retrieveProxyToken(localStorage.getItem('superuserName').toLowerCase(), CSRUser);
          } else {
            localStorage.removeItem('proxyCMCDebtor');
            localStorage.removeItem('superuserName');
            window.location.href = environment.app_base_url_oss;
          }
        }
        // }
      }
      return true;
    } else {
      return this.initSignIn(url);
    }
  }
  private initSignIn(url): Observable<boolean> {
    const idSvrState: { [key: string]: string } = {
      jur: 'ROI',
      role: 'COM'       // default chnaged to COM from DMT
    };
    const userDom = this.getUrlParameter('nt');
    const userJur = userDom.toLowerCase().indexOf('ni') > -1 ? 'NI' : 'ROI';
    const userRole = userDom.toLowerCase().indexOf('dom') > -1 ? 'DMT' : 'COM';
    if (userDom) {
      idSvrState.jur = userJur;
      idSvrState.role = userRole;
      this.locale.setRegion(userDom);
    }

    const usernameCode = this.getUrlParameter('username');
    // By default, don't consider username in url params. Only valid for forgot-username scenario
    let usernameRetrieve$: Observable<any> = Observable.create(observer => {
      observer.next('');
      observer.complete();
    });
    if (usernameCode) {
      // If forgot-username scenario, retrieve username from API
      usernameRetrieve$ = this.auth.getUsernameByCode(usernameCode);
    }

    return usernameRetrieve$.pipe(finalize(() => {
      if (url) {
        localStorage.setItem('post_login_uri', url);
      }

      this.auth.setCustomRequestParameters(idSvrState);
      // Clear proxy debtor, if any, so that debtor is not auto logged in
      this.proxyLoginService.proxyDebtorNumber = null;
      this.router.navigate(['/signin-redirect'], { queryParamsHandling: 'merge' });
    }), map(response => {
      try {
        // IS4 sets value of login_hint as the username
        idSvrState.login_hint = response.message.split('Username: ')[1].slice(0, -1);
      } catch (error) {
        // Ignore login_hint if username is not available
      }
      return false;
    }), catchError(exception => {
      // Ignore login_hint if username is not available
      return Observable.create(observer => {
        observer.next(false);
        observer.complete();
      }) as Observable<boolean>;
    }));
  }
  getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }
  private getParamList(url) {
    return decodeURI(url.split('?')[1]).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') === 'undefined' ? null :
      decodeURI(url.split('?')[1]).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"');
  }
}
