import { LocalizationService } from '../../../core/services/localization/localization.service';
import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable()
export class CurrencyMaskService {
  private decimalSeparator: string;
  private thousandsSeparator: string;
  private defaultCurrencyCode: string;
  constructor(private currencyPipe: CurrencyPipe, private localizationService: LocalizationService) {
    this.decimalSeparator = '.';
    this.thousandsSeparator = ',';
    this.localizationService.region$.subscribe((region) => {
      this.defaultCurrencyCode = region.currencyCode;
    });
    if(JSON.parse(localStorage.getItem('userData'))?.jur?.trim()?.toLowerCase() === 'ni' ) {
      this.defaultCurrencyCode = '£';
    } else {
      this.defaultCurrencyCode = '€';
    }
  }

  transform(value: string = '', allowNegative = false, currencyCode: string = this.defaultCurrencyCode, decimalPrecision: number = 2) {
    value = this.parse(value, allowNegative);
    if (!value || value.length === 0) {
      value = '';
      return '';
    }
    else{
    return this.currencyPipe.transform(value, currencyCode, 'symbol', '1.2-2') as string;
    }
  }

  parse(value: string, allowNegative = false) {
    let [integer, fraction = ''] = (value || '').split(this.decimalSeparator);
    integer = integer.replace(new RegExp(/[^\d\.]/, 'g'), '');
    fraction = parseInt(fraction, 10) > 0 && 2 > 0 ? this.decimalSeparator + (fraction + '000000').substring(0, 2) : '';
    if (allowNegative && (value.startsWith('-'))) {
      return ('-' + integer + fraction);
    } else {
      return integer + fraction;
    }
  }
}
