export class NavMenuItem {
    link: string;
    text: string;
    customClasses?: string[];
    submenu?: NavMenuItem[];
    id?: string;
    queryParams?: any;

    constructor(text: string, link: string, id?: string, customClasses?: string[], submenu?: NavMenuItem[], queryParams?: any) {
        this.text = text;
        this.link = link;
        this.id = id;
        this.customClasses = customClasses || [];
        this.submenu = submenu || [];
        this.queryParams = queryParams || '';
    }
}
