import { Region } from '../../../shared/models/region.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CacheManager } from '../cache-manager.service';

@Injectable()
export class LocalizationService {

  private selectedRegion: BehaviorSubject<Region>;

  private regionROI = new Region('Republic of Ireland', 'ROI', 'ROI', 'en_IE', 'EUR');
  private regionNIE = new Region('Northern Ireland Electricity', 'NIE', 'NI', 'en_IE', 'GBP');
  private regionNIG = new Region('Northern Ireland Gas', 'NIG', 'NI', 'en_IE', 'GBP');

  constructor(
    private cacheManager: CacheManager
  ) {
    this.selectedRegion = new BehaviorSubject<Region>(this.regionROI);
  }

  setRegion(currentRegion: string) {
    switch (currentRegion.toUpperCase()) {
      case 'ROI':
      case 'ROIDOM':
      case 'REPUBLIC OF IRELAND': {
        this.selectedRegion.next(this.regionROI);
        break;
      }
      case 'NIE':
      case 'NI':
      case 'NIDOM':
      case 'NORTHERN IRELAND':
      case 'NORTHERN IRELAND ELECTRICITY': {
        this.selectedRegion.next(this.regionNIE);
        break;
      }
      case 'NIG':
      case 'NORTHERN IRELAND GAS': {
        this.selectedRegion.next(this.regionNIG);
        break;
      }
    }
    this.backupRegionData();
  }

  get region$(): Observable<Region> { return this.selectedRegion.asObservable(); }
  get region(): Region { return this.selectedRegion.value; }
  get countryCode(): 'ROI' | 'NI' { return this.selectedRegion.value.jurisdiction; }
  get currencyCode(): 'EUR' | 'GBP' { return this.selectedRegion.value.currencyCode; }
  get locale(): string { return this.selectedRegion.value.locale; }

  backupRegionData() {
    this.cacheManager.save('regionCode', this.selectedRegion.value.code);
  }

  restoreRegionData() {
    const regionCode = this.cacheManager.retrieve('regionCode');
    if (regionCode) {
      this.setRegion(regionCode);
    }
  }
}
