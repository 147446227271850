<div class="spinner-container">
    <div class="spinkit">
        <div class="sk-three-bounce">
            <div class="sk-child sk-bounce1"></div>
            <div class="sk-child sk-bounce2"></div>
            <div class="sk-child sk-bounce3"></div>
            <div class="sk-child sk-bounce4"></div>
        </div>
    </div>
    <div class="spinner-overlay"></div>
</div>