import {
  BillingTransaction,
  BillingTransactionAPI,
  BillingTransactionCollection,
  BillingTransactionCollectionAPI,
} from '../../models/billing/billing-transaction-collection.model';
import { CurrencyMaskService } from '../../../shared/directives/currency-mask/currency-mask.service';
import { Injectable } from '@angular/core';

export enum TransactionUtilityType {
  Gas = 'G',
  Electricity = 'E'
}

@Injectable()
export class TransactionsHandlerService {

  constructor(private currencyMaskService: CurrencyMaskService) { }

  parseTransactionHistory(billingTransactionCollection: BillingTransactionCollectionAPI): BillingTransactionCollection {
    const transactions = new Map<string, BillingTransaction>();
    let resolveConflictID = 1;
    let transactionKey;
    let hasElectricity = false;
    let hasGas = false;
    if (billingTransactionCollection.billingTransactionCollection) {
      billingTransactionCollection.billingTransactionCollection.forEach(transaction => {
        transactionKey = `${transaction.transactionStatus}${transaction.statementNumber}`;
        switch (transaction.transactionStatus) {
          case 'DEBIT':
          case 'CREDIT': {
            if (
              transaction.transactionDescription === 'Bill'
              || transaction.transactionDescription === 'Budget Plan Bill'
            ) {
              switch (transaction.utilityType) {
                case TransactionUtilityType.Electricity: {
                  hasElectricity = true;
                  break;
                }
                case TransactionUtilityType.Gas: {
                  hasGas = true;
                  break;
                }
              }
              transactions.set(transactionKey, this.mergeTransactions(transaction, transactions.get(transactionKey)));
            } else {
              transactionKey = `${transactionKey}${resolveConflictID}`;
              resolveConflictID++;
              transactions.set(transactionKey, transaction);
            }
            break;
          }
          default: {
            if (transactions.has(transactionKey)) {
              transactionKey = `${transactionKey}${resolveConflictID}`;
              resolveConflictID++;
            }
            transactions.set(transactionKey, transaction);
            break;
          }
        }
      });
    }
    return {
      billingTransactionCollection: Array.from(transactions.values()),
      hasElectricity,
      hasGas
    };
  }

  mergeTransactions(currentTransaction: BillingTransactionAPI, oldTransaction?: BillingTransaction): BillingTransaction {
    const oldTransactionAmount = () => {
      if (oldTransaction) {
        return this.currencyMaskService.parse(oldTransaction.transactionAmount || '0', true);
      } else {
        return '0';
      }
    };
    const currentTransactionAmount = () => {
      return this.currencyMaskService.parse(currentTransaction.transactionAmount || '0', true);
    };
    const oldElectricityAmount = () => {
      if (oldTransaction) {
        return this.currencyMaskService.parse(oldTransaction.electricityAmount || '0', true);
      } else {
        return '0';
      }
    };
    const currentElectricityAmount = () => {
      if (currentTransaction.utilityType === TransactionUtilityType.Electricity) {
        return this.currencyMaskService.parse(currentTransaction.transactionAmount || '0', true);
      } else {
        return '0';
      }
    };
    const oldGasAmount = () => {
      if (oldTransaction) {
        return this.currencyMaskService.parse(oldTransaction.gasAmount || '0', true);
      } else {
        return '0';
      }
    };
    const currentGasAmount = () => {
      if (currentTransaction.utilityType === TransactionUtilityType.Gas) {
        return this.currencyMaskService.parse(currentTransaction.transactionAmount || '0', true);
      } else {
        return '0';
      }
    };
    const isElectricityReversed = () => {
      if (currentTransaction.utilityType === TransactionUtilityType.Electricity && currentTransaction.reversed) {
        return true;
      } else if (oldTransaction) {
        return oldTransaction.electricReversal;
      } else {
        return false;
      }
    };
    const isGasReversed = () => {
      if (currentTransaction.utilityType === TransactionUtilityType.Gas && currentTransaction.reversed) {
        return true;
      } else if (oldTransaction) {
        return oldTransaction.gasReversal;
      } else {
        return false;
      }
    };
    const isFullyReversed = () => {
      if (oldTransaction) {
        return oldTransaction.invoiceFullyReversed && currentTransaction.reversed;
      } else {
        return currentTransaction.reversed;
      }
    };
    const getTransactionDescription = () => {
      // If any transactions in the statement was for budget plan, retain budget plan bill description
      if (oldTransaction && oldTransaction.transactionDescription === 'Budget Plan Bill') {
        return oldTransaction.transactionDescription;
      }
      return currentTransaction.transactionDescription;
    };
    const statementNumber = currentTransaction.statementNumber;
    const transactionAmount = this.currencyMaskService.transform(
      this.addAmounts(currentTransactionAmount(), oldTransactionAmount()), true
    );
    const electricityAmount = this.currencyMaskService.transform(
      this.addAmounts(currentElectricityAmount(), oldElectricityAmount()), true
    );
    const gasAmount = this.currencyMaskService.transform(
      this.addAmounts(currentGasAmount(), oldGasAmount()), true
    );
    const transactionDate = currentTransaction.transactionDate;
    const transactionDescription = getTransactionDescription();
    const transactionStatus = currentTransaction.transactionStatus;
    const electricReversal = isElectricityReversed();
    const gasReversal = isGasReversed();
    const invoiceFullyReversed = isFullyReversed();
    return {
      statementNumber,
      transactionAmount,
      electricityAmount,
      gasAmount,
      transactionDate,
      transactionDescription,
      transactionStatus,
      electricReversal,
      gasReversal,
      invoiceFullyReversed
    };
  }

  addAmounts(amountOne: string, amountTwo: string): string {

    // Amount would have upto 2 decimal places as per the current API response pattern.
    // Converting all decimals to whole numbers before addition and converting them back to 2 decimal places.
    // This is to prevent floating point addition errors in JavaScript.
    // eg. 22.01 + 32.12 = 54.129999999999995, but should be 54.13. This gets trimmed to 54.12 by CurrencyMaskService during transform.

    return (((Number(amountOne) * 100) + (Number(amountTwo) * 100)) / 100).toString();
  }

}
