import { MoveOut } from '../models/tenancy/move-out.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalizationService } from '../../core/services/localization/localization.service';
import { ProxyLoginService } from '../../core/services/proxy-login.service';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { map , catchError, finalize } from 'rxjs/operators';

import { HttpOptions } from '../../core/services/http-options.service';
import { OSSAppConfig } from '../../core/config/app-config';
import { LoaderService } from '../components/loader/loader.service';
import { CustomerService } from './customer.service';
import { CacheManager } from '../../core/services/cache-manager.service';

export enum HomeMovesParent {
  AccountOverview = 'account-overview',
  AccountDetails = 'account-details'
}

@Injectable()
export class TenancyService {

  private apiGroupURL = OSSAppConfig.API_GROUP[OSSAppConfig.API_SRC].changeoftenancy;

  constructor(
    private http: HttpClient,
    private customerService: CustomerService,
    private cacheManager: CacheManager,
  ) { }

  public getHomeMovesOptions(): Observable<any> {
    const path = `${OSSAppConfig.jsonBasePath}/home-moves-option-config.json`;
    return this.http.get<any>(path)
      .pipe(map(data => data)
      , catchError(error => throwError(error))
      , finalize(() => {

      }));
  }

  public checkUserEligibility(): Observable<any> {
    const debtorNum = this.customerService.getAccountNo();
    const url = `${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL}/customer-id/${debtorNum}/move-out`;
    return this.http.get(url)
      .pipe(map((response) => {
        return response;
      }), catchError((exception: Response | any) => throwError(exception))
      , finalize(() => {

      }));
  }

  public moveOut(moveOutData: MoveOut): Observable<any> {
    const debtorNum = this.customerService.getAccountNo();
    const url = `${OSSAppConfig.DATA_API_BASE}/${this.apiGroupURL}/customer-id/${debtorNum}/move-out`;
    return this.http.post(url, moveOutData)
      .pipe(map(response => response)
      , catchError(exception => throwError(exception))
      , finalize(() => {

      }));
  }

  public setHomeMovesParentPage(parentpage: HomeMovesParent) {
    this.cacheManager.save('homeMovesParent', parentpage);
  }

  public getHomeMovesParentPage(): HomeMovesParent {
    return this.cacheManager.retrieve('homeMovesParent') || HomeMovesParent.AccountDetails;
  }

}
